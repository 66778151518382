import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ActivityDetails from "./ActivityDetails";
import { useGetActivitiesByLeadIdQuery } from "state/salesApi";
import { Close } from "@mui/icons-material";
import { hoverStyle } from "components/helpers/MediaQueries";
import { TimeFormatter } from "components/helpers/DateTimeHelpers";

const ActivityLeadList = ({ lead, selectedListLead, onLeadListClose }) => {
  const { data: activities, refetch } = useGetActivitiesByLeadIdQuery(
    lead._id,
    {
      enabled: Boolean(lead),
      refetchOnMountOrArgChange: true,
    }
  );
  const [selectedActivityId, setSelectedActivityId] = useState(null);

  useEffect(() => {
    refetch();
  }, [lead, refetch]);

  const handleActivityClick = (activityId) => {
    setSelectedActivityId(activityId);
  };

  const handleDetailsClose = () => {
    refetch();
    setSelectedActivityId(null);
  };

  const handleActivityDate = (date) => {
    const activityDate = new Date(date);

    return activityDate.toLocaleDateString();
  };

  // const handleActivityTime = (time) => {
  //   const activityTime = new Date(time);

  //   return activityTime.toLocaleTimeString();
  // };

  if (!activities) return;

  if (selectedListLead) return (
    <Dialog open onClose={onLeadListClose} fullWidth maxWidth="xs">
      <DialogTitle>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
          <Typography variant="h6">{lead.name} Activity</Typography>
          <IconButton onClick={onLeadListClose}>
            <Close sx={hoverStyle}/>
          </IconButton>
        </div>
        <Divider sx={{ mb: '15px', }}/>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', justifyContent: 'center', }}>
        <div style={{ maxHeight: 540 }}>
          {activities.length !== 0 ? (
            <List sx={{ display: 'flex', flexDirection: 'column-reverse', }}>
              {activities.map((activity, index) => (
                <ListItem key={index} sx={{ padding: '0 16px', listStyleType: 'disc' }}>
                  {activity.type === "call" ? (
                    <ListItemText 
                      primary={`Call - ${handleActivityDate(activity.date)}`}
                      secondary={
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                        >
                          {TimeFormatter(activity.date)} - {" "}
                          {activity.title}
                        </Typography>
                      }
                      sx={{ display: 'list-item', }}
                    />
                  ) : (
                    <ListItemText 
                      primary={
                        activity.type === "email"
                          ? `Email - ${handleActivityDate(activity.date)}`
                          : activity.type === "inPerson"
                          ? `In Person - ${handleActivityDate(activity.date)}`
                          : activity.type === "listEmail"
                          ? `List Email - ${handleActivityDate(activity.date)}`
                          : `Other - ${handleActivityDate(activity.date)}`
                      }
                      secondary={
                        <Typography
                          sx={{ display: 'inline', }}
                          component="span"
                          variant="body2"
                        >
                          {TimeFormatter(activity.date)}
                          {activity.title && ` - ${activity.title}`}
                        </Typography>
                      }
                      sx={{ display: 'list-item', }}
                    />
                  )}
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography>No activities to show</Typography>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={onLeadListClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{ height: "400px", overflowY: "auto", overflowX: "hidden" }}
        >
          <List sx={{ display: "flex", flexDirection: "column-reverse" }}>
            {activities.map((activity) => (
              <ListItem
                key={activity._id}
                onClick={() => handleActivityClick(activity._id)}
              >
                {activity.type === "call" ? (
                  <ListItemText
                    primary={`Call ${activity.title} - ${handleActivityDate(
                      activity.date
                    )}`}
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  />
                ) : (
                  <ListItemText
                    primary={
                      activity.type === "email"
                        ? `Email - ${handleActivityDate(activity.date)}`
                        : activity.type === "listEmail"
                        ? `List Email - ${handleActivityDate(activity.date)}`
                        : activity.type === "inPerson"
                        ? `In Person - ${handleActivityDate(activity.date)}`
                        : `Other - ${handleActivityDate(activity.date)}`
                    }
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  />
                )}
              </ListItem>
            ))}
          </List>
        </div>
      </div>
      {selectedActivityId && (
        <ActivityDetails
          activityId={selectedActivityId}
          handleDetailsClose={handleDetailsClose}
        />
      )}
    </div>
  );
};

export default ActivityLeadList;
