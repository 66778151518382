import Close from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import { hoverStyle } from "components/helpers/MediaQueries";
import { states } from "components/helpers/ReusableLargeValues";
import { useFormik } from "formik";
import { useState } from "react";
import * as yup from 'yup';


// TODO: Handle 'NaN' on Margin percentage
const EditQuoteForm = ({ quote, onClose, onSubmit }) => {
    const theme = useTheme();
    const [calculatedMargin, setCalculatedMargin] = useState(quote.margin);
    const [marginPercentage, setMarginPercentage] = useState(parseFloat((quote.margin / quote.customerQuote) * 100 || 0).toFixed(2));
    const [originStateValue, setOriginStateValue] = useState(states.find((state) => state.code === quote.origin.state));
    const [originInputValue, setOriginInputValue] = useState("");
    const [destinationStateValue, setDestinationStateValue] = useState(states.find((state) => state.code === quote.destination.state));
    const [destinationInputValue, setDestinationInputValue] = useState("");

    const initialValues = {
        emailSubject: quote.emailSubject,
        originCity: quote.origin.city,
        originState: quote.origin.state,
        destinationCity: quote.destination.city,
        destinationState: quote.destination.state,
        equipType: quote.equipType || "",
        loadType: quote.loadType || "",
        truckPrice: quote.truckPrice || 0,
        customerQuote: quote.customerQuote || 0,
        margin: quote.margin || 0,
        driverName: quote.driverName || "",
        driverPhone: quote.driverPhone || "",
        dispatcherName: quote.dispatcherName || "",
        dispatcherPhone: quote.dispatcherPhone || "",
        pickupAndDeliveryETA: quote.pickupAndDeliveryETA || "",
        shipmentDate: quote.shipmentDate || "",
        truckInHand: quote.truckInHand,
        carrierMcNumber: quote.carrierInfo.mcNumber || "",
        carrierPhoneNumber: quote.carrierInfo.phoneNumber || "",
        carrierEmail: quote.carrierInfo.email || "",
        notes: quote.notes || "",
        quoteStatus: quote.quoteStatus || "unknown",
    };

    const formik = useFormik({
        initialValues,
        validationSchema: yup.object({
            emailSubject: yup.string().required('Email subject is required'),
            originCity: yup.string().required('Origin city is required'),
            originState: yup.string().required('Origin state is required'),
            destinationCity: yup.string().required('Destination city is required'),
            destinationState: yup.string().required('Destination state is required'),
            equipType: yup.string(),
            loadType: yup.string(),
            truckPrice: yup.number(),
            customerQuote: yup.number(),
            margin: yup.number(),
            driverName: yup.string(),
            driverPhone: yup.string(),
            dispatcherName: yup.string(),
            dispatcherPhone: yup.string(),
            pickupAndDeliveryETA: yup.string(),
            shipmentDate: yup.string(),
            truckInHand: yup.boolean(),
            carrierMcNumber: yup.string(),
            carrierPhoneNumber: yup.string(),
            carrierEmail: yup.string().email('Invalid email address'),
            status: yup.string(),
            assignedName: yup.string(),
            assignedEmail: yup.string(),
            notes: yup.string(),
            quoteStatus: yup.string(),
        }),
        onSubmit: (values) => {
            const newQuote = {
                emailSubject: values.emailSubject,
                origin: {
                    city: values.originCity,
                    state: values.originState
                },
                destination: {
                    city: values.destinationCity,
                    state: values.destinationState
                },
                equipType: values.equipType,
                loadType: values.loadType,
                truckPrice: values.truckPrice,
                customerQuote: values.customerQuote,
                margin: calculatedMargin,
                driverName: values.driverName,
                driverPhone: values.driverPhone,
                dispatcherName: values.dispatcherName,
                dispatcherPhone: values.dispatcherPhone,
                pickupAndDeliveryETA: values.pickupAndDeliveryETA,
                shipmentDate: values.shipmentDate,
                truckInHand: values.truckInHand,
                carrierInfo: {
                    mcNumber: values.carrierMcNumber,
                    phoneNumber: values.carrierPhoneNumber,
                    email: values.carrierEmail
                },
                notes: values.notes,
                quoteStatus: values.quoteStatus,
            };
            onSubmit(newQuote);
        },
    });

    // Handlers
    const handleStateChanges = (value, state) => {
        if (value === "origin") {
            formik.setFieldValue("originState", state);
        } else if (value === "destination") {
            formik.setFieldValue("destinationState", state);
        };
    };

    const handleDecimalFormat = (value) => {
        return parseFloat(value).toFixed(2);
    };

    const handleQuoteChange = (e, value) => {
        let newMargin

        if (value === "truckPrice") {
            formik.setFieldValue("truckPrice", e.target.value);
            newMargin = formik.values.customerQuote - e.target.value;
        } else if (value === "customerQuote") {
            formik.setFieldValue("customerQuote", e.target.value);
            newMargin = e.target.value - formik.values.truckPrice;
        };

        if (formik.values.truckPrice !== 0 && formik.values.customerQuote !== 0) {
            setCalculatedMargin((handleDecimalFormat(newMargin)));

            if (value === "truckPrice" && formik.values.truckPrice !== "0.00") {
                setMarginPercentage(handleDecimalFormat((newMargin / formik.values.customerQuote) * 100));
            } else if (value === "customerQuote" && formik.values.customerQuote !== 0) {
                setMarginPercentage(handleDecimalFormat((newMargin / e.target.value) * 100));
            };
        };
    };

    return (
        <Dialog open onClose={onClose} fullWidth maxWidth="lg">
            <DialogTitle>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                    <Typography variant="h6">Update Quote</Typography>
                    <IconButton onClick={onClose}>
                        <Close sx={hoverStyle} />
                    </IconButton>
                </div>
                <Divider sx={{ mb: '15px', }} />
            </DialogTitle>
            <DialogContent>
                <Container
                    component="main"
                    maxWidth="xl"
                    padding="40px"
                    sx={{
                        mt: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: theme.palette.primary[900],
                        p: '20px',
                        m: '0 auto',
                    }}
                >
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="emailSubject"
                                    label="Subject"
                                    name="emailSubject"
                                    autoFocus
                                    value={formik.values.emailSubject}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.emailSubject && formik.errors.emailSubject && (
                                    <Alert severity="error">{formik.errors.emailSubject}</Alert>
                                )}
                                <Divider sx={{ m: '15px 0px', }} />
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container item spacing={2}>
                                    <Grid item xs={2}>
                                        <Typography>
                                            Origin:
                                        </Typography>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="originCity"
                                            label="City"
                                            name="originCity"
                                            autoFocus
                                            value={formik.values.originCity}
                                            onChange={formik.handleChange}
                                            multiline
                                        />
                                        {formik.touched.originCity && formik.errors.originCity && (
                                            <Alert severity="error">{formik.errors.originCity}</Alert>
                                        )}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Autocomplete
                                            value={originStateValue}
                                            onChange={(e, newValue) => {
                                                setOriginStateValue(newValue);
                                                if (newValue) {
                                                    handleStateChanges("origin", newValue.code);
                                                } else {
                                                    formik.setFieldValue("originState", "");
                                                };
                                            }}
                                            inputValue={originInputValue}
                                            onInputChange={(e, newInputValue) => {
                                                setOriginInputValue(newInputValue);
                                            }}
                                            options={states}
                                            fullWidth
                                            autoHighlight
                                            selectOnFocus
                                            clearOnBlur
                                            clearOnEscape
                                            handleHomeEndKeys
                                            getOptionLabel={(option) => option.label}
                                            isOptionEqualToValue={(option, value) => option.code === value.code}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    {option.label} ({option.code})
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="State"
                                                    margin="normal"
                                                    name="originState"
                                                    required
                                                    inputProps={{
                                                        ...params.inputProps,
                                                    }}
                                                />
                                            )}
                                        />
                                        {formik.touched.originState && formik.errors.originState && (
                                            <Alert severity="error">{formik.errors.originState}</Alert>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container item spacing={2}>
                                    <Grid item xs={2}>
                                        <Typography>
                                            Destination:
                                        </Typography>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="destinationCity"
                                            label="City"
                                            name="destinationCity"
                                            autoFocus
                                            value={formik.values.destinationCity}
                                            onChange={formik.handleChange}
                                            multiline
                                        />
                                        {formik.touched.destinationCity && formik.errors.destinationCity && (
                                            <Alert severity="error">{formik.errors.destinationCity}</Alert>
                                        )}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Autocomplete
                                            value={destinationStateValue}
                                            onChange={(e, newValue) => {
                                                setDestinationStateValue(newValue);
                                                if (newValue) {
                                                    handleStateChanges("destination", newValue.code);
                                                } else {
                                                    formik.setFieldValue("destinationState", "");
                                                };
                                            }}
                                            inputValue={destinationInputValue}
                                            onInputChange={(e, newInputValue) => {
                                                setDestinationInputValue(newInputValue);
                                            }}
                                            options={states}
                                            autoHighlight
                                            selectOnFocus
                                            clearOnBlur
                                            clearOnEscape
                                            handleHomeEndKeys
                                            getOptionLabel={(option) => option.label}
                                            isOptionEqualToValue={(option, value) => option.code === value.code}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    {option.label} ({option.code})
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="State"
                                                    margin="normal"
                                                    name="destinationState"
                                                    required
                                                    inputProps={{
                                                        ...params.inputProps,
                                                    }}
                                                />
                                            )}
                                        />
                                        {formik.touched.destinationState && formik.errors.destinationState && (
                                            <Alert severity="error">{formik.errors.destinationState}</Alert>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="quoteStatus"
                                    label="Quote Status"
                                    name="quoteStatus"
                                    autoFocus
                                    value={formik.values.quoteStatus}
                                    onChange={formik.handleChange}
                                    defaultValue=""
                                    select
                                >
                                    <MenuItem value="live">Live</MenuItem>
                                    <MenuItem value="not live">Not Live</MenuItem>
                                    <MenuItem value="won">Won</MenuItem>
                                    <MenuItem value="unknown">Unknown</MenuItem>
                                </TextField>
                                {formik.touched.quoteStatus && formik.errors.quoteStatus && (
                                    <Alert severity="error">{formik.errors.quoteStatus}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="shipmentDate"
                                    label="Shipment Date"
                                    name="shipmentDate"
                                    autoFocus
                                    value={formik.values.shipmentDate}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.shipmentDate && formik.errors.shipmentDate && (
                                    <Alert severity="error">{formik.errors.shipmentDate}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="equipType"
                                    label="Equipment Type"
                                    name="equipType"
                                    autoFocus
                                    value={formik.values.equipType}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.equipType && formik.errors.equipType && (
                                    <Alert severity="error">{formik.errors.equipType}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                {/* <TextField
                                    margin="normal"
                                    fullWidth
                                    id="loadType"
                                    label="Load Type"
                                    name="loadType"
                                    autoFocus
                                    value={formik.values.loadType}
                                    onChange={formik.handleChange}
                                /> */}
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="loadType"
                                    label="Load Type"
                                    name="loadType"
                                    autoFocus
                                    value={formik.values.loadType}
                                    onChange={formik.handleChange}
                                    defaultValue=""
                                    select
                                >
                                    <MenuItem value="" style={{ display: 'none' }} />
                                    <MenuItem value="Full Truckload">
                                        Full Truckload
                                    </MenuItem>
                                    <MenuItem value="Partial">
                                        Partial
                                    </MenuItem>
                                    <MenuItem value="Doesn't Matter">
                                        Doesn't Matter
                                    </MenuItem>
                                </TextField>
                                {formik.touched.loadType && formik.errors.loadType && (
                                    <Alert severity="error">{formik.errors.loadType}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="truckPrice">Truck Price</InputLabel>
                                    <OutlinedInput
                                        id="truckPrice"
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        label="Amount"
                                        name="truckPrice"
                                        autoFocus
                                        type="number"
                                        value={formik.values.truckPrice}
                                        onChange={(e) => {
                                            handleQuoteChange(e, "truckPrice");
                                        }}
                                        onBlur={() => {
                                            if (formik.values.truckPrice !== "" || formik.values.truckPrice !== "0.00") {
                                                const newValue = handleDecimalFormat(formik.values.truckPrice);
                                                formik.setFieldValue("truckPrice", newValue);
                                            } else {
                                                formik.setFieldValue("truckPrice", 0);
                                            }
                                        }}
                                        onFocus={() => {
                                            if (formik.values.truckPrice === 0 || formik.values.truckPrice === "0.00") {
                                                formik.setFieldValue("truckPrice", "");
                                            }
                                        }}
                                        sx={{
                                            '& input[type=number]': {
                                                'MozAppearance': 'textfield',
                                            },
                                            '& input[type=number]::-webkit-outer-spin-button': {
                                                'WebkitAppearance': 'none',
                                                margin: 0,
                                            },
                                            '& input[type=number]::-webkit-inner-spin-button': {
                                                'WebkitAppearance': 'none',
                                                margin: 0,
                                            },
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="customerQuote">Customer Quote</InputLabel>
                                    <OutlinedInput
                                        id="customerQuote"
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        label="Amount"
                                        name="customerQuote"
                                        autoFocus
                                        type="number"
                                        value={formik.values.customerQuote}
                                        onChange={(e) => {
                                            handleQuoteChange(e, "customerQuote");
                                        }}
                                        onBlur={() => {
                                            if (formik.values.customerQuote !== "" || formik.values.customerQuote !== "0.00") {
                                                const newValue = handleDecimalFormat(formik.values.customerQuote);
                                                formik.setFieldValue("customerQuote", newValue);
                                            } else {
                                                formik.setFieldValue("customerQuote", 0);
                                            }
                                        }}
                                        onFocus={() => {
                                            if (formik.values.customerQuote === 0 || formik.values.customerQuote === "0.00") {
                                                formik.setFieldValue("customerQuote", "");
                                            }
                                        }}
                                        sx={{
                                            '& input[type=number]': {
                                                'MozAppearance': 'textfield',
                                            },
                                            '& input[type=number]::-webkit-outer-spin-button': {
                                                'WebkitAppearance': 'none',
                                                margin: 0,
                                            },
                                            '& input[type=number]::-webkit-inner-spin-button': {
                                                'WebkitAppearance': 'none',
                                                margin: 0,
                                            },
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="margin">Calculated Margin</InputLabel>
                                    <OutlinedInput
                                        id="margin"
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        label="Margin"
                                        name="margin"
                                        autoFocus
                                        value={calculatedMargin}
                                        type="number"
                                        disabled
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1.5}>
                                <FormControl>
                                    <InputLabel htmlFor="margin">Margin %</InputLabel>
                                    <OutlinedInput
                                        id="margin"
                                        endAdornment={<InputAdornment position="start">%</InputAdornment>}
                                        label="Percentage"
                                        name="percentage"
                                        autoFocus
                                        value={marginPercentage}
                                        disabled
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4.5} />
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="carrierMcNumber"
                                    label="MC Number"
                                    name="carrierMcNumber"
                                    autoFocus
                                    value={formik.values.carrierMcNumber}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.carrierMcNumber && formik.errors.carrierMcNumber && (
                                    <Alert severity="error">{formik.errors.carrierMcNumber}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6} />
                            <Grid item xs={6}>
                                <TextField 
                                    margin="normal"
                                    fullWidth
                                    id="dispatcherName"
                                    label="Dispatcher Name"
                                    name="dispatcherName"
                                    autoFocus
                                    value={formik.values.dispatcherName}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.dispatcherName && formik.errors.dispatcherName && (
                                    <Alert severity="error">{formik.errors.dispatcherName}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField 
                                    margin="normal"
                                    fullWidth
                                    id="dispatcherPhone"
                                    label="Dispatcher Phone Number"
                                    name="dispatcherPhone"
                                    autoFocus
                                    value={formik.values.dispatcherPhone}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.dispatcherPhone && formik.errors.dispatcherPhone && (
                                    <Alert severity="error">{formik.errors.dispatcherPhone}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="carrierEmail"
                                    label="Dispatcher Email"
                                    name="carrierEmail"
                                    autoFocus
                                    value={formik.values.carrierEmail}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.carrierEmail && formik.errors.carrierEmail && (
                                    <Alert severity="error">{formik.errors.carrierEmail}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6} />
                            <Grid item xs={6}>
                                <TextField 
                                    margin="normal"
                                    fullWidth
                                    id="driverName"
                                    label="Driver Name"
                                    name="driverName"
                                    autoFocus
                                    value={formik.values.driverName}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.driverName && formik.errors.driverName && (
                                    <Alert severity="error">{formik.errors.driverName}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField 
                                    margin="normal"
                                    fullWidth
                                    id="driverPhone"
                                    label="Driver Phone Number"
                                    name="driverPhone"
                                    autoFocus
                                    value={formik.values.driverPhone}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.driverPhone && formik.errors.driverPhone && (
                                    <Alert severity="error">{formik.errors.driverPhone}</Alert>
                                )}
                            </Grid>
                            {/* <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="carrierPhoneNumber"
                                    label="Carrier Phone Number"
                                    name="carrierPhoneNumber"
                                    autoFocus
                                    value={formik.values.carrierPhoneNumber}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.carrierPhoneNumber && formik.errors.carrierPhoneNumber && (
                                    <Alert severity="error">{formik.errors.carrierPhoneNumber}</Alert>
                                )}
                            </Grid> */}
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="pickupAndDeliveryETA"
                                    label="Pickup and Delivery ETA"
                                    name="pickupAndDeliveryETA"
                                    autoFocus
                                    value={formik.values.pickupAndDeliveryETA}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.pickupAndDeliveryETA && formik.errors.pickupAndDeliveryETA && (
                                    <Alert severity="error">{formik.errors.pickupAndDeliveryETA}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6} />
                            <Grid item xs={6}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="truckInHand"
                                                name="truckInHand"
                                                checked={formik.values.truckInHand}
                                                onChange={() => {
                                                    formik.setFieldValue("truckInHand", !formik.values.truckInHand);
                                                }}
                                            />
                                        }
                                        label="Truck In Hand"
                                        sx={{ pt: '21px', }}
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="notes"
                                    label="Notes"
                                    name="notes"
                                    multiline
                                    rows={4}
                                    autoFocus
                                    value={formik.values.notes}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.notes && formik.errors.notes && (
                                    <Alert severity="error">{formik.errors.notes}</Alert>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </Container>
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={formik.handleSubmit}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditQuoteForm;