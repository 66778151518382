import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useUpdateActivityMutation } from "../../state/salesApi";

const UpdateActivityForm = ({ activityData, onClose, onSubmit }) => {
  const [formErrors, setFormErrors] = useState([]);
  const [updateActivityMutation, { isLoading: updating }] =
    useUpdateActivityMutation();
  const [successMessage, setSuccessMessage] = useState("");

  const formik = useFormik({
    initialValues: {
      title: activityData.title,
      description: activityData.description,
      type: activityData.type,
      status: activityData.status,
    },
    validationSchema: Yup.object({
      title: Yup.string(),
      description: Yup.string().required("Description is required"),
      type: Yup.string().required("Type is required"),
      status: Yup.string().required("Status is required"),
    }),
    onSubmit: async (values, helpers) => {
      let errors = [];
      const updatedData = {
        title: values.title,
        description: values.description,
        type: values.type,
        status: values.status,
      };
      try {
        handleUpdate(updatedData);
      } catch (error) {
        errors.push(error);
        setFormErrors(errors);
        setSuccessMessage("");
      }
    },
  });

  const handleUpdate = async (updatedData) => {
    await updateActivityMutation({ id: activityData._id, data: updatedData })
      .then((response) => {
        formik.resetForm();
        if (response.data && response.data.message) {
          setSuccessMessage(response.data.message);
          formik.setValues({
            title: response.data.updatedActivity.title,
            description: response.data.updatedActivity.description,
            type: response.data.updatedActivity.type,
            status: response.data.updatedActivity.status,
          });
          onSubmit();
        } else {
          setSuccessMessage("No response received, data did not upload.");
        }
        setFormErrors([]);
      })
      .catch((error) => {
        console.log(error);
        setFormErrors([error.message]);
        setSuccessMessage("");
      });
  };

  const handleClose = () => {
    formik.resetForm();
    setTimeout(() => {
      onClose();
    }, 200);
  };

  const handleAlertClose = () => {
    setSuccessMessage("");
  };

  return (
    <Dialog open={true} onClose={(event, reason) => onClose(event, reason)}>
      <DialogTitle sx={{ textAlign: "center" }}>Update Activity</DialogTitle>
      <DialogContent sx={{ minWidth: "400px", width: "400px" }}>
        <Box component="form" onSubmit={formik.handleSubmit} noValidate>
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Type</InputLabel>
            <Select
              name="type"
              value={formik.values.type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <MenuItem value="call">Call</MenuItem>
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="listEmail">List Email</MenuItem>
              <MenuItem value="inPerson">In Person</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
            {formik.touched.type && formik.errors.type && (
              <Alert severity="error">{formik.errors.type}</Alert>
            )}
          </FormControl>
          {formik.values.type === "call" ? (
            <FormControl fullWidth margin="normal" required>
              <InputLabel>Title</InputLabel>
              <Select
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <MenuItem value="First Contact">First Contact</MenuItem>
                <MenuItem value="Follow Up">Follow Up</MenuItem>
                <MenuItem value="Voicemail">Voicemail</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <TextField
              margin="normal"
              fullWidth
              label="Title"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          )}
          {formik.touched.title && formik.errors.title && (
            <Alert severity="error">{formik.errors.title}</Alert>
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            multiline
            rows={4}
            label="Description"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.description && formik.errors.description && (
            <Alert severity="error">{formik.errors.description}</Alert>
          )}
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={formik.values.status}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <MenuItem value="upcoming">Upcoming</MenuItem>
              <MenuItem value="inProgress">In Progress</MenuItem>
              <MenuItem value="complete">Complete</MenuItem>
            </Select>
            {formik.touched.status && formik.errors.status && (
              <Alert severity="error">{formik.errors.status}</Alert>
            )}
          </FormControl>
          {formErrors.length ? (
            <Alert severity="error">Error updating activity.</Alert>
          ) : null}
          <DialogActions>
            <Button onClick={handleClose} color="error" variant="contained">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={updating}
            >
              Update
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
      {successMessage && (
        <Box sx={{ mt: 2 }}>
          <Alert severity="success" onClose={handleAlertClose}>
            {successMessage}
          </Alert>
        </Box>
      )}
    </Dialog>
  );
};

export default UpdateActivityForm;
