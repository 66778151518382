import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useState } from "react";
import { useGetGoogleCalendarEventsQuery } from "state/api";
import AddCalendarEventForm from "components/forms/AddCalendarEventForm";
import { useEffect } from "react";

// TODO: Come back to
const Calendar = ({ user, toggleCalendar }) => {
    const now = new Date();
    const oneWeekFromNow = new Date(now);
    oneWeekFromNow.setDate(now.getDate() + 7);
    const oneMonthFromNow = new Date(now);
    oneMonthFromNow.setDate(oneMonthFromNow.getMonth() + 1);
    // const [firstDate, setFirstDate] = useState(new Date(now.getFullYear(), now.getMonth(), 1));
    // const [lastDate, setLastDate] = useState(new Date(now.getFullYear(), now.getMonth() + 1, 0));
    const [range, setRange] = useState({
        startDate: new Date(now.getFullYear(), now.getMonth(), 1).toISOString().split("T")[0],
        endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString().split("T")[0]
    });
    const { 
        data: events, 
        isLoading, 
        refetch
    } = useGetGoogleCalendarEventsQuery(range);
    const [addEventOpen, setAddEventOpen] = useState(false);
    const [googleCalendarUrl, setGoogleCalendarUrl] = useState("");    

    const handleAddEventClose = () => {
        setAddEventOpen(false);
    }

    const handleGoogleCalendarUrlFetch = () => {
        setGoogleCalendarUrl('');
        setTimeout(() => {
            setGoogleCalendarUrl(`https://calendar.google.com/calendar/embed?src=${user.email}&ctz=America%2FChicago`)
        }, 500);
    };

    useEffect(() => {
        setTimeout(() => {
            setGoogleCalendarUrl(`https://calendar.google.com/calendar/embed?src=${user.email}&ctz=America%2FChicago`)
        }, 500);
    }, []);


    return (
        <Dialog
            open={true}
            onClose={toggleCalendar}
            maxWidth="xl" 
            fullWidth 
        >
            <DialogTitle>
                <Box sx={{ textAlign: "center", fontWeight: "bold", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    Calendar
                </Box>
            </DialogTitle>
            <DialogContent>
                {isLoading || googleCalendarUrl === '' ? (
                    <Skeleton animation="wave" variant="rounded" width="90%" height="30rem" sx={{ margin: '1.5rem 2.5rem', padding: 2 }}/>
                ) : (
                    <Box sx={{ textAlign: "center", fontWeight: "bold", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <iframe src={googleCalendarUrl} style={{border: "0", width: "80rem", height: "40rem", frameBorder: "0", scrolling: "no"}}></iframe>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setAddEventOpen(!addEventOpen)}
                >
                    <Typography variant="h6">Add Event</Typography>
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    onClick={toggleCalendar}
                >
                    Close
                </Button>
            </DialogActions>
            {addEventOpen && (
                <AddCalendarEventForm handleCloseEventForm={handleAddEventClose} googleCalendarRefetch={handleGoogleCalendarUrlFetch}/>
            )}
        </Dialog>
    )
}

export default Calendar;