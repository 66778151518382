import React, { useState, useEffect } from 'react';
import LightModeOutlined from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlined from "@mui/icons-material/DarkModeOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDropDownOutlined from "@mui/icons-material/ArrowDropDownOutlined";
import Leaderboard from "@mui/icons-material/Leaderboard";
import Email from "@mui/icons-material/Email";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
// import Message from "@mui/icons-material/Message";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FlexBetween from './FlexBetween';
import { useDispatch } from 'react-redux';
import { setMode } from 'state';
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";

import { useNavigate } from 'react-router-dom';
// import { useResetUserPasswordMutation } from '../../state/api';
// import ResetUserPasswordForm from 'components/forms/ResetUserPasswordForm';
import AgentLeaderboard from 'components/leaderboard/AgentLeaderboard';

// import Notifications from '../notifications/Notifications';
import { setupWebSocket } from '../helpers/WebSocketSetup';
// import SettingsMenu from 'components/tools/SettingsMenu';
import EmailInbox from 'components/tools/EmailInbox';
import Calendar from 'components/calendar/Calendar';
import MessageInbox from 'components/messages/MessageInbox';
import Settings from 'components/tools/Settings';
import UniversalSearchBar from 'components/searchbar/universalSearchBar/UniversalSearchBar';
import NotificationsNav from 'components/notifications/NotificationsNav';
import RichTooltip from './ui/RichTooltip';

function Navbar({
  user,
  isSidebarOpen,
  setIsSidebarOpen
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null)
  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null)
  // const [resetPassword, resetPasswordResult] = useResetUserPasswordMutation();
  // const [showPasswordForm, setShowPasswordForm] = useState(false);
  // const [successMessage, setSuccessMessage] = useState('');
  // const [errorMessage, setErrorMessage] = useState('');
  const [openEmailInbox, setOpenEmailInbox] = useState(false);
  const [openMessages, setOpenMessages] = useState(false);
  const [openLeaderboard, setOpenLeaderboard] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [socket, setSocket] = useState(null);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  // const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);

  useEffect(() => {
    const componentName = 'Notifications';
    const newSocket = setupWebSocket(process.env.REACT_APP_WEBSOCKET_URL, componentName);

    newSocket.on('initialNotifications', (initialNotifications) => {
      setNotifications(initialNotifications);
    });

    newSocket.on('newNotification', (newNotification) => {
      setNotifications((prevNotifications) => [...prevNotifications, newNotification]);
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  const handleCreateTestNotification = () => {
    const testNotification = {
      title: 'Test Notification',
      description: 'This is a test notification for testing purposes.',
      link: '/test-link',
    };

    if (socket) {
      socket.emit('createNotification', testNotification);
    }
  };

  const handleNotificationsClick = () => {
    setNotificationsOpen((prevOpen) => !prevOpen);
  };

  const handleEmailClick = () => {
    setOpenEmailInbox((prevOpen) => !prevOpen);
  };

  const handleMessageClick = () => {
    setOpenMessages((prevOpen) => !prevOpen);
  };

  const handleCalendarClick = () => {
    setOpenCalendar(!openCalendar);
  };

  const handleLeaderboardClick = () => {
    setOpenLeaderboard(!openLeaderboard)
  };

  const handleSettingsClick = (e) => {
    // setSettingsAnchorEl(e.currentTarget);
    setOpenSettings((prevOpen) => !prevOpen);
    /* setShowPasswordForm(true);
    setSuccessMessage(null);
    setErrorMessage(null); */
  };

  /* const handleClosePasswordForm = () => {
    setShowPasswordForm(false);
  }; */

  /* const handlePasswordReset = (newPassword) => {
    resetPassword({ newPassword })
      .unwrap()
      .then((response) => {
        setSuccessMessage(response);
        setTimeout(() => {
          setSuccessMessage(null);
        }, 2000);
        setErrorMessage(null);
      })
      .catch((error) => {
        setSuccessMessage(null);
        setErrorMessage(error);
      });
  }; */

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login', { replace: true });
  };

  return (
    <AppBar
      sx={{
        position: "static",
        background: "none",
        boxShadow: "none"
      }}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between"
        }}>
        {/*LEFT SIDE OF NAVBAR*/}
        <FlexBetween gap="2rem" sx={{ width: '40%', }}>
          <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            <MenuIcon />
          </IconButton>
          {user.role !== "agent" && user.role !== "dispatch" && (
            <UniversalSearchBar />
          )}
        </FlexBetween>

        {/*RIGHT SIDE OF NAVBAR*/}
        <FlexBetween gap="1.5rem">
          {user.role === "superadmin" && (
            <Button onClick={handleCreateTestNotification} variant="contained">
              Create Test Notification
            </Button>
          )}
          {/* <Notifications
            notifications={notifications}
            isOpen={notificationsOpen}
            onNotificationsClick={handleNotificationsClick}
            onClose={() => setNotificationsOpen(false)}
          /> */}
          {/* <NotificationsNav 
            notifications={notifications}
            onClose={() => setNotificationsOpen(false)}
          /> */}
          <RichTooltip
            placement="bottom-start"
            open={notificationsOpen}
            onClose={() => setNotificationsOpen(false)}
            content={<NotificationsNav notifications={notifications} user={user} socket={socket}/>}
          >
            <IconButton onClick={handleNotificationsClick}>
              <Badge badgeContent={notifications.length} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </RichTooltip>
          <IconButton onClick={handleEmailClick}>
            <Email />
          </IconButton>
          <IconButton onClick={handleCalendarClick}>
            <CalendarIcon sx={{ fontSize: "25px" }} />
          </IconButton>
          <IconButton onClick={handleLeaderboardClick}>
            <Leaderboard sx={{ fontSize: "25px" }} />
          </IconButton>
          <IconButton onClick={() => dispatch(setMode())}>
            {theme.palette.mode === 'dark' ? (
              <DarkModeOutlined sx={{ fontSize: "25px" }} />
            ) : (
              <LightModeOutlined sx={{ fontSize: "25px" }} />
            )}
          </IconButton>
          {/* <SettingsMenu 
            user={user}
            anchorRef={settingsAnchorEl}
            isOpen={openSettings}
            onSettingsClick={handleSettingsClick}
            onClose={() => setOpenSettings(false)}
          /> */}
          <IconButton onClick={handleSettingsClick}>
            <SettingsOutlined />
          </IconButton>
          <FlexBetween>
            <Button
              onClick={handleClick}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                testTransform: "none",
                gap: "1rem"
              }}
            >
              <Box textAlign="left">
                <Typography
                  fontWeight="bold"
                  fontSize="0.85rem"
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {user.name}
                </Typography>
                <Typography
                  fontWeight=""
                  fontSize="0.75rem"
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {user.role}
                </Typography>
                <Typography
                  fontSize="0.75rem"
                  fontWeight="bold"
                  sx={{ color: theme.palette.secondary[200] }}
                >
                  {user.phoneNumber}
                </Typography>
              </Box>
              <ArrowDropDownOutlined
                sx={{ color: theme.palette.secondary[300], fontSize: "25px" }}
              />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <MenuItem onClick={handleLogout}>Log Out</MenuItem>
            </Menu>
          </FlexBetween>
        </FlexBetween>
      </Toolbar>
      {/* {showPasswordForm && (
        <ResetUserPasswordForm
          isOpen={showPasswordForm}
          onClose={handleClosePasswordForm}
          onSubmit={handlePasswordReset}
          successMessage={successMessage}
          errorMessage={errorMessage}
        />
      )} */}
      {openEmailInbox && (
        <EmailInbox handleToggleEmailInbox={handleEmailClick} />
      )}
      {openMessages && (
        <MessageInbox toggleMessages={handleMessageClick} />
      )}
      {openCalendar && (
        <Calendar user={user} toggleCalendar={handleCalendarClick} />
      )}
      {openLeaderboard && (
        <AgentLeaderboard
          toggleLeaderboard={handleLeaderboardClick}
        />
      )}
      {openSettings && (
        <Settings user={user} onClose={handleSettingsClick} />
      )}
    </AppBar>
  );
}

export default Navbar;
