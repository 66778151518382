import React, { useState, useMemo } from 'react';
import { useGetAgentsQuery } from 'state/managementApi';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import AgentDetails from './AgentDetails';
// import AddEmployeeForm from '../forms/AddEmployeeForm';
import { ExpandLess, ExpandMore, Refresh as RefreshIcon } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const AgentsList = () => {
  const { data: agentsData = [], error, isLoading, refetch } = useGetAgentsQuery(null, { staleTime: 0 });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [selectedAgent, setSelectedAgent] = useState(null);
  const [sortField, setSortField] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');


  const handleOpenAgentDetails = (agentId) => {
    setSelectedAgent(agentId);
  };

  const handleCloseAgentDetails = (event, reason) => {
    if (reason !== "backdropClick") {
      setSelectedAgent(null);
    }
  };

  const handleRefresh = () => {
    refetch();
  };

  const handleSort = (field) => {
    if (field === sortField) {
      // Toggle the sort order if clicking on the same field
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Set the new sort field and default sort order to ascending
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const sortedAgents = useMemo(() => {
    return [...agentsData].sort((a, b) => {
      if (sortField) {
        const valueA = a[sortField] || "";
        const valueB = b[sortField] || "";
        if (typeof valueA === "string" && typeof valueB === "string") {
          return sortOrder === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        } else {
          return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
        };
      };
      return 0;
    })
  }, [agentsData, sortField, sortOrder])


  if (error) {
    return <Typography color="error">Error: {error.message}</Typography>;
  }

  if (isLoading) {
    return (
      <Box mt={1} width='90%' height='90%'>
        <Skeleton animation="wave" variant='rounded' width='100%' height='90%' sx={{ margin: '4.5rem 2.5rem 15.5rem 2.5rem', padding: 2 }} />
      </Box>
    )
  }

  if (!agentsData || agentsData.length === 0) {
    return (
      <Box>
        <Typography>No agents found.</Typography>
      </Box>
    );
  }

  return (
    <Box mt={1}>
      <Tooltip title="Refresh" placement="bottom">
        <IconButton onClick={handleRefresh} color="info" size="medium" sx={{ ml: 1, boxShadow: '0 5px 5px rgba(0, 0, 0, 0.2)' }}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
      <Typography
        variant="h6"
        sx={{
          paddingTop: '2px',
          paddingBottom: '2px',
          textAlign: 'center',
          mt: { xs: 2, sm: 0 },
        }}
      >
        Employees List

      </Typography>
      <TableContainer component={Paper} sx={{ marginBottom: '16px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h6"
                    component="span"
                    onClick={() => handleSort('name')}
                    style={{ cursor: 'pointer' }}
                  >
                    Name
                  </Typography>
                  {sortField !== "name" ? <ExpandMore /> : <ExpandLess />}
                </Box>
              </TableCell>
              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h6"
                    component="span"
                    onClick={() => handleSort('email')}
                    style={{ cursor: 'pointer' }}
                  >
                    Email
                  </Typography>
                  {sortField !== "email" ? <ExpandMore /> : <ExpandLess />}
                </Box>
              </TableCell>
              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h6"
                    component="span"
                    onClick={() => handleSort('phoneNumber')}
                    style={{ cursor: 'pointer' }}
                  >
                    Phone Number
                  </Typography>
                  {sortField !== "phoneNumber" ? <ExpandMore /> : <ExpandLess />}
                </Box>
              </TableCell>
              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h6"
                    component="span"
                    onClick={() => handleSort('cellNumber')}
                    style={{ cursor: 'pointer' }}
                  >
                    Cell Number
                  </Typography>
                  {sortField !== "cellNumber" ? <ExpandMore /> : <ExpandLess />}
                </Box>
              </TableCell>
              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h6"
                    component="span"
                    onClick={() => handleSort('role')}
                    style={{ cursor: 'pointer' }}
                  >
                    Role
                  </Typography>
                  {sortField !== "role" ? <ExpandMore /> : <ExpandLess />}
                </Box>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <TableBody>
              {sortedAgents.map((agent) => (
                <TableRow key={agent._id}>
                  <TableCell>{agent.name}</TableCell>
                  <TableCell>{agent.email}</TableCell>
                  <TableCell>{agent.phoneNumber}</TableCell>
                  <TableCell>{agent.cellNumber}</TableCell>
                  <TableCell>
                    {agent.role === 'salesAgent' ? 'Sales agent' : agent.role.charAt(0).toUpperCase() + agent.role.slice(1)}
                  </TableCell>
                  <TableCell>
                    <Button variant="contained" color="primary" onClick={() => handleOpenAgentDetails(agent._id)}>
                      View Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Dialog
        open={Boolean(selectedAgent)}
        onClose={handleCloseAgentDetails}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: isMobile ? '100%' : 'xl',
            margin: 0,
            maxHeight: isMobile ? '100%' : 'unset',
            borderRadius: 0,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
          },
        }}
      >
        <DialogTitle sx={{ textAlign: 'center' }}>Agent Details</DialogTitle>
        <DialogContent
          sx={{
            flex: '1 1 auto',
            overflow: 'auto',
            maxHeight: 'calc(100vh - 100px)',
          }}
        >
          {selectedAgent && (
            <Container
              component="main"
              maxWidth="xl"
              // padding="5px"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: theme.palette.primary[900],
                width: '100%',
                height: '100%',
                overflow: 'auto',
                '@media (max-width: 600px)': {
                  minWidth: '100%',
                  width: '50%',
                  padding: 0,
                  overflow: 'auto',
                  margin: 0,
                },
              }}
            >
              <AgentDetails agentId={selectedAgent} onAgentUpdate={handleRefresh} allAgents={agentsData} />
            </Container>
          )}
        </DialogContent>
        <Box sx={{ textAlign: 'center', paddingBottom: '4px', paddingTop: '4px' }}>
          <Button variant="contained" color="primary" onClick={handleCloseAgentDetails}>
            Close Agent Details
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default AgentsList;

