import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Add from '@mui/icons-material/Add';

const EditAgentForm = ({ agentData, onSaveAgent, onCancelEdit, updating, allAgents }) => {
  const [alternateEmailOpen, setAlternateEmailOpen] = useState(false);
  const [selectedAlternateEmail, setSelectedAlternateEmail] = useState('default');

  const [isOperationsMember, setIsOperationsMember] = useState(false);

  const handleAddAlternateEmail = () => {
    const newAltEmails = [...formik.values.alternateEmails, selectedAlternateEmail];
    formik.setFieldValue("alternateEmails", newAltEmails);
    setSelectedAlternateEmail('default');
    setAlternateEmailOpen(false);
  };

  const handleRemoveAlternateEmail = (altToRemove) => {
    formik.setFieldValue("alternateEmails", formik.values.alternateEmails.filter(alt => alt !== altToRemove));
  };

  const formik = useFormik({
    initialValues: {
      name: agentData.name || '',
      email: agentData.email || '',
      phoneNumber: agentData.phoneNumber || '',
      cellNumber: agentData.cellNumber || '',
      address: agentData.address || '',
      city: agentData.city || '',
      state: agentData.state || '',
      country: agentData.country || '',
      occupation: agentData.occupation || '',
      role: agentData.role || '',
      alternateEmails: agentData.alternateEmails || [],
      showStats: agentData.showStats || true,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email('Enter a valid email').required('Email is required'),
      phoneNumber: Yup.string(),
      cellNumber: Yup.string(),
      address: Yup.string(),
      city: Yup.string(),
      state: Yup.string(),
      country: Yup.string(),
      occupation: Yup.string(),
      role: Yup.string(),
      alternateEmails: Yup.array().of(Yup.string()).nullable(),
      showStats: Yup.bool(),
    }),
    onSubmit: (values) => {
      onSaveAgent(values, isOperationsMember);
    },
    enableReinitialize: true,
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ minWidth: { xs: 'sm', sm: 'md', md: 'lg' } }}>
      <TextField
        margin="normal"
        required
        fullWidth
        label="Name"
        name="name"
        autoComplete="name"
        autoFocus
        value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.name && formik.errors.name}
        helperText={formik.touched.name && formik.errors.name}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        label="Email Address"
        name="email"
        autoComplete="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.email && formik.errors.email}
        helperText={formik.touched.email && formik.errors.email}
      />
      <TextField
        margin="normal"
        fullWidth
        label="Phone Number"
        name="phoneNumber"
        autoComplete="phoneNumber"
        value={formik.values.phoneNumber}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.phoneNumber && formik.errors.phoneNumber}
        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
      />
      <TextField
        margin="normal"
        fullWidth
        label="Cell Number"
        name="cellNumber"
        autoComplete="cellNumber"
        value={formik.values.cellNumber}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.cellNumber && formik.errors.cellNumber}
        helperText={formik.touched.cellNumber && formik.errors.cellNumber}
      />
      <TextField
        margin="normal"
        fullWidth
        label="Address"
        name="address"
        autoComplete="address"
        value={formik.values.address}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.address && formik.errors.address}
        helperText={formik.touched.address && formik.errors.address}
      />
      <TextField
        margin="normal"
        fullWidth
        label="City"
        name="city"
        autoComplete="city"
        value={formik.values.city}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.city && formik.errors.city}
        helperText={formik.touched.city && formik.errors.city}
      />
      <TextField
        margin="normal"
        fullWidth
        label="State"
        name="state"
        autoComplete="state"
        value={formik.values.state}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.state && formik.errors.state}
        helperText={formik.touched.state && formik.errors.state}
      />
      <FormControl fullWidth margin="normal" error={formik.touched.country && formik.errors.country}>
        <InputLabel id="country-label">Country</InputLabel>
        <Select
          labelId="country-label"
          id="country"
          name="country"
          value={formik.values.country}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          <MenuItem value="US">US</MenuItem>
          <MenuItem value="CAN">CAN</MenuItem>
          <MenuItem value="MEX">MEX</MenuItem>
        </Select>
        {formik.touched.country && formik.errors.country && (
          <FormHelperText>{formik.errors.country}</FormHelperText>
        )}
      </FormControl>
      <TextField
        margin="normal"
        fullWidth
        label="Occupation"
        name="occupation"
        autoComplete="occupation"
        value={formik.values.occupation}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.occupation && formik.errors.occupation}
        helperText={formik.touched.occupation && formik.errors.occupation}
      />
      <FormControl fullWidth margin="normal" error={formik.touched.role && formik.errors.role}>
        <InputLabel id="role-label">Role</InputLabel>
        <Select
          labelId="role-label"
          id="role"
          name="role"
          value={formik.values.role}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
          <MenuItem value="salesAgent">Sales Agent</MenuItem>
          <MenuItem value="agent">Agent</MenuItem>
          <MenuItem value="dispatch">Dispatch</MenuItem>
          <MenuItem value="manager">Manager</MenuItem>
        </Select>
        {formik.touched.role && formik.errors.role && (
          <FormHelperText>{formik.errors.role}</FormHelperText>
        )}
      </FormControl>
      <Typography sx={{ fontSize: '12px', ml: '5px', mt: '15px' }}>Alternate Emails</Typography>
      <FormControl
        fullWidth
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: '10px' }}
      >
        <Box sx={{ width: '70%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {formik.values.alternateEmails.map((alt, index) => (
            <Chip
              key={index}
              label={alt}
              onDelete={() => handleRemoveAlternateEmail(alt)}
              sx={{ mr: 3, mt: '10px' }}
            />
          ))}
        </Box>
        <Box sx={{ width: '60%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
          {!alternateEmailOpen ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setAlternateEmailOpen(!alternateEmailOpen)}
            >
              <Add sx={{ mr: '5px' }} />
              Add Alternate Email
            </Button>
          ) : (
            <>
              <Select
                value={selectedAlternateEmail}
                onChange={(e) => setSelectedAlternateEmail(e.target.value)}
                sx={{ mr: '15px', width: '50%', height: '70%' }}
              >
                <MenuItem value="default" sx={{ display: 'none' }}>Select an email</MenuItem>
                {allAgents.map((agent, index) => (
                  agent.email !== agentData.email && !formik.values.alternateEmails.find((alt) => alt === agent.email) && (
                    <MenuItem key={index} value={agent.email}>{agent.email}</MenuItem>
                  )
                ))}
              </Select>
              <Button
                variant="contained"
                sx={{ height: '55%', width: '10%', mr: '15px' }}
                onClick={handleAddAlternateEmail}
              >
                <Add />
                Add
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => setAlternateEmailOpen(!alternateEmailOpen)}
                sx={{ height: '55%', width: '10%' }}
              >
                Cancel
              </Button>
            </>
          )}
        </Box>
      </FormControl>
      <Divider sx={{ mt: '0px' }} />
      <Box sx={{ mt: 1, mb: 2, display: "flex", justifyContent: "center" }}>
        <Button
          type="submit"
          // fullWidth
          variant="contained"
          sx={{ mr: 1 }}
          disabled={updating}
        >
          Save
        </Button>
        <Button
          // fullWidth
          variant="contained"
          color="error"
          sx={{ ml: 1 }}
          onClick={onCancelEdit}
          disabled={updating}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default EditAgentForm;

