import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { useGetAllUserStatsByDayQuery, useUpdateAgentMutation } from "state/managementApi";
import { useEffect, useMemo, useState } from "react";
import { isWithinBusinessHours } from "components/helpers/CheckBusinessHours";
import Close from "@mui/icons-material/Close";
import Edit from "@mui/icons-material/Edit";
import ConfirmationDialog from "components/helpers/ConfirmDialog";
import AddAgentStatTrackingForm from "manager/stats/AddAgentStatTrackingForm";
import EditAgentDayStatsForm from "./EditAgentDayStatsForm";

const now = new Date();

const todayString = now.getFullYear() + '-' +
    String(now.getMonth() + 1).padStart(2, '0') + '-' +
    String(now.getDate()).padStart(2, '0');


// HTML for week day headers
const WeekDayHeaders = () => {
    const cellStyles = {
        borderLeft: '0.5px solid gray',
        borderRight: '0.5px solid gray',
    };

    return (
        <>
            <TableCell style={cellStyles}>Margin</TableCell>
            <TableCell style={cellStyles}>New Calls</TableCell>
            <TableCell style={cellStyles}>FU Calls</TableCell>
            <TableCell style={cellStyles}>Packets</TableCell>
            <TableCell style={cellStyles}>Quotes</TableCell>
            <TableCell style={cellStyles}>FU Emails</TableCell>
            <TableCell style={cellStyles}>List Emails</TableCell>
            <TableCell style={cellStyles}>Loads Booked</TableCell>
            <TableCell style={cellStyles}>Set-Ups</TableCell>
        </>
    )
};

const StatsTable = ({ agents }) => {
    const [selectedDay, setSelectedDay] = useState(todayString);
    const [querySkip, setQuerySkip] = useState(true);
    const [addOpen, setAddOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false);
    const [agentToEdit, setAgentToEdit] = useState(null);
    const [removeOpen, setRemoveOpen] = useState(false);
    const [agentToRemove, setAgentToRemove] = useState(null)

    const { data: userStats = [], isLoading: userStatsLoading, error: userStatsError, refetch: refetchStats } = useGetAllUserStatsByDayQuery({ date: selectedDay }, { skip: querySkip });

    const [updateAgentTracking] = useUpdateAgentMutation();

    const filteredStats = useMemo(() => {
        return [...agents].sort((a, b) => {
            const valueA = a.userName || "";
            const valueB = b.userName || "";
            return valueA.localeCompare(valueB);
        }).filter((agent) => agent?.showStats !== false);
    }, [agents]);

    const sortedStats = useMemo(() => {
        return [...userStats].sort((a, b) => {
            const valueA = a.userName || "";
            const valueB = b.userName || "";
            return valueA.localeCompare(valueB);
        })
    }, [userStats]).filter((agentStats) => agents.find((agent) => (agent.name === agentStats.userName && agent.showStats)));

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (isWithinBusinessHours() && !querySkip) {
                refetchStats();
            }
        }, 15 * 60 * 1000); // Refresh every 15 minutes

        return () => clearInterval(intervalId);
    }, [refetchStats]);

    const toggleRemoveOpen = () => setRemoveOpen(prev => !prev);
    const toggleAddOpen = () => setAddOpen(prev => !prev);
    const toggleEditOpen = () => setEditOpen(prev => !prev);

    const handleAddAgent = async (agent) => {
        try {
            await updateAgentTracking({
                agentId: agent._id, data: {
                    showStats: true,
                    name: agent.name,
                    address: agent.address,
                    city: agent.city,
                    state: agent.state,
                    occupation: agent.occupation,
                    country: agent.country,
                    phoneNumber: agent.phoneNumber,
                    cellNumber: agent.cellNumber,
                    role: agent.role,
                    alternateEmails: agent.alternateEmails,
                }
            })
                .then(() => {
                    toggleAddOpen();
                })
        } catch (error) {
            console.error('Error adding stat tracking:', error);
        }
    };

    const handleRemoveAgent = async () => {
        try {
            await updateAgentTracking({
                agentId: agentToRemove._id, data: {
                    showStats: false,
                    name: agentToRemove.name,
                    address: agentToRemove.address,
                    city: agentToRemove.city,
                    state: agentToRemove.state,
                    occupation: agentToRemove.occupation,
                    country: agentToRemove.country,
                    phoneNumber: agentToRemove.phoneNumber,
                    cellNumber: agentToRemove.cellNumber,
                    role: agentToRemove.role,
                    alternateEmails: agentToRemove.alternateEmails,
                }
            })
                .then(() => {
                    toggleRemoveOpen();
                    setAgentToRemove(null);
                });
        } catch (error) {
            console.error('Error removing stat tracking:', error);
        }
    };

    const cellStyles = {
        borderLeft: '0.5px solid gray',
        borderRight: '0.5px solid gray',
    };

    if (userStatsLoading) return <CircularProgress />;

    if (userStatsError) return <p>Error retrieving stats</p>;

    return (
        <Box sx={{ mt: '1rem' }}>
            <Box>
                <Typography>Date:</Typography>
                <input
                    name="statsDate"
                    type="date"
                    max={todayString}
                    value={selectedDay}
                    onChange={(e) => {
                        const newDate = new Date(e.target.value);
                        setSelectedDay(newDate.toISOString().split("T")[0]);
                        setQuerySkip(false);
                    }}
                />
            </Box>
            <TableContainer component={Paper} sx={{ overflow: 'auto', maxHeight: 570 }}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Broker</TableCell>
                            <WeekDayHeaders />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedDay === todayString ? (
                            filteredStats.map((agent, index) => (
                                <TableRow hover key={index}>
                                    <TableCell sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        {agent.name}
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setAgentToRemove(agent);
                                                toggleRemoveOpen();
                                            }}
                                        >
                                            <Close />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell style={cellStyles}>
                                        ${agent.margin || 0}
                                    </TableCell>
                                    <TableCell style={cellStyles}>
                                        {agent.dailyNewCalls || 0}
                                    </TableCell>
                                    <TableCell style={cellStyles}>
                                        {agent.dailyCalls || 0}
                                    </TableCell>
                                    <TableCell style={cellStyles}>
                                        {agent.packets || 0}
                                    </TableCell>
                                    <TableCell style={cellStyles}>
                                        {agent.quotes || 0}
                                    </TableCell>
                                    <TableCell style={cellStyles}>
                                        {agent.dailyEmails || 0}
                                    </TableCell>
                                    <TableCell style={cellStyles}>
                                        {agent.dailyListEmails || 0}
                                    </TableCell>
                                    <TableCell style={cellStyles}>
                                        {agent.loadsBooked || 0}
                                    </TableCell>
                                    <TableCell style={cellStyles}>
                                        {agent.setups || 0}
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            sortedStats.map((agent, index) => (
                                <TableRow hover key={index}>
                                    <TableCell sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        {agent.userName}
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setAgentToEdit(agent);
                                                toggleEditOpen();
                                            }}
                                        >
                                            <Edit />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell style={cellStyles}>
                                        ${agent.margin || 0}
                                    </TableCell>
                                    <TableCell style={cellStyles}>
                                        {agent.dailyNewCalls || 0}
                                    </TableCell>
                                    <TableCell style={cellStyles}>
                                        {agent.dailyCalls || 0}
                                    </TableCell>
                                    <TableCell style={cellStyles}>
                                        {agent.packets || 0}
                                    </TableCell>
                                    <TableCell style={cellStyles}>
                                        {agent.quotes || 0}
                                    </TableCell>
                                    <TableCell style={cellStyles}>
                                        {agent.dailyEmails || 0}
                                    </TableCell>
                                    <TableCell style={cellStyles}>
                                        {agent.dailyListEmails || 0}
                                    </TableCell>
                                    <TableCell style={cellStyles}>
                                        {agent.loadsBooked || 0}
                                    </TableCell>
                                    <TableCell style={cellStyles}>
                                        {agent.setups || 0}
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                        {/* <TableRow>
                            <TableCell>
                                <Typography 
                                    fontWeight="bold" 
                                    sx={{ 'textDecoration': 'underline' }}
                                >
                                    TOTAL
                                </Typography>
                            </TableCell>
                        </TableRow> */}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button
                variant="contained"
                color="primary"
                onClick={toggleAddOpen}
                sx={{ mt: '15px' }}
            >
                Add Agent Tracking
            </Button>
            {removeOpen && (
                <ConfirmationDialog
                    open={removeOpen}
                    onClose={toggleRemoveOpen}
                    onConfirm={handleRemoveAgent}
                    title={`Confirm Removal`}
                    message={`Are you sure you want to remove stat tracking for ${agentToRemove.name || ''}?`}
                />
            )}
            {editOpen && (
                <EditAgentDayStatsForm
                    stats={agentToEdit}
                    onClose={toggleEditOpen}
                />
            )}
            {addOpen && (
                <AddAgentStatTrackingForm
                    agents={agents}
                    onClose={toggleAddOpen}
                    onConfirm={handleAddAgent}
                />
            )}
        </Box>
    )
};

export default StatsTable;