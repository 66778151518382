import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import ActivityLeadList from "../activities/ActivityLeadList";
import { calculateDSLA } from "components/helpers/CalculateDSLA";
import { DateFormatter } from "components/helpers/DateTimeHelpers";

const LeadInfo = ({ lead, showActivityList }) => {
  const theme = useTheme();

  const handleDSLA = (DSLA) => {
    return calculateDSLA(DSLA);
  };

  return (
    <div sx={{ width: "100%" }}>
      <Grid container direction="row" justifyContent="space-around">
        <Grid item xs={3}>
          <Box>
            <Typography variant="h6" fontWeight="bold" mb="5px">
              Client Information
            </Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableBody>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">Contact Name</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      <Typography>
                        {!lead.contactName && !lead.contactLastName
                          ? ""
                          : `${lead.contactName} ${lead.contactLastName}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">Main Phone</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      <Typography>{lead.contactNumber || ""}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">Email</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      <Typography>{lead.contactEmail || ""}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">Secondary Email</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      <Typography>{lead?.secondaryEmail || ""}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="h6" fontWeight="bold" mt="30px" mb="5px">
              Business Information
            </Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableBody>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">Company Name</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      <Typography>{lead.name || ""}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">
                        Main Business Type
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      <Typography>{lead.commodity || ""}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">Website</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      <Typography>{lead.website || ""}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">
                        Business Address
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      <Typography>
                        {lead.city || ""} {lead.state || ""}{" "}
                        {lead.country || ""} {lead.zipCode || ""}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <Typography variant="h6" fontWeight="bold" mb="5px">
              Lead Information
            </Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableBody>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">Title</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {lead.title || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">Next Step</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {lead.nextStep || ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="h6" fontWeight="bold" mt="30px" mb="5px">
              Financial Information
            </Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableBody>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">AP Name</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {lead.APName || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">AP Email</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {lead.APEmail || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">AP Number</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {lead.APNumber || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">Credit Limit</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {lead.creditLimit || ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <Typography variant="h6" fontWeight="bold" mb="5px">
              Activity Information
            </Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableBody>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">DSLE</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {handleDSLA(lead.DSLE) === 0
                        ? "Today"
                        : handleDSLA(lead.DSLE) > 0
                        ? handleDSLA(lead.DSLE)
                        : "Not Emailed"}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">DSLC</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {handleDSLA(lead.DSLC) === 0
                        ? "Today"
                        : handleDSLA(lead.DSLC) > 0
                        ? handleDSLA(lead.DSLC)
                        : "Not Called"}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">Date Setup</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {DateFormatter(lead.dateSetup || "")}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">
                        First Load Moved
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {DateFormatter(lead.firstLoadMoved || "")}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">Last Load Moved</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {DateFormatter(lead.lastLoadMoved || "")}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">Transfer Date</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {DateFormatter(lead.transferDate || "")}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
            <Grid item xs={12} sx={{ mt: 2 }}>
              {showActivityList && <ActivityLeadList lead={lead} />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <style>
        {`
          .lead-website-link {
            color: ${theme.palette.primary.contrastText};
            text-decoration: none;
          }
    
          .lead-website-link:hover {
            text-decoration: underline;
          }
          `}
      </style>
    </div>
  );
};

export default LeadInfo;
