import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUpdateAgentGoalsMutation } from '../../state/managementApi';
import { Close } from '@mui/icons-material';
import { hoverStyle } from 'components/helpers/MediaQueries';

const SetAgentGoalsForm = ({ agentData, setAgentData, onSaveAgentGoals, onCancel, refetch }) => {
  const initialValues = {
    callGoals: agentData.callGoals.toString(),
    newCallGoals: agentData.newCallGoals.toString(),
    emailGoals: agentData.emailGoals.toString(),
    dailyListEmailGoals: agentData.dailyListEmailGoals.toString(),
    // meetingGoals: agentData.meetingGoals.toString(),
    setupGoals: agentData.setupGoals.toString(),
    packetGoals: agentData.packetGoals.toString(),
    marginGoals: agentData.marginGoals.toString(),
    quoteGoals: agentData.quoteGoals.toString(),
    loadsBookedGoals: agentData.loadsBookedGoals.toString(),
  };

  const validationSchema = Yup.object({
    callGoals: Yup.number()
      .typeError('Enter a valid number')
      .min(0, 'Enter a positive number')
      .required('Call Goal is required'),
    newCallGoals: Yup.number()
      .typeError('Enter a valid number')
      .min(0, 'Enter a positive number')
      .required('New Call Goal is required'),
    emailGoals: Yup.number()
      .typeError('Enter a valid number')
      .min(0, 'Enter a positive number')
      .required('Email Goal is required'),
    dailyListEmailGoals: Yup.number()
      .typeError('Enter a valid number')
      .min(0, 'Enter a positive number')
      .required('Email List Goal is required'),
      /* meetingGoals: Yup.number()
      .typeError('Enter a valid number')
      .min(0, 'Enter a positive number')
      .required('Meeting Goal is required'), */
      setupGoals: Yup.number()
        .typeError('Enter a valid number')
        .min(0, 'Enter a positive number')
        .required('Setup Goal is required'),
      packetGoals: Yup.number()
        .typeError('Enter a valid number')
        .min(0, 'Enter a positive number')
        .required('Packet Goal is required'),
      marginGoals: Yup.number()
        .typeError('Enter a valid number')
        .min(0, 'Enter a positive number')
        .required('Packet Goal is required'),
      quoteGoals: Yup.number()
        .typeError('Enter a valid number')
        .min(0, 'Enter a positive number')
        .required('Quote Goal is required'),
      loadsBookedGoals: Yup.number()
        .typeError('Enter a valid number')
        .min(0, 'Enter a positive number')
        .required('Loads Booked Goal is required'),
    });

  const [updateAgentGoals, { isLoading: updatingGoals }] = useUpdateAgentGoalsMutation();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const updatedGoals = {
        callGoals: parseInt(values.callGoals),
        newCallGoals: parseInt(values.newCallGoals),
        emailGoals: parseInt(values.emailGoals),
        dailyListEmailGoals: parseInt(values.dailyListEmailGoals),
        // meetingGoals: parseInt(values.meetingGoals),
        setupGoals: parseInt(values.setupGoals),
        packetGoals: parseInt(values.packetGoals),
        marginGoals: parseInt(values.marginGoals),
        quoteGoals: parseInt(values.quoteGoals),
        loadsBookedGoals: parseInt(values.loadsBookedGoals),
      };
      updateAgentGoals({ agentId: agentData._id, data: updatedGoals })
        .unwrap()
        .then(() => {
          // setAgentData((prevData) => ({ ...prevData, ...updatedGoals }));
          refetch();
          onSaveAgentGoals(updatedGoals);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },
  });

  return (
    <Dialog open onClose={onCancel}>
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography>Set Agent Goals</Typography>
          <Close sx={hoverStyle} onClick={onCancel}/>
        </div>
        <Divider sx={{ m: '10px 0px', }}/>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            label="Margin Goal"
            name="marginGoals"
            value={formik.values.marginGoals}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.marginGoals && Boolean(formik.errors.marginGoals)}
            helperText={formik.touched.marginGoals && formik.errors.marginGoals}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            margin="normal"
          />
          <TextField
            label="New Call Goal"
            name="newCallGoals"
            value={formik.values.newCallGoals}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.newCallGoals && Boolean(formik.errors.newCallGoals)}
            helperText={formik.touched.newCallGoals && formik.errors.newCallGoals}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Follow Up Call Goal"
            name="callGoals"
            value={formik.values.callGoals}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.callGoals && Boolean(formik.errors.callGoals)}
            helperText={formik.touched.callGoals && formik.errors.callGoals}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Packet Goal"
            name="packetGoals"
            value={formik.values.packetGoals}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.packetGoals && Boolean(formik.errors.packetGoals)}
            helperText={formik.touched.packetGoals && formik.errors.packetGoals}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email Goal"
            // label="Follow Up Email Goal"
            name="emailGoals"
            value={formik.values.emailGoals}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.emailGoals && Boolean(formik.errors.emailGoals)}
            helperText={formik.touched.emailGoals && formik.errors.emailGoals}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            margin="normal"
          />
          <TextField
            label="List Email Goal"
            name="dailyListEmailGoals"
            value={formik.values.dailyListEmailGoals}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.dailyListEmailGoals && Boolean(formik.errors.dailyListEmailGoals)}
            helperText={formik.touched.dailyListEmailGoals && formik.errors.dailyListEmailGoals}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            margin="normal"
          />
          {/* <TextField
            label="Meeting Goal"
            name="meetingGoals"
            value={formik.values.meetingGoals}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.meetingGoals && Boolean(formik.errors.meetingGoals)}
            helperText={formik.touched.meetingGoals && formik.errors.meetingGoals}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            margin="normal"
          /> */}
          <TextField
            label="Quote Goal"
            name="quoteGoals"
            value={formik.values.quoteGoals}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.quoteGoals && Boolean(formik.errors.quoteGoals)}
            helperText={formik.touched.quoteGoals && formik.errors.quoteGoals}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Loads Booked Goal"
            name="loadsBookedGoals"
            value={formik.values.loadsBookedGoals}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.loadsBookedGoals && Boolean(formik.errors.loadsBookedGoals)}
            helperText={formik.touched.loadsBookedGoals && formik.errors.loadsBookedGoals}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Setup Goal"
            name="setupGoals"
            value={formik.values.setupGoals}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.setupGoals && Boolean(formik.errors.setupGoals)}
            helperText={formik.touched.setupGoals && formik.errors.setupGoals}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            margin="normal"
          />
          <DialogActions>
            <Button type="submit" color="primary" variant="contained" disabled={updatingGoals}>
              {updatingGoals ? 'Updating...' : 'Save'}
            </Button>
            <Button onClick={onCancel} color="error">
              Close
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SetAgentGoalsForm;
