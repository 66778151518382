import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import { TreeItem } from "@mui/x-tree-view";
import { useState } from "react";
import { useGetLeadsByStatusAndCategoryQuery } from "state/managementApi";
import PoolLeadDetails from "./PoolLeadDetails";
import useContextMenu from "components/hooks/useContextMenu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { calculateDSLA } from "components/helpers/CalculateDSLA";

const CategoryLeadsTree = ({
  categories,
  category,
  selectedPoolLead,
  setSelectedPoolLead,
  selectedMultipleLeads,
  setSelectedMultipleLeads,
  selectedPages,
  setSelectedPages,
}) => {
  const theme = useTheme();
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortField, setSortField] = useState("name");
  const itemsPerPage = 100; // Change this to set the number of items per page
  const [page, setPage] = useState(1);

  const { clicked, setClicked, points, setPoints } = useContextMenu();
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    data: categoryLeads = [],
    isLoading: categoryLeadsLoading,
    refetch,
  } = useGetLeadsByStatusAndCategoryQuery({
    status: "pool",
    page,
    limit: itemsPerPage,
    categoryId: category?._id,
    commodity: "",
    sortField,
    sortOrder,
  });

  // Right click menu handlers
  const handleMenuContext = (e) => {
    e.preventDefault();
    setClicked(true);
    setPoints({
      x: e.clientX,
      y: e.clientY,
    });
    const anchorElement = document.elementFromPoint(e.clientX, e.clientY);
    setAnchorEl(anchorElement);
  };

  const handleLeadCheckbox = (selectedLead) => {
    const isSelected = selectedMultipleLeads.includes(selectedLead);
    if (isSelected) {
      setSelectedMultipleLeads(
        selectedMultipleLeads.filter((lead) => lead !== selectedLead)
      );
    } else {
      setSelectedMultipleLeads([...selectedMultipleLeads, selectedLead]);
    }
  };

  const toggleAllLeads = () => {
    // If all leads on current page are selected, deselect all; otherwise, select all
    if (selectedPages.includes(page)) {
      setSelectedMultipleLeads((prev) =>
        prev.filter((lead) => !categoryLeads.leads.includes(lead))
      );
      setSelectedPages((prev) =>
        prev.filter((selectedPage) => selectedPage !== page)
      );
    } else {
      setSelectedMultipleLeads((prev) => [...prev, ...categoryLeads.leads]);
      setSelectedPages((prev) => [...prev, page]);
    }
  };

  const handleLeadUpdate = (newLead) => {
    setSelectedPoolLead(newLead);
  };

  const handleCategoryChange = () => {
    refetch();
  };

  const handleLeadRemoval = (leadId) => {
    const isSelected = selectedMultipleLeads.find(
      (selectedLead) => selectedLead._id === leadId
    );
    if (isSelected) {
      setSelectedMultipleLeads(
        selectedMultipleLeads.filter((lead) => lead._id !== leadId)
      );
    }
    setSelectedPoolLead(null);
  };

  // Sorting handlers
  const handleSort = (category) => {
    if (sortField === category) {
      setSortField(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(category);
      setSortOrder("asc");
      setPage(1);
    }
  };

  function shortenURL(url, maxLength) {
    if (url && url.length > maxLength) {
      return url.slice(0, maxLength - 3) + "...";
    }
    return url;
  }

  const ensureHttpPrefix = (url) => {
    // Check if the URL starts with "http://"
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return `http://${url}`;
    }
    return url;
  };

  const handleDSLA = (DSLA) => {
    return calculateDSLA(DSLA);
  };

  const handlePageChange = (e, newPage) => {
    e.preventDefault();
    setPage(newPage);
  };

  if (categoryLeadsLoading) {
    return <TreeItem itemId="spinner" label={<CircularProgress />} />;
  }

  return (
    <>
      {selectedPoolLead ? (
        <PoolLeadDetails
          lead={categoryLeads.leads.find(
            (lead) => lead._id === selectedPoolLead._id
          )}
          onClose={() => setSelectedPoolLead(null)}
          onEdit={handleLeadUpdate}
          onMove={handleCategoryChange}
          onAssign={handleLeadRemoval}
          onDelete={handleLeadRemoval}
          categories={categories}
        />
      ) : (
        <div style={{ margin: "35px 0px" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              count={Math.ceil(categoryLeads.total / itemsPerPage)}
              page={page}
              onChange={(e, newPage) => {
                handlePageChange(e, newPage);
              }}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={6}>
              <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
                Showing {categoryLeads.leads.length} out of{" "}
                {categoryLeads.total} total leads.
              </Typography>
            </Grid>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={6}>
              <Typography
                variant="subtitle2"
                sx={{
                  display: selectedMultipleLeads.length ? "block" : "none",
                }}
              >
                {selectedMultipleLeads?.length}{" "}
                {selectedMultipleLeads?.length > 1 ? "Leads" : "Lead"} Selected
              </Typography>
            </Grid>
          </div>
          <Box mt={4}>
            <TableContainer component={Paper} sx={{ maxHeight: 540 }}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ padding: "0 10px", textAlign: "center" }}>
                      <Checkbox
                        checked={selectedPages.includes(page)}
                        onChange={toggleAllLeads}
                      />
                      Select Page
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("name")}
                          style={{ cursor: "pointer" }}
                        >
                          Company Name
                        </Typography>
                        {sortField !== "name" ? <ExpandMore /> : <ExpandLess />}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("commodity")}
                          style={{ cursor: "pointer" }}
                        >
                          Commodity
                        </Typography>
                        {sortField !== "commodity" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("state")}
                          style={{ cursor: "pointer" }}
                        >
                          State
                        </Typography>
                        {sortField !== "state" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("contactNumber")}
                          style={{ cursor: "pointer" }}
                        >
                          Phone Number
                        </Typography>
                        {sortField !== "contactNumber" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("website")}
                          style={{ cursor: "pointer" }}
                        >
                          Website
                        </Typography>
                        {sortField !== "website" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("title")}
                          style={{ cursor: "pointer" }}
                        >
                          Title
                        </Typography>
                        {sortField !== "title" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() =>
                            handleSort("recentactivitydescription")
                          }
                          style={{ cursor: "pointer" }}
                        >
                          Recent Activity
                        </Typography>
                        {sortField !== "recentactivitydescription" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("recentActivity.date")}
                          style={{ cursor: "pointer" }}
                        >
                          DSLA
                        </Typography>
                        {sortField !== "recentActivity.date" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categoryLeads.length === 0 ? (
                    <TableRow>
                      <TableCell>
                        <span>No Leads Available</span>
                      </TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  ) : (
                    categoryLeads.leads.map((lead) => (
                      <TableRow
                        key={lead._id}
                        sx={{
                          boxShadow:
                            lead.DNA.status && theme.palette.mode === "dark"
                              ? "inset 0 0 0 1000px #761600"
                              : lead.DNA.status && theme.palette.mode !== "dark"
                              ? "inset 0 0 0 1000px #FF9177"
                              : "none",
                        }}
                      >
                        <TableCell
                          sx={{
                            padding: "0 30px",
                            textAlign: "center",
                            lineHeight: "0",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={
                              !!selectedMultipleLeads.find(
                                (selected) => selected._id === lead._id
                              )
                            }
                            onChange={() => handleLeadCheckbox(lead)}
                            onClick={(e) => e.stopPropagation()}
                          />
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            setSelectedPoolLead(lead);
                          }}
                        >
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            {lead.name}
                          </span>
                        </TableCell>
                        <TableCell>{lead?.commodity}</TableCell>
                        <TableCell>{lead.state}</TableCell>
                        <TableCell>{lead.contactNumber}</TableCell>
                        <TableCell>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {lead.website.length > 30 ? (
                              <Tooltip title={lead.website}>
                                <span style={{ cursor: "pointer" }}>
                                  <a
                                    href={ensureHttpPrefix(lead.website)}
                                    target="_blank"
                                    style={{
                                      color: "inherit",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    {shortenURL(lead.website, 30)}
                                  </a>
                                </span>
                              </Tooltip>
                            ) : (
                              <Tooltip title={lead.website}>
                                <span style={{ cursor: "pointer" }}>
                                  <a
                                    href={ensureHttpPrefix(lead.website)}
                                    target="_blank"
                                    style={{
                                      color: "inherit",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    {lead.website}
                                  </a>
                                </span>
                              </Tooltip>
                            )}
                          </div>
                        </TableCell>
                        <TableCell>{lead.title || ""}</TableCell>
                        <TableCell>
                          {lead.recentActivity?.description || "No Activity."}
                        </TableCell>
                        <TableCell>
                          {handleDSLA(lead.recentActivity?.date) === 0
                            ? "Today"
                            : handleDSLA(lead.recentActivity?.date) > 0
                            ? handleDSLA(lead.recentActivity?.date)
                            : "New"}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>
      )}
    </>
  );
};

export default CategoryLeadsTree;
