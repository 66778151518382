import Close from "@mui/icons-material/Close";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetAgentsQuery, useMoveLeadsBetweenUsersMutation } from "state/managementApi";

const ChangeOwnerForm = ({ lead, onClose }) => {
    const userData = useSelector((state) => state.user);
    const [selectedAgentDisplayName, setSelectedAgentDisplayName] = useState('default');
    const [selectedAgent, setSelectedAgent] = useState(null);

    const { 
        data: employeesData = [], 
        error: employeesError, 
        loading: employeesLoading, 
    } = useGetAgentsQuery();
    const [transferLead, { isLoading: moveLeadsLoading }] = useMoveLeadsBetweenUsersMutation();

    const sortedAgents = [...employeesData].sort((a, b) => {
        const valueA = a.name || "";
        const valueB = b.name || "";
        return valueA.localeCompare(valueB);
    });

    const handleChange = (e) => {
      setSelectedAgentDisplayName(e.target.value);
      setSelectedAgent(employeesData.find((agent) => agent.name === e.target.value));
    };

    const handleAssign = async () => {           
        try {
            await transferLead({ userIdFrom: lead.assignedUsers[0]._id, userIdTo: selectedAgent._id, leadIds: [lead._id] })
                .then(() => {
                    enqueueSnackbar(`${lead.name} transferred to ${selectedAgentDisplayName}`)
                    onClose();
                })
        } catch (error) {
            console.error('Error transferring customer:', error);
        }
    };
    
    return (
        <Dialog open onClose={onClose} fullWidth maxWidth="xs">
            <DialogTitle>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h6">Transfer Customer</Typography>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </div>
                <Divider />
            </DialogTitle>
            <DialogContent>
                <Typography>Transfer to:</Typography>
                {employeesError ? (
                    <Typography sx={{ color: 'red', }}>Error loading employees</Typography>
                ) : employeesLoading ? (
                    <CircularProgress />
                ) : (
                    <Select
                        value={selectedAgentDisplayName}
                        onChange={handleChange}
                    >
                        <MenuItem value="default" sx={{ display: 'none', }}>Select an agent</MenuItem>
                        {sortedAgents.map((employee, index) => (
                            userData.name !== employee.name && (
                                <MenuItem key={index} value={employee.name}>{employee.name}</MenuItem>
                            )
                        ))}
                    </Select>
                )}
            </DialogContent>
            <DialogActions>
                {moveLeadsLoading && (
                    <CircularProgress color="primary"/>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    disabled={selectedAgentDisplayName === 'default'}
                    onClick={handleAssign}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ChangeOwnerForm;