import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import useTheme from '@mui/material/styles/useTheme';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useRegisterMutation } from '../../state/api';

const AddEmployeeForm = ({ onAgentAdd, onClose }) => {
  const theme = useTheme();
  const [formErrors, setFormErrors] = useState([]);
  const [registerMutation, { isLoading: adding }] = useRegisterMutation();
  const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
  const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const passwordRegExp = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/
  const [successMessage, setSuccessMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      phoneNumber: '',
      cellNumber: '',
      role: 'agent',
      country: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Enter a valid email")
        .max(40, "Must be 40 characters or less.")
        .required("Enter a valid email")
        .matches(
          emailRegExp,
          "Email is not valid"),
      password: Yup.string()
        .required("A valid password is required")
        .min(8)
        .matches(
          passwordRegExp,
          "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character"
        ),
      name: Yup.string(),
      phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
      cellNumber: Yup.string().matches(phoneRegExp, 'Cellphone number is not valid'),
      address: Yup.string(),
      city: Yup.string(),
      state: Yup.string(),
      country: Yup.string(),
      occupation: Yup.string(),
      transactions: Yup.array()
    }),
    onSubmit: async (values, helpers) => {
      let errors = [];
      try {
        registerMutation({
          name: values.name,
          email: values.email,
          password: values.password,
          phoneNumber: values.phoneNumber,
          cellNumber: values.cellNumber,
          address: values.address,
          city: values.city,
          state: values.state,
          country: values.country,
          occupation: values.occupation,
          transactions: values.transactions,
        })
          .unwrap()
          .then(() => {
            formik.resetForm();
            onAgentAdd();
            setSuccessMessage('Successfully created agent!');
          })
          .catch((error) => {
            errors.push(error);
            setFormErrors(errors);
          });
      } catch (error) {
        errors.push(error);
        setFormErrors(errors);
      }
    },
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const handleAlertClose = () => {
    setSuccessMessage('');
  };


  return (
    <Dialog open={true} onClose={onAgentAdd}>
      <DialogTitle sx={{ textAlign: 'center' }}>Add Employee</DialogTitle>
      <DialogContent>
        <Container 
        component="main" 
        maxWidth="xl"
        padding="5px"
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: theme.palette.primary[900],
          padding: '40px',
          margin: '0 auto',
          minWidth: '300px',
          width: '100%',
          '@media (max-width: 600px)': {
            width: '100%',
            margin: 0,
            maxWidth: '100%',
            maxHeight: '100%',
            borderRadius: 0,
          },
          '@media (min-width: 600px)': {
            minWidth: '300px',
          },
        }}
        >
          <Box component="form" onSubmit={formik.handleSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Name"
              name="name"
              autoComplete="name"
              autoFocus
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.name && formik.errors.name && (
              <Box sx={{ color: '#ba000d' }}>
                <small>{formik.errors.name}</small>
              </Box>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              label="Email"
              name="email"
              autoComplete="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email && (
              <Box sx={{ color: '#ba000d' }}>
                <small>{formik.errors.email}</small>
              </Box>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              label="Password"
              name="password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.password && formik.errors.password && (
              <Box sx={{ color: '#ba000d' }}>
                <small>{formik.errors.password}</small>
              </Box>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              label="Phone Number"
              name="phoneNumber"
              autoComplete="phoneNumber"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <Box sx={{ color: '#ba000d' }}>
                <small>{formik.errors.phoneNumber}</small>
              </Box>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              label="Cell Number"
              name="cellNumber"
              autoComplete="cellNumber"
              value={formik.values.cellNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.cellNumber && formik.errors.cellNumber && (
              <Box sx={{ color: '#ba000d' }}>
                <small>{formik.errors.cellNumber}</small>
              </Box>
            )}
            <TextField
              margin="normal"
              fullWidth
              label="Address"
              name="address"
              autoComplete="address"
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <TextField
              margin="normal"
              fullWidth
              label="City"
              name="city"
              autoComplete="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <TextField
              margin="normal"
              fullWidth
              label="State"
              name="state"
              autoComplete="state"
              value={formik.values.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <TextField
              select
              margin="normal"
              fullWidth
              label="Country"
              name="country"
              value={formik.values.country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <MenuItem value="US">US</MenuItem>
              <MenuItem value="CAN">CAN</MenuItem>
              <MenuItem value="MEX">MEX</MenuItem>
            </TextField>
            <TextField
              margin="normal"
              fullWidth
              label="Occupation"
              name="occupation"
              autoComplete="occupation"
              value={formik.values.occupation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formErrors.length ? (
              <Box sx={{ color: '#ba000d' }}>
                <small>Registration error:</small>
              </Box>
            ) : null}
            <DialogActions>
              <Button onClick={handleClose} color="error">
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained" disabled={adding}>
                Add
              </Button>
            </DialogActions>
          </Box>
        </Container>
      </DialogContent>
      {successMessage && (
        <Box sx={{ mt: 2 }}>
          <Alert severity="success" onClose={handleAlertClose}>
            {successMessage}
          </Alert>
        </Box>
      )}
    </Dialog>
  );
};

export default AddEmployeeForm;
