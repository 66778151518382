import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import LeadsList from "./LeadsList";
import { useGetLeadsByStatusQuery } from "../../state/salesApi";
import AddLeadForm from "components/forms/AddLeadForm";
import ComposeEmail from "components/email/ComposeEmail";
import Add from "@mui/icons-material/Add";
import Email from "@mui/icons-material/Email";
// import PersonAddAlt1 from "@mui/icons-material/PersonAddAlt1";
import SearchBar from "components/searchbar/SearchBar";
import LeadSearchList from "./LeadsSearchList";

const LeadsTabs = () => {
  const [activeTab, setActiveTab] = useState("new");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const {
    data: { leads: leadsData = [], total = 0 } = {},
    isLoading,
    isError,
    refetch,
  } = useGetLeadsByStatusQuery([activeTab, currentPage, sortField, sortOrder]);
  const [showForm, setShowForm] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const theme = useTheme();
  const [selectedLead, setSelectedLead] = useState(null);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [selectedLeadObjects, setSelectedLeadObjects] = useState([]);
  const [selectedLeadsEmails, setSelectedLeadsEmails] = useState([]);

  // Lead search
  const [searched, setSearched] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchCategory, setSearchCategory] = useState("name");

  const handleLeadCreate = (newLead) => {
    setShowForm(false);
    setSelectedLead(newLead);
  };

  const handleEmailSelected = () => {
    setShowEmailForm(true);
  };

  const handleCloseEmailForm = () => {
    setShowEmailForm(false);
    setSelectedLeads([]);
    setSelectedLeadObjects([]);
    setSelectedLeadsEmails([]);
    refetch();
  };

  const handleTabChange = (e, newTab) => {
    e.preventDefault();
    setActiveTab(newTab);
    setSearchInput("");
    setSearched(false);
    setCurrentPage(1);
    setSelectedLead(null);
    setSortField("name");
    setSortOrder("asc");
  };

  const handleLeadsUpdate = () => {
    refetch();
  };

  const handleSearchChange = () => {
    setSearched(!searched);
  };

  useEffect(() => {
    refetch();
  }, [currentPage, activeTab, refetch]);

  const handleOpenForm = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    refetch();
  };

  const getIndicatorColor = () => {
    return theme.palette.mode === "dark"
      ? theme.palette.primary[100]
      : theme.palette.primary[100];
  };

  const getTextColor = () => {
    return theme.palette.mode === "dark"
      ? theme.palette.text.primary
      : theme.palette.text.primary;
  };

  const getBackgroundColor = () => {
    return theme.palette.mode === "dark"
      ? theme.palette.background.paper
      : theme.palette.background.paper;
  };

  const tabStyles = {
    color: getTextColor(),
    backgroundColor: getBackgroundColor(),
    marginRight: theme.spacing(1),
    borderRadius: "4px",
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.grey[900],
        borderRadius: "4px",
        padding: theme.spacing(1),
      }}
    >
      {showEmailForm && (
        <ComposeEmail
          // arrayOfEmails={selectedLeadsEmails}
          arrayOfEmails={selectedLeadObjects
            .filter((lead) => lead.DNA.status !== true)
            .map((lead) => lead.contactEmail)}
          handleCloseEmailForm={handleCloseEmailForm}
          arrayOfLeadIds={selectedLeads}
          DNAIds={selectedLeadObjects
            .filter((lead) => lead.DNA.status === true)
            .map((lead) => lead._id)}
        />
      )}
      <Box>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="fullWidth"
          scrollButtons="auto"
          style={{ borderRadius: "4px" }}
          TabIndicatorProps={{
            style: { backgroundColor: getIndicatorColor() },
          }}
        >
          <Tab label="New Leads" value="new" style={tabStyles} />
          <Tab label="Warm Leads" value="warm" style={tabStyles} />
          <Tab label="Hot Leads" value="hot" style={tabStyles} />
          <Tab
            label="Assigned Customers"
            value="assignedCustomer"
            style={tabStyles}
          />
          <Tab
            label="Pending Customers"
            value="pendingCustomer"
            style={tabStyles}
          />
          <Tab
            label="Active Customers"
            value="activeCustomer"
            style={tabStyles}
          />
        </Tabs>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ marginTop: 1 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenForm}
            style={tabStyles}
            sx={{ pl: "5px", pr: "10px" }}
          >
            <Add sx={{ mr: "5px", pb: "2px" }} />
            Add Lead
          </Button>
          {/* <Button
            variant="contained"
            color="primary"
            style={tabStyles}
            sx={{ pl: "5px", pr: "10px" }}
          >
            <PersonAddAlt1 sx={{ mr: "5px", pb: "2px" }} />
            Request Leads
          </Button> */}
          <Button
            disabled={selectedLeads.length === 0}
            variant="contained"
            color="primary"
            onClick={handleEmailSelected}
            sx={{ marginLeft: 1 }}
          >
            <Email style={{ marginRight: "5px" }} />
            Email Selected
          </Button>
        </Box>
        <Box sx={{ marginTop: 1, marginRight: "40px", display: "flex" }}>
          <Select
            value={searchCategory}
            size="small"
            onChange={(e) => setSearchCategory(e.target.value)}
            sx={{
              marginRight: "5px",
              backgroundColor:
                theme.palette.mode !== "dark"
                  ? "white"
                  : theme.palette.grey[900],
            }}
          >
            <MenuItem value="name">Name</MenuItem>
            <MenuItem value="contactEmail">Email</MenuItem>
            <MenuItem value="commodity">Commodity</MenuItem>
          </Select>
          <SearchBar
            data={leadsData}
            placeholder={"Search all leads"}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            handleSearchChange={handleSearchChange}
            allLeadsSearch={true}
            hasSearched={searched}
          />
        </Box>
      </Box>
      {showForm && (
        <AddLeadForm
          mode="create"
          leadToUpdate={null}
          open={showForm}
          closeForm={handleCloseForm}
          onCreate={handleLeadCreate}
        />
      )}
      <Paper elevation={4} sx={{ p: 2, mt: 2 }}>
        {isLoading ? (
          <CircularProgress />
        ) : isError ? (
          <Typography>Error fetching leads.</Typography>
        ) : !searched ? (
          <LeadsList
            leads={leadsData}
            isLoading={isLoading}
            handleLeadsUpdate={handleLeadsUpdate}
            totalLeads={total}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            refetch={refetch}
            selectedLead={selectedLead}
            setSelectedLead={setSelectedLead}
            selectedLeads={selectedLeads}
            selectedLeadObjects={selectedLeadObjects}
            setSelectedLeadObjects={setSelectedLeadObjects}
            setSelectedLeads={setSelectedLeads}
            setSelectedLeadsEmails={setSelectedLeadsEmails}
            sortCategory={sortField}
            setSortCategory={setSortField}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            theme={theme}
          />
        ) : (
          <LeadSearchList
            searchInput={searchInput}
            searchCategory={searchCategory}
            handleLeadsUpdate={handleLeadsUpdate}
            theme={theme}
          />
        )}
      </Paper>
    </Box>
  );
};

export default LeadsTabs;
