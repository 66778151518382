import AddOutlined from '@mui/icons-material/AddOutlined';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Business from '@mui/icons-material/Business';
import Close from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';
import Email from '@mui/icons-material/Email';
import Refresh from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import ComposeEmail from 'components/email/ComposeEmail';
import AddActivityForm from 'components/forms/AddActivityForm';
import AddCalendarEventForm from 'components/forms/AddCalendarEventForm';
import AddLeadForm from 'components/forms/AddLeadForm';
import { calculateDSLA } from 'components/helpers/CalculateDSLA';
import ConfirmationDialog from 'components/helpers/ConfirmDialog';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDeleteLeadMutation, useGetEmployeesListQuery, useGetLeadByIdQuery, useTransferLeadsMutation, useUpdateLeadMutation } from 'state/salesApi';
import SalesList from '../sales/SalesList';
import LeadActions from './LeadActions';
import LeadInfo from './LeadInfo';
import { RequestQuote } from '@mui/icons-material';
import QuoteSubmissionForm from 'components/forms/QuoteSubmissionForm';

const LeadDetails = ({ lead, onClose, handleUpdateLeads, refetchLeads }) => {
  const user = useSelector((state) => state.user);
  const theme = useTheme();
  const [updateFormOpen, setUpdateFormOpen] = useState(false);
  const [isAddActivityOpen, setIsAddActivityOpen] = useState(false);
  const [isEmailLeadOpen, setIsEmailLeadOpen] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [isAddEventOpen, setIsAddEventOpen] = useState(false);
  const [quoteLeadOpen, setQuoteLeadOpen] = useState(false);
  const [sendToOpen, setSendToOpen] = useState(false);
  const [showActivityList, setShowActivityList] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [isSalesListOpen, setIsSalesListOpen] = useState(false);
  const { data: leadData, /* isError, */ isLoading, refetch } = useGetLeadByIdQuery(lead._id);
  const { 
    data: employeesData = [], 
    // employeesError, 
    // agentsLoading, 
    // refetchEmployees 
  } = useGetEmployeesListQuery();
  const [transferLead] = useTransferLeadsMutation();
  const [selectedAgent, setSelectedAgent] = useState('default');
  const [transferError, setTransferError] = useState(false);
  const [transferErrorMessage, setTransferErrorMessage] = useState('');
  const [updateLead] = useUpdateLeadMutation();
  const [deleteLead] = useDeleteLeadMutation();

  const { enqueueSnackbar } = useSnackbar();

  const sortedAgents = [...employeesData].sort((a, b) => {
    const valueA = a.email || "";
    const valueB = b.email || "";
    return valueA.localeCompare(valueB);
  });

  const handleMoveToPool = (updatedLead) => {
    alert("Under Development")
  };

  const handleDelete = async () => {
    try {
      await deleteLead(leadData._id);
      handleDeleteToggle();
      handleUpdateLeads();
      onClose();
      refetchLeads();
      enqueueSnackbar(`${lead.name} deleted`);
    } catch (error) {
      console.error('Error deleting lead:', error);
    }
  };

  const toggleSalesList = () => {
    setIsSalesListOpen(!isSalesListOpen);
  };

  const handleEditLeadToggle = () => {
    setUpdateFormOpen(prev => !prev);
  };

  const handleQuoteLeadOpen = () => {
    setQuoteLeadOpen(true);
  };

  const handleQuoteLeadClose = () => {
    setQuoteLeadOpen(false);
  };

  const handleAddEventOpen = () => {
    setIsAddEventOpen(true);
  };

  const handleAddEventClose = () => {
    setIsAddEventOpen(false);
  };

  const handleAddActivity = () => {
    setIsAddActivityOpen(true);
  };

  const handleAddActivityClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setIsAddActivityOpen(false);
    }
  };

  const handleEmailLead = () => {
    setIsEmailLeadOpen(true);
  };

  const handleEmailLeadClose = () => {
    setIsEmailLeadOpen(false);
    refetch();
  };

  const handleToggleActivityList = () => {
    setShowActivityList(prev => !prev);
  };

  const handleSendToDisplay = () => {
    if (sendToOpen) {
      setTransferError(false);
      setTransferErrorMessage('');
    }
    setSendToOpen(prev => !prev);
  };

  const handleDeleteToggle = () => {
    setConfirmDeleteOpen(prev => !prev);
  };

  const handleAgentChange = async () => {
    try {
      await transferLead({
        targetEmployeeEmail: selectedAgent,
        leadIds: [lead._id]
      })
        .then((res) => {
          if (res.error) {
            setTransferError(true);
            setTransferErrorMessage(res.error.data.message);
          } else {
            handleUpdateLeads();
            enqueueSnackbar(`${leadData.name} sent to ${selectedAgent}`);
            setSendToOpen(false);
            onClose();
          }
        })
    } catch (error) {
      console.error("Error moving lead:", error);
      setTransferError(true);
      setTransferErrorMessage(error.data.message);
    }
  };

  const handleDNA = async () => {
    const updatedValues = {
      notes: leadData.DNA.notes,
      status: !leadData.DNA.status
    }

    await updateLead({ id: leadData._id, leadUpdates: { DNA: updatedValues } })
      .then(() => {
        handleRefresh();
      })
  };

  const handleCallTomorrow = async () => {
    const updatedValues = {
      callTomorrow: !leadData.callTomorrow
    };

    await updateLead({ id: leadData._id, leadUpdates: updatedValues })
      .then(() => {
        handleRefresh();
      })
  };

  const handleBookmark = async () => {
    const updatedValues = {
      bookmarked: !leadData.bookmarked
    };

    await updateLead({ id: leadData._id, leadUpdates: updatedValues })
      .then(() => {
        handleRefresh();
      })
  };

  const handleDSLA = (DSLA) => {
    return calculateDSLA(DSLA);
  };

  const handleRefresh = () => {
    refetch();
  };

  if (isLoading) return <CircularProgress />

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Button onClick={onClose}>
          <ArrowBack sx={{ marginRight: '5px', color: theme.palette.mode === 'dark' ? 'white' : 'black' }} />
          <Typography sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }}>Back</Typography>
        </Button>
      </Box>
      <Paper sx={{ backgroundColor: theme.palette.background.paper, padding: '10px' }}>
        <Box sx={{ backgroundColor: theme.palette.background.secondary, borderRadius: '8px', marginBottom: '20px' }}>
          <Box
            sx={{
              backgroundColor: theme.palette.background.paper,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderTopLeftRadius: '8px',
              borderTopRightRadius: '8px'
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{
                backgroundColor: theme.palette.background.alt,
                margin: '15px 10px',
                height: '2.9rem',
                width: '2.9rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '10px'
              }}>
                <Business sx={{ fontSize: '35px' }} />
              </Box>
              <Box>
                <Typography sx={{ fontFamily: theme.typography.h3.fontFamily, fontSize: theme.typography.h3.fontSize }}>{leadData.name || "No name provided"}</Typography>
                {leadData.DNA.status && <Typography>DO NOT ANNOY</Typography>}
              </Box>
            </div>
            <div>
              <Box>
                {leadData?.contactEmail ? (
                  <Button
                    onClick={handleEmailLead}
                    color="primary"
                    variant="contained"
                    sx={{ textTransform: 'none', borderRadius: '2', marginRight: '5px' }}
                  >
                    <Email style={{ marginRight: "10px" }} />
                    Email
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    disabled
                    sx={{ textTransform: 'none', borderRadius: '2', marginRight: '5px' }}
                  >
                    No email found
                  </Button>
                )}
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                    borderTopRightRadius: '0px',
                    borderBottomRightRadius: '0px',
                    boxShadow: 'none',
                    border: '1px solid black',
                    borderRight: '.05px solid black',
                    paddingLeft: '8px'
                  }}
                  onClick={handleEditLeadToggle}
                >
                  <Edit sx={{ marginRight: '5px', fontSize: '15px' }} />
                  Edit
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                    borderRadius: '0px',
                    boxShadow: 'none',
                    border: '1px solid black',
                    borderLeft: '0px solid black',
                    borderRight: '.05px solid black',
                    paddingLeft: '8px',
                  }}
                  onClick={handleAddActivity}
                >
                  <AddOutlined sx={{ marginRight: '5px' }} />
                  Activity
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                    borderRadius: '0px',
                    boxShadow: 'none',
                    border: '1px solid black',
                    borderLeft: '0px solid black',
                    borderRight: '.05px solid black',
                    paddingLeft: '8px'
                  }}
                  onClick={handleAddEventOpen}
                >
                  <AddOutlined sx={{ marginRight: '5px' }} />
                  Event
                </Button>
                <Button
                  color='primary'
                  variant='contained'
                  sx={{
                    textTransform: 'none',
                    borderRadius: '0px',
                    border: '1px solid black',
                    borderLeft: '0px solid black',
                    borderRight: '.05px solid black',
                    paddingLeft: '8px'
                  }}
                  onClick={handleQuoteLeadOpen}
                >
                  <RequestQuote sx={{ marginRight: '5px'}}/>
                  Quote Lead
                </Button>
                {/* <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                    borderRadius: '0px',
                    boxShadow: 'none',
                    border: '1px solid black',
                    borderLeft: '0px solid black',
                    borderRight: '.05px solid black'
                  }}
                >
                  Change Status
                </Button> */}
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                    borderRadius: '0px',
                    boxShadow: 'none',
                    border: '1px solid black',
                    borderLeft: '0px solid black',
                    borderRight: '.05px solid black'
                  }}
                  onClick={handleSendToDisplay}
                >
                  Change Owner
                </Button>
                <Button
                  color="error"
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                    borderTopLeftRadius: '0px',
                    borderBottomLeftRadius: '0px',
                    boxShadow: 'none',
                    border: '1px solid black',
                    borderLeft: '0px solid black',
                    borderRight: '.05px solid black',
                    marginRight: '10px'
                  }}
                  onClick={handleDeleteToggle}
                >
                  Delete
                </Button>
              </Box>
            </div>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', flexDirection: 'row', marginTop: '10px' }}>
            <Box sx={{ display: 'flex', marginBottom: '10px' }}>
              <Box sx={{ flexBasis: '25%', wordWrap: 'break-word' }}>
                <Typography sx={{ fontFamily: theme.typography.h6.fontFamily, fontSize: theme.typography.h7.fontSize, marginLeft: '5px', marginRight: '70px' }}>
                  State
                </Typography>
                <Divider sx={{ width: '50px' }} />
                <Typography sx={{ fontFamily: theme.typography.h6.fontFamily, fontSize: theme.typography.h6.fontSize, marginLeft: '5px' }}>
                  {leadData.state || ""}
                </Typography>
              </Box>
              <Box sx={{ flexBasis: '25%', wordWrap: 'break-word' }}>
                <Typography sx={{ fontFamily: theme.typography.h6.fontFamily, fontSize: theme.typography.h7.fontSize, marginRight: '70px' }}>
                  Commodity
                </Typography>
                <Divider sx={{ width: '50px' }} />
                <Typography sx={{ fontFamily: theme.typography.h6.fontFamily, fontSize: theme.typography.h6.fontSize }}>
                  {leadData.commodity || ""}
                </Typography>
              </Box>
              <Box sx={{ flexBasis: '25%', wordWrap: 'break-word' }}>
                <Typography sx={{ fontFamily: theme.typography.h6.fontFamily, fontSize: theme.typography.h7.fontSize, marginRight: '70px' }}>
                  Status
                </Typography>
                <Divider sx={{ width: '50px' }} />
                <Typography sx={{ fontFamily: theme.typography.h6.fontFamily, fontSize: theme.typography.h6.fontSize }}>
                  {leadData.status.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase() })}
                </Typography>
              </Box>
              <Box sx={{ flexBasis: '25%', wordWrap: 'break-word' }}>
                <Typography sx={{ fontFamily: theme.typography.h6.fontFamily, fontSize: theme.typography.h7.fontSize, marginRight: '70px' }}>
                  DSLA
                </Typography>
                <Divider sx={{ width: '50px' }} />
                <Typography sx={{ fontFamily: theme.typography.h6.fontFamily, fontSize: theme.typography.h6.fontSize }}>
                  {handleDSLA(leadData.recentActivity?.date) === 0 ? "Today" : handleDSLA(leadData.recentActivity?.date) > 0 ? handleDSLA(leadData.recentActivity?.date) : "Not contacted"}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', }}>
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={leadData.bookmarked} onChange={handleBookmark} />} label="Bookmarked" />
              </FormGroup>
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={leadData.callTomorrow} onChange={handleCallTomorrow} />} label="Call Tomorrow" />
              </FormGroup>
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={leadData.DNA.status} onChange={handleDNA} />} label="Do Not Annoy" />
              </FormGroup>
            </Box>
          </Box>
        </Box>
        <Grid style={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton onClick={handleRefresh} color="info" size="medium" sx={{ ml: 1 }}>
            <Refresh />
          </IconButton>
          <Grid container spacing={1} justifyContent="right">
            <Button
              variant="contained"
              color="primary"
              style={{ alignSelf: "flex-end" }}
              onClick={handleToggleActivityList}
            >
              {showActivityList ? "Hide All Activity" : "Show All Activity"}
            </Button>
          </Grid>
        </Grid>
        <LeadInfo lead={leadData} showActivityList={showActivityList} refetch={refetch} />
        <LeadActions lead={leadData} onMoveToPool={handleMoveToPool} onDelete={handleDelete} refetch={refetch} />
        <Button onClick={toggleSalesList} color="primary" variant="contained" sx={{ marginTop: 1, marginBottom: 1 }}>
          {isSalesListOpen ? 'Close Sales List' : 'Open Sales List'}
        </Button>
        {isSalesListOpen && <SalesList leadId={leadData._id} />}
        {isAddEventOpen && <AddCalendarEventForm handleCloseEventForm={handleAddEventClose} leadName={leadData.name} />}
        {isAddActivityOpen && (
          <AddActivityForm leadId={leadData._id} onClose={handleAddActivityClose} refetch={refetch} />
        )}
        {quoteLeadOpen && (
          <QuoteSubmissionForm open={quoteLeadOpen} closeForm={handleQuoteLeadClose} fromLead lead={lead}/>
        )}
        {isEmailLeadOpen && (
          <ComposeEmail
            handleCloseEmailForm={handleEmailLeadClose}
            arrayOfEmails={[leadData.contactEmail]}
            arrayOfLeadIds={[leadData._id]}
            setEmailSent={setEmailSent}
          />
        )}
        {sendToOpen && (
          <Dialog open onClose={handleSendToDisplay} fullWidth maxWidth="xs">
            <DialogTitle>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6">Transfer Lead</Typography>
                <IconButton onClick={handleSendToDisplay}>
                  <Close />
                </IconButton>
              </div>
              <Divider />
            </DialogTitle>
            <DialogContent>
              <Typography>Move to:</Typography>
              <Select
                value={selectedAgent}
                onChange={(e) => setSelectedAgent(e.target.value)}
              >
                <MenuItem value='default' sx={{ display: 'none' }}>Select an agent</MenuItem>
                {sortedAgents.map((employee, index) => (
                  user.email !== employee.email && (
                    <MenuItem key={index} value={employee.email}>{employee.email}</MenuItem>
                  )
                ))}
              </Select>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                disabled={selectedAgent === 'default'}
                onClick={handleAgentChange}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Paper>
      {updateFormOpen && (
        <AddLeadForm
          mode="update"
          leadToUpdate={leadData}
          open={updateFormOpen}
          closeForm={handleEditLeadToggle}
          updateRefetch={refetch}
        />
      )}
      {confirmDeleteOpen && (
        <ConfirmationDialog
          open={confirmDeleteOpen}
          onClose={handleDeleteToggle}
          onConfirm={handleDelete}
          title="Confirm Delete"
          message={`Are you sure you want to delete ${leadData.name}?`}
        />
      )}
    </>
  );
};

export default LeadDetails;










