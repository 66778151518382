import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAddGoogleCalendarEventMutation } from "state/api";
import AccessTime from "@mui/icons-material/AccessTime";
import Description from "@mui/icons-material/Description";
import LocationOn from "@mui/icons-material/LocationOn";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { useSnackbar } from "notistack";

const AddCalendarEventForm = ({ handleCloseEventForm, leadName = null, googleCalendarRefetch }) => {
    const [addEvent] = useAddGoogleCalendarEventMutation();
    const [eventResponseMessage, setEventResponseMessage] = useState([]);

    const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
    const [newInvitedUser, setNewInvitedUser] = useState("");
    const [addUserErrors, setAddUserErrors] = useState([]);
    const [formErrors, setFormErrors] = useState([]);

    const { enqueueSnackbar } = useSnackbar();

    // Validation schema for manually adding emails
    const addEmailSchema = Yup.object().shape({
        email: Yup.string()
        .matches(
            /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
            'Invalid email'
          )
          .required("Email is required"),
    });

    const handleInviteUser = async () => {
        try {
            await addEmailSchema.validate({
                email: newInvitedUser,
            });

            formik.setFieldValue("invitedUsers", [
                ...formik.values.invitedUsers,
                newInvitedUser,
            ]);
            
            setNewInvitedUser("");
            setAddUserErrors([]);
            setOpenAddUserDialog(false);

        } catch (err) {
            setAddUserErrors(err);
        }
    };

    const formik = useFormik({
        initialValues: {
            title: "",
            startDateTime: null,
            endDateTime: null,
            description: "",
            location: "",
            invitedUsers: "",
            type: "default"
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Event title is required"),
            startDateTime: Yup.date().required("Event date cannot be empty"),
            endDateTime: Yup.date().nullable(), // TODO: Make sure it can't be before start time
            description: Yup.string(),
            location: Yup.string(),
            invitedUsers: Yup.array().of(Yup.string()).nullable(),
            type: Yup.string()
        }),

        onSubmit: async (values, helpers) => {
            let errors = [];
            let eventToSend = {
                title: values.title,
                startDateTime: null,
                endDateTime: null,
                description: values.description,
                location: values.location,
                invitedUsers: values.invitedUsers,
                type: values.type,
            }

            // Handle if event is for a lead
            if (leadName) {
                eventToSend.title = values.title + " - " + leadName;
            };
            
            // Handle start and end datetimes
            if (values.startDateTime) {
                const startDateTime = new Date(values.startDateTime);
    
                if (!isNaN(startDateTime)) {
                    eventToSend.startDateTime = startDateTime.toISOString();
                }
            };

            if (values.endDateTime) {
                const endDateTime = new Date(values.endDateTime);
    
                if (!isNaN(endDateTime)) {
                    eventToSend.endDateTime = endDateTime.toISOString();
                }
            } else {
                const endDateTime = new Date(values.startDateTime);
                endDateTime.setHours(endDateTime.getHours() + 1);

                if (!isNaN(endDateTime)) {
                    eventToSend.endDateTime = endDateTime.toISOString();
                }
            };

            if (values.type === "Task" || values.type === "Appointment") {
                eventToSend.description = values.type + " - " + values.description;
            };
            
            try {
                await addEvent(eventToSend);
                setEventResponseMessage([
                    { type: "success", message: "Email sent successfully!" },
                ]);

                enqueueSnackbar("Event created");

                formik.resetForm();
                setFormErrors([]);
                !leadName && googleCalendarRefetch();
                handleCloseEventForm();
            } catch (error) {
                console.error("Event creation error:", error);
                errors.push(error);
                setFormErrors(errors);
                setEventResponseMessage([
                    {
                        type: "error",
                        message: error.message || "An error has occurred during event creation."
                    }
                ])
            }
        }
    });

    const handleAddUserClick = () => {
        setOpenAddUserDialog(true);
    };

    const handleAddUserDialogClose = () => {
        setOpenAddUserDialog(false);
    };

    return (
        <div>
            <Dialog open onClose={handleCloseEventForm} fullWidth maxWidth="sm">
                <DialogTitle sx={{ textAlign: 'center' }}>Create Event {leadName && `for ${leadName}`}</DialogTitle>
                <DialogContent>
                    <div>
                        <TextField 
                            label="Add title"
                            variant="outlined"
                            name="title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            style={{ marginLeft: "38px", width: "92.5%" }}
                        />
                        {formik.touched.title && formik.errors.title && (
                            <Alert severity="error">{formik.errors.title}</Alert>
                        )}
                        <Select
                            name="type"
                            value={formik.values.type}
                            style={{ marginLeft: "38px", width: "92.5%", marginTop: "15px" }}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        >
                            <MenuItem value="default" style={{ display: "none" }}>Select type</MenuItem>
                            <MenuItem value="Task">Task</MenuItem>
                            <MenuItem value="Appointment">Appointment</MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                        </Select>
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <AccessTime />
                            <TextField 
                                variant="outlined"
                                fullWidth
                                name="startDateTime"
                                value={formik.values.startDateTime || ""}
                                type="datetime-local"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                margin="normal"
                                style={{ marginLeft: "20px" }}
                            />
                        </Box>
                        {formik.touched.startDateTime && formik.errors.startDateTime && (
                            <Alert severity="error">{formik.errors.startDateTime}</Alert>
                        )}
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <LocationOn />
                            <TextField 
                                label="Add location"
                                variant="outlined"
                                name="location"
                                fullWidth
                                value={formik.values.location}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                margin="normal"
                                style={{ marginLeft: "20px" }}
                            />
                        </Box>
                        {formik.touched.location && formik.errors.location && (
                            <Alert severity="error">{formik.errors.location}</Alert>
                        )}
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Description />
                            <TextField 
                                label="Add description"
                                variant="outlined"
                                name="description"
                                fullWidth
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                margin="normal"
                                style={{ marginLeft: "20px"}}
                            />
                        </Box>
                        {formik.touched.description && formik.errors.description && (
                            <Alert severity="error">{formik.errors.description}</Alert>
                        )}
                        <TextField 
                                label="Invited users"
                                variant="outlined"
                                fullWidth
                                name="invitedUsers"
                                value={formik.values.invitedUsers}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                margin="normal"
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                            {formik.touched.invitedUsers && formik.errors.invitedUsers && (
                                <Alert severity="error">{formik.errors.description}</Alert>
                            )}
                    </div>
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "10px",
                        }}
                    >
                        <Button 
                            variant="contained"
                            color="primary"
                            onClick={handleAddUserClick}
                            style={{ marginTop: "10px" }}
                        >
                            <AddIcon />
                            Invite email
                        </Button>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <form onSubmit={formik.handleSubmit}>
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "10px",
                            }}
                        >
                            <Button 
                                type="submit" 
                                variant="contained" 
                                color="primary"
                                style={{ marginRight: "15px" }}
                            >
                                Create event
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={handleCloseEventForm}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </form>
                </DialogActions>
                <div style={{ position: "relative" }}>
                    {eventResponseMessage.length > 0 &&
                        eventResponseMessage.map((message, index) =>
                            message.type === "error" ? (
                                <Alert key={index} severity={message.type}>
                                    {message.message}
                                </Alert>
                            ): null
                        )
                    }
                </div>
            </Dialog>
            <Dialog open={openAddUserDialog}>
                <DialogContent style={{ position: "relative" }}>
                    <Box p={2}>
                        <CloseIcon 
                            style={{
                                position: "absolute",
                                right: "5px",
                                top: "5px",
                                cursor: "pointer",
                            }}
                            onClick={handleAddUserDialogClose}
                        />
                        {/* TODO: Error handle for bad email invited */}
                            <TextField 
                                value={newInvitedUser}
                                onChange={(e) => setNewInvitedUser(e.target.value)}
                                style={{ marginTop: "8px", marginRight: "8px" }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleInviteUser}
                            >
                                Add Email
                            </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default AddCalendarEventForm;