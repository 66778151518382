import React, { useRef, useState } from 'react';
import { useUploadSignatureForUserMutation, useDeleteSignatureFromUserMutation, useGetSignaturesForUserQuery } from '../../state/managementApi';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { Slider } from '@mui/material';

const AgentSignatureForm = ({ agentId, onClose }) => {
  const [newSignatureFormOpen, setNewSignatureFormOpen] = useState(false);
  const [signatureType, setSignatureType] = useState(0);
  const [signatureName, setSignatureName] = useState("");
  const [textPng, setTextPng] = useState(null);
  const [textPngfile, setTextPngfile] = useState(null);
  const [signatureFile, setSignatureFile] = useState(null);
  const [uploadError, setUploadError] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [signature, setSignature] = useState(""); // For text field signature
  const canvasRef = useRef(null);
  const {
    data: signatures,
    isLoading,
    refetch
  } = useGetSignaturesForUserQuery(agentId);
  const [uploadSignatureForUser, { isLoading: isUploadingSignature }] = useUploadSignatureForUserMutation();
  const [deleteSignatureFromUser, { isLoading: isDeletingSignature }] = useDeleteSignatureFromUserMutation();

  const { enqueueSnackbar } = useSnackbar();

  // Handlers
  const handleConvertToPng = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    // Set font and calculate the width and height required for the text
    const fontSize = 30;
    const lineHeight = fontSize * 1.2; // Line height for spacing between lines
    context.font = `${fontSize}px Arial`;
    const lines = signature.split("\n");

    const canvasWidth = Math.max(...lines.map(line => context.measureText(line).width)) + 40; // Add padding
    const canvasHeight = lines.length * lineHeight + 40; // Add padding for height

    // Set canvas dimensions based on the text size
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    // Fill canvas with white background
    context.fillStyle = "#fff";
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Set text color and style
    context.fillStyle = "#000"; // Black text
    context.font = `${fontSize}px Arial`;

    // Draw each line of text on the canvas
    lines.forEach((line, index) => {
      context.fillText(line, 20, 40 + index * lineHeight); // Draw each line with padding
    });

    // Convert canvas to PNG
    const dataUrl = canvas.toDataURL();

    // Save the PNG data in the state for preview
    setTextPng(dataUrl);

    // Convert to Blob and File
    const blob = handleDataURLToBlob(dataUrl);
    const file = new File([blob], `${signatureName}.png` || "signature.png", { type: "image/png" });

    setTextPngfile(file);
  };

  // Helper function to convert base64 data URL to Blob
  const handleDataURLToBlob = (dataUrl) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const handleFileChange = (event) => {
    setSignatureFile(event.target.files[0]);
  };

  const handleNewSignatureClose = () => {
    setNewSignatureFormOpen(false);
    setSignatureType(0);
    setSignatureName("");
    setTextPng(null);
    setTextPngfile(null);
    setSignatureFile(null);
    setSignature("");
  };

  const handleUploadSignature = async () => {
    if (!signatureFile && !textPngfile) {
      setUploadError(true);
      setErrorMessage('Nothing to upload');
      return;
    }

    if (signatureName === "") {
      setUploadError(true);
      setErrorMessage("Signature must have a name");
    }

    const formData = new FormData();
    formData.append("signatureName", signatureName);

    if (signatureType === 0) {
      formData.append("image", signatureFile);
    } else {
      formData.append("image", textPngfile);
    }

    try {
      await uploadSignatureForUser({ userId: agentId, formData }).unwrap()
        .then(() => {
          handleNewSignatureClose();
          refetch();
          enqueueSnackbar(`${signatureName} uploaded`);
        });
      setUploadError(false);
      setErrorMessage("");
    } catch (error) {
      setErrorMessage(error.data.error.message);
      setUploadError(true);
      console.error('Error:', error);
    }
  };

  const handleDeleteSignature = (signatureName) => {
    deleteSignatureFromUser({ userId: agentId, signatureName }).unwrap()
      .then(() => {
        refetch();
        enqueueSnackbar("Signature deleted");
      })
      .catch(error => {
        console.error('Error deleting signature:', error);
        setErrorMessage('Error deleting signature.');
      });
  };

  if (isLoading) return <CircularProgress />;

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogContent>
        {isUploadingSignature || isDeletingSignature ? (
          <CircularProgress />
        ) : (
          <>
            <Stack spacing={2}>
              {signatures.map((signature, index) => (
                <MenuItem key={index} style={{ display: "flex", justifyContent: "space-around" }}>
                  <img src={signature.data} alt={signature.name} style={{ width: "40%" }} />
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleDeleteSignature(signature.name)}
                  >
                    Delete
                  </Button>
                </MenuItem>
              ))}
            </Stack>
            <input
              type="file"
              id="signatureInput"
              style={{ display: "none" }}
            />
            {newSignatureFormOpen && (
              <Box sx={{ marginTop: "60px" }}>
                <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                  <Box sx={{ mb: '40px', width: '20%' }}>
                    <Typography>Signature Type</Typography>
                    <Slider
                      shiftStep={1}
                      step={1}
                      min={0}
                      max={1}
                      value={signatureType}
                      onChange={(e) => setSignatureType(e.target.value)}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography
                        variant='body2'
                        onClick={() => setSignatureType(0)}
                        sx={{ cursor: 'pointer' }}
                      >
                        File
                      </Typography>
                      <Typography
                        variant='body2'
                        onClick={() => setSignatureType(1)}
                        sx={{ cursor: 'pointer' }}
                      >
                        Text
                      </Typography>
                    </Box>
                  </Box>
                  <InputLabel htmlFor="signatureName">Signature Name:</InputLabel>
                  <Input
                    name="signatureName"
                    type="text"
                    value={signatureName}
                    style={{ marginBottom: 20 }}
                    onChange={(e) => setSignatureName(e.target.value)}
                  />
                  {signatureType ? (
                    <>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Box sx={{ width: '50%' }}>
                          <InputLabel htmlFor="signatureText">Signature Text:</InputLabel>
                          <TextField
                            type='text'
                            value={signature}
                            onChange={(e) => setSignature(e.target.value)}
                            placeholder='Enter signature text'
                            multiline
                            minRows={4}
                            style={{ marginBottom: 10, width: '100%' }}
                          />
                        </Box>
                        <Box sx={{ ml: 5 }}>
                          <h4>Preview:</h4>
                          {textPng && (
                            <div>
                              <img src={textPng} alt='Text as PNG' style={{ maxWidth: '100%' }} />
                            </div>
                          )}
                        </Box>
                        <canvas ref={canvasRef} style={{ display: 'none' }} />
                      </Box>
                    </>
                  ) : (
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <TextField
                        type="file"
                        onChange={handleFileChange}
                        inputProps={{ accept: "image/png" }}
                        fullWidth
                      />
                    </Box>
                  )}
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                  {signatureType === 1 && (
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={handleConvertToPng}
                    >
                      Convert and preview
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUploadSignature}
                  >
                    Upload
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ ml: 1 }}
                    onClick={handleNewSignatureClose}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            )}
            <DialogActions sx={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="contained"
                color="primary"
                disabled={newSignatureFormOpen}
                onClick={() => setNewSignatureFormOpen(!newSignatureFormOpen)}
              >
                Add Signature
              </Button>
              <Button variant="outlined" color="error" onClick={onClose}>
                Close
              </Button>
            </DialogActions>
          </>
        )}
        {uploadError && (
          <Alert severity="error">
            {errorMessage}
          </Alert>
        )}
        {deleteError && (
          <Alert severity="error">
            Error deleting signature
          </Alert>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AgentSignatureForm;
