import AttachFile from "@mui/icons-material/AttachFile";
import Delete from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useSendEmailMutation } from "state/api";
import * as Yup from "yup";

const BugReportForm = ({ open, handleClose }) => {
    const user = useSelector((state) => state.user);
    const [attachments, setAttachments] = useState([]);
    const [sendEmail] = useSendEmailMutation();
    const [emailResponseMessage, setEmailResponseMessage] = useState([]);
    const [formErrors, setFormErrors] = useState([]);

    const { enqueueSnackbar } = useSnackbar();

    const formik = useFormik({
        initialValues: {
            userId: user._id,
            to: "helpdesk@shipktb.com",
            emailSubject: "Bug Report",
            emailText: "",
        },
        validationSchema: Yup.object({
            emailText: Yup.string().required("Bug description required"),
        }),

        onSubmit: async (values, helpers) => {
            let errors = [];
            const formData = new FormData();
            formData.append("userId", values.userId);
            formData.append("to", values.to);
            formData.append("subject", values.emailSubject);
            formData.append("text", values.emailText);

            for (const attachment of attachments) {
                formData.append("attachments", attachment);
            };

            try {
                await sendEmail(formData).unwrap();
                setEmailResponseMessage([
                    { type: "success", message: "Bug report sent successfully!" },
                ]);
                formik.resetForm();
                setFormErrors([]);
                setAttachments([]);
                handleClose();
                enqueueSnackbar("Bug reported");
            } catch (error) {
                console.error("Error reporting bug:", error);
                errors.push(error);
                setFormErrors(errors);
                setEmailResponseMessage([
                    {
                        type: "error",
                        message: error.message || "An error occurred reporting."
                    }
                ])
            }
        }
    })

    const handleAttachmentChange = (e) => {
        const files = e.target.files;
        setAttachments([...attachments, ...files]);
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle sx={{ fontSize: '20px', textAlign: 'center' }}><b>Report a Bug</b></DialogTitle>
            <Divider />
            <DialogContent>
                <Typography sx={{ padding: '5px' }}><b>Details</b></Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={6}
                    name="emailText"
                    value={formik.values.emailText}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sx={{ padding: '15px' }}
                />
                {formik.touched.emailText && formik.errors.emailText && (
                    <Alert severity="error" sx={{ marginBottom: '15px' }}>{formik.errors.emailText}</Alert>
                )}
                <input
                    type="file"
                    id="attachmentInput"
                    multiple
                    style={{ display: 'none' }}
                    onChange={handleAttachmentChange}
                />
                {attachments.length > 0 && (
                    <List>
                        {attachments.map((attachment) => (
                            <ListItem key={attachment.name}>
                                <Delete
                                    variant="contained"
                                    onClick={() =>
                                        setAttachments(attachments.filter((a) => a !== attachment))
                                    }
                                />
                                <ListItemIcon>
                                    <AttachFile />
                                </ListItemIcon>
                                <ListItemText
                                    primary={attachment.name}
                                    secondary={`${attachment.size} bytes`}
                                />
                            </ListItem>
                        ))}
                    </List>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        document.getElementById("attachmentInput").click();
                    }}
                >
                    Add a Screenshot (Recommended)
                </Button>
            </DialogContent>
            <DialogActions>
                <form onSubmit={formik.handleSubmit}>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '10px'
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ marginLeft: '15px' }}
                        >
                            Submit
                        </Button>
                    </Box>
                </form>
            </DialogActions>
        </Dialog>
    )
}

export default BugReportForm;