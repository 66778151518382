
//Get current date for timestamping. This is the date format of recentActivity.date
export const getCurrentDateTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = `${now.getMonth() + 1}`.padStart(2, '0');
  const day = `${now.getDate()}`.padStart(2, '0');
  const hours = `${now.getHours()}`.padStart(2, '0');
  const minutes = `${now.getMinutes()}`.padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

//gets DSLA from lead.recentActivity
export const calculateDSLA = (recentActivityDate) => {
  if (recentActivityDate) {
    const today = new Date();
    const activityDate = new Date(recentActivityDate);
    const dayOfTheWeek = today.getDay();
    const activityDayOfTheWeek = activityDate.getDay();

    const timeDiff = Math.abs(today - activityDate);
    const dateDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if ((dayOfTheWeek - activityDayOfTheWeek === 1) && dateDiff === 0) {
      return 1;
    };
    return dateDiff;
  };
  return null;
};

//takes date like 2023-07-03T00:00:00.000Z returns 07-03-2023
export const formatDate = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${month}-${day}-${year}`;
  }
  return null;
}