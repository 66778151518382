import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useGetActivityByIdQuery } from "../../state/salesApi";
import UpdateActivityForm from "../forms/UpdateActivityForm";
import { useSnackbar } from "notistack";
import Close from "@mui/icons-material/Close";

const ActivityDetails = ({ activityId, handleDetailsClose }) => {
  const {
    data: activity,
    error,
    isLoading,
    refetch,
  } = useGetActivityByIdQuery(activityId, {
    enabled: Boolean(activityId),
    refetchOnMountOrArgChange: true,
  });
  const [updateFormOpen, setUpdateFormOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = () => {
    setUpdateFormOpen(true);
  };

  const handleUpdate = () => {
    setUpdateFormOpen(false);
    enqueueSnackbar("Activity Updated");
    handleRefresh();
  };

  const handleFormClose = () => {
    setUpdateFormOpen(false);
  };

  const handleDateTime = (activityDateTime) => {
    const date = new Date(activityDateTime);
    const day = date.toLocaleDateString();
    const time = date.toLocaleTimeString();

    return `${day} at ${time}.`;
  };

  const handleRefresh = () => {
    refetch();
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    console.log(error);
    return <Typography>Error!</Typography>;
  }

  return (
    <Dialog open onClose={handleDetailsClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>Activity Details</Typography>
          <IconButton onClick={handleDetailsClose}>
            <Close />
          </IconButton>
        </div>
        <Divider sx={{ mb: "15px" }} />
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4">{activity.title}</Typography>
        <Typography>Date: {handleDateTime(activity.date)}</Typography>
        <Typography>
          Type:{" "}
          {activity.type
            .replace(/([A-Z])/g, " $1")
            .replace(/^./, function (str) {
              return str.toUpperCase();
            })}
        </Typography>
        <Typography>Description: {activity.description}</Typography>
        <Box display="flex" justifyContent="space-around" mt={2}>
          <Button onClick={handleOpen} variant="contained" color="primary">
            Update
          </Button>
        </Box>
        {updateFormOpen && (
          <UpdateActivityForm
            activityData={activity}
            onClose={handleFormClose}
            onSubmit={handleUpdate}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDetailsClose} variant="contained" color="error">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActivityDetails;
