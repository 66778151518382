import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { api } from "./api";

export const managementApi = api.injectEndpoints({
  endpoints: (build) => ({
    //! Agent API Calls
    getAgents: build.query({
      query: () => "/management/agent",
      providesTags: ["User", "Agent"],
    }),
    getAgentById: build.query({
      query: (id) => `management/agent/${id}`,
      providesTags: ["Agent"],
    }),
    updateAgent: build.mutation({
      query: ({ agentId, data }) => ({
        url: `management/agent/${agentId}`,
        method: "PATCH",
        body: {
          name: data.name || "",
          email: data.email,
          address: data.address || "",
          city: data.city || "",
          state: data.state || "",
          occupation: data.occupation || "",
          country: data.country || "",
          phoneNumber: data.phoneNumber || "",
          cellNumber: data.cellNumber || "",
          role: data.role || "agent",
          alternateEmails: data.alternateEmails,
          showStats: data.showStats
        },
      }),
      invalidatesTags: ["Agent"]
    }),
    deleteAgent: build.mutation({
      query: (id) => ({
        url: `management/agent/${id}`,
        method: "DELETE",
      }),
    }),
    //! Agent Activity API Calls
    getActivitiesByDate: build.query({
      query: (date) => `/management/date/${date}`,
    }),
    getActivitiesByUserAndDateRange: build.query({
      query: ({ userId, startDate, endDate, type, status, page, limit }) => ({
        url: `/management/user/${userId}?startDate=${startDate}&endDate=${endDate}${type ? `&type=${type}` : ""
          }${status ? `&status=${status}` : ""}&page=${page}&limit=${limit}`,
      }),
    }),
    getDispatchUsers: build.query({
      query: () => "management/dispatch",
    }),
    getDispatchUserById: build.query({
      query: (id) => `management/dispatch/${id}`,
    }),
    getManagerUsers: build.query({
      query: () => "management/manager",
    }),
    getManagerUserById: build.query({
      query: (id) => `management/manager/${id}`,
    }),
    getSalesAgents: build.query({
      query: () => "management/salesagents",
    }),
    getSalesAgentById: build.query({
      query: (id) => `management/salesagents/${id}`,
    }),
    managerGetCustomers: build.query({
      query: ({ page, limit, sortField, sortOrder }) =>
        `management/leads/customers?page=${page}&limit=${limit}&sortField=${sortField}&sortOrder=${sortOrder}`,
      providesTags: ["Customers"]
    }),
    searchAllCustomerLeads: build.query({
      query: ({ page, limit, search }) => `/management/leads/searchCustomers?page=${page}&limit=${limit}&search=${search}`
    }),
    managerGetAllSales: build.query({
      query: () => "management/sales",
    }),
    managerCreateSale: build.mutation({
      query: (data) => ({
        url: "management/sales",
        method: "POST",
        body: data,
      }),
    }),
    managerGetSaleById: build.query({
      query: (id) => `management/sales/${id}`,
    }),
    managerUpdateSale: build.mutation({
      query: ({ id, data }) => ({
        url: `management/sales/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    managerDeleteSale: build.mutation({
      query: (id) => ({
        url: `management/sales/${id}`,
        method: "DELETE",
      }),
    }),
    searchAllLeadsOrActivitiesByText: build.query({
      query: ({ type, searchText, page, limit, sortField, sortOrder }) =>
        `/management/search/${type}/${searchText}?page=${page}&limit=${limit}&sortField=${sortField}&sortOrder=${sortOrder}`
    }),
    getAllLeadsForUser: build.query({
      query: ({ userId, status, page, limit, sortField, sortOrder }) =>
        `management/leads/${userId}/${status}?page=${page}&limit=${limit}&sortField=${sortField}&sortOrder=${sortOrder}`,
    }),
    createLead: build.mutation({
      query: (data) => ({
        url: "management/leads",
        method: "POST",
        body: data,
      }),
    }),
    managerGetLeadById: build.query({
      query: (categoryIds) => `management/leads/${categoryIds}`,
    }),
    managerUpdateLead: build.mutation({
      query: ({ id, data, leadUpdates }) => ({
        url: `management/leads/update/${id}`,
        method: "PATCH",
        body: {
          lead: data,
          leadUpdates,
        },
      }),
      invalidatesTags: ["Customers", "ManageLeads"],
    }),
    managerDeleteLead: build.mutation({
      query: (id) => ({
        url: `/management/leads/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ManageLeads"]
    }),
    //! Agent Stat Calls
    getAgentDailyStatsByDate: build.query({
    query: ({ agentId, date }) =>
        `/management/stats/agent/${agentId}/daily/${date}`
    }),
    updateUserStatsByDate: build.mutation({
      query: ({ statsId, stats }) => ({
        url: `/management/stats/agent/${statsId}`,
        method: "PATCH",
        body: stats,
      }),
      invalidatesTags: ["Stats"]
    }),
    getAgentMonthlyReport: build.query({
      query: ({ agentId, monthYear }) =>
        `management/stats/agent/${agentId}/monthly/${monthYear}`,
    }),
    updateAgentGoals: build.mutation({
      query: ({ agentId, data }) => ({
        url: `management/agent/callgoals/${agentId}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Agent"]
    }),
    getAllUserStatsByDay: build.query({
      query: ({ date }) => 
        `/management/stats/day/${date}`,
      providesTags: ["Stats"]
    }),
    getAllUsersWeekDayStatsByWeek: build.query({
    query: () => 
        `/management/stats/weekdays`
    }),
    getAllUsersWeeklyStatsByWeek: build.query({
      query: ({ startDate, endDate }) => 
        `/management/stats/week?startDate=${startDate}&endDate=${endDate}`
    }),
    getAllUsersMonthlyStatsByMonth: build.query({
      query: ({ month }) =>
        `/management/stats/monthly/${month}`
    }),
    searchAgentsLeads: build.query({
      query: ({ userId, searchQuery, page, limit, sortField, sortOrder, isQuoteSelection = false }) =>
        `management/leads/search/${userId}/${searchQuery}?page=${page}&limit=${limit}&sortField=${sortField}&sortOrder=${sortOrder}${isQuoteSelection ? '&isQuoteSelection=true' : ''}`,
      providesTags: ["ManageLeads"]
    }),
    moveLeadsBetweenUsers: build.mutation({
      query: ({ userIdFrom, userIdTo, leadIds }) => ({
        url: "/management/leads/move",
        method: "PATCH",
        body: {
          userIdFrom,
          userIdTo,
          leadIds,
        },
      }),
      invalidatesTags: ["Customers"]
    }),
    /* TODO */
    /* CHECK IF ABLE TO DO MULTIPLE QUERIES TO ONE URL */
    /* moveCustomersBetweenUsers: build.mutation.... */
    updateLeadStatusForUser: build.mutation({
      query: ({ leadId, userId, status }) => ({
        url: "management/leads/status",
        method: "PATCH",
        body: {
          leadId,
          userId,
          status,
        },
      }),
    }),
    updateLeadStatusForMultipleLeadsAndUser: build.mutation({
      query: (leadUpdates) => ({
        url: `/management/leads/multipleStatuses`,
        method: "PATCH",
        body: leadUpdates,
      }),
    }),
    //! Manager Activity Calls
    createManagerActivity: build.mutation({
      query: (data) => ({
        url: `/management/managerActivities`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["User", "ManagerActivity"],
    }),
    getAllManagerActivities: build.query({
      query: ({ userId, startDate, endDate, type, status, page, limit, sortField, sortOrder }) => {
        const url = `/management/managerActivities?userId=${userId}&page=${page}&limit=${limit}${startDate ? `&startDate=${startDate}` : ""}
        ${endDate ? `&endDate=${endDate}` : ""}${type ? `&type=${type}` : ""}${status ? `&status=${status}` : ""}`;
        return url;
      },
      providesTags: ["ManagerActivity"],
    }),
    getManagerActivityById: build.query({
      query: (activityId) => `/management/managerActivities/${activityId}`,
    }),
    updateManagerActivity: build.mutation({
      query: ({ activityId, updates }) => ({
        url: `/management/managerActivities/${activityId}`,
        method: "PATCH",
        body: updates,
      }),
      invalidatesTags: ["ManagerActivity"],
    }),
    deleteManagerActivity: build.mutation({
      query: (activityId) => ({
        url: `/management/managerActivities/${activityId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ManagerActivity"],
    }),
    resetAgentPassword: build.mutation({
      query: (agentId) => ({
        url: `/management/resetpassword/${agentId}`,
        method: "PATCH",
      }),
    }),
    uploadCSV: build.mutation({
      query: (formData) => ({
        url: "/csv/upload",
        method: "POST",
        body: formData,
      }),
    }),
    uploadActivitiesCSV: build.mutation({
      query: (formData) => ({
        url: "/csv/upload-activities",
        method: "POST",
        body: formData,
      }),
    }),
    //! Pool API Calls
    getAllLeadsFromPool: build.query({
      query: (page) => `management/leads/pool?page=${page}`,
    }),
    moveLeadsToPool: build.mutation({
      query: (leadIds) => ({
        url: "management/leads/pool",
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ leadIds: leadIds }),
      }),
    }),
    moveLeadsFromPoolToUser: build.mutation({
      query: ({ userIdTo, leadIds }) => ({
        url: "/management/leads/movepooltouser",
        method: "PATCH",
        body: {
          userIdTo,
          leadIds,
        },
      }),
      invalidatesTags: ["Pool"],
    }),
    updatePoolLead: build.mutation({
      query: ({ id, data }) => ({
        url: `management/leads/update/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Pool"],
    }),
    deletePoolLead: build.mutation({
      query: (id) => ({
        url: `/management/leads/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Pool"],
    }),
    getAllCategories: build.query({
      query: () => "management/categories",
    }),
    getCategoriesByIds: build.query({
      query: (categoryIds) => ({
        url: `management/categories/byIds`,
        method: "POST",
        body: {
          categoryIds,
        },
      }),
    }),
    createCategory: build.mutation({
      query: ({ name, description }) => ({
        url: "management/categories",
        method: "POST",
        body: {
          name,
          description,
        },
      }),
    }),
    updateCategory: build.mutation({
      query: ({ categoryId, name, description }) => ({
        url: `management/categories/${categoryId}`,
        method: "PUT",
        body: {
          name,
          description,
        },
      }),
    }),
    deleteCategory: build.mutation({
      query: (categoryId) => ({
        url: `management/categories/${categoryId}`,
        method: "DELETE",
      }),
    }),
    addLeadToCategory: build.mutation({
      query: ({ leadIds, categoryIds }) => ({
        url: "management/leads/categories/add",
        method: "POST",
        body: {
          leadIds,
          categoryIds,
        },
      }),
      invalidatesTags: ["Pool"],
    }),
    removeCategoryFromLeads: build.mutation({
      query: ({ leadIds, categoryIds }) => ({
        url: "management/leads/categories/remove",
        method: "POST",
        body: {
          leadIds,
          categoryIds,
        },
      }),
      invalidatesTags: ["Pool"],
    }),
    updateLeadsCategory: build.mutation({
      query: ({ leadIds, oldCategoryId, newCategoryId }) => ({
        url: "management/leads/categories/update",
        method: "POST",
        body: {
          leadIds: JSON.stringify(leadIds),
          oldCategoryId,
          newCategoryId,
        },
      }),
    }),
    getLeadsByStatusAndCategory: build.query({
      query: ({
        status,
        page,
        limit,
        categoryId,
        commodity,
        sortField,
        sortOrder,
      }) => {
        const url = `management/leads/byCategory?status=${status}&page=${page}&limit=${limit}${categoryId ? `&categoryId=${categoryId}` : ""
          }${commodity && `&commodity=${commodity}`
          }&sortField=${sortField}&sortOrder=${sortOrder}`;
        return url;
      },
      providesTags: ["Pool"],
    }),
    //! *BEGINNING OF * ADMIN NUKE ROUTE< DO NOT USE THIS FOR ANY NORMAL USERS.
    deleteAllLeadsForUser: build.mutation({
      query: (userId) => ({
        url: `management/user/deleteAllLeads/${userId}`,
        method: "DELETE",
      }),
      // transformResponse: (baseResponse) => {  //USED FOR LOGGING RESPONSE
      //   console.log("Response:", baseResponse);
      //   return baseResponse;
      // }
    }),
    //! *END OF* ADMIN NUKE ROUTE< DO NOT USE THIS FOR ANY NORMAL USERS.
    uploadLegalDisclosure: build.mutation({
      query: (formData) => ({
        url: "/management/upload-legal-disclosure",
        method: "POST",
        body: formData,
      }),
    }),
    uploadPacketUpload: build.mutation({
      query: (formData) => ({
        url: "/management/upload-packet",
        method: "POST",
        body: formData,
      }),
    }),
    getSignaturesForUser: build.query({
      query: (userId) => `/management/get-all-signatures/${userId}`,
    }),
    uploadSignatureForUser: build.mutation({
      query: ({ userId, formData }) => ({
        url: `/management/upload-signature/${userId}`,
        method: "POST",
        body: formData,
      }),
    }),
    deleteSignatureFromUser: build.mutation({
      query: ({ userId, signatureName }) => ({
        url: `/management/delete-signature/${userId}/${signatureName}`,
        method: "DELETE",
      }),
    }),
    updateLeadStatuses: build.mutation({
      query: () => ({
        url: `/management/superadmin/update-lead-statuses`,
        method: "POST",
      }),
    }),
    updateLeadCustomers: build.mutation({
      query: () => ({
        url: `/management/superadmin/update-lead-customers`,
        method: "POST",
      }),
    }),
    /* getErrorLogs: build.query({
      query: ({ page, limit, sortBy, order, severity, message, startDate, endDate}) => 
        `/management/error-logs`,
    }),
    updateErrorLog: build.mutation({
      query: ({ logId, updateData }) => ({
        url: `/management/error-logs/${logId}`,
        method: "PATCH",
        body: updateData,
      }),
    }),
    deleteErrorLogs: build.mutation({
      query: ({ logIds }) => ({
        url: `/management/error-logs`
      })
    }) */
  }),
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  reducerPath: "managementApi",
  tagTypes: ["User"],
});

export const {
  useGetAgentsQuery,
  useGetAgentByIdQuery,
  useUpdateAgentMutation,
  useDeleteAgentMutation,
  useGetActivitiesByDateQuery,
  useGetActivitiesByUserAndDateRangeQuery,
  useGetDispatchUsersQuery,
  useGetDispatchUserByIdQuery,
  useGetManagerUsersQuery,
  useGetManagerUserByIdQuery,
  useGetSalesAgentsQuery,
  useGetSalesAgentByIdQuery,
  useManagerGetCustomersQuery,
  useSearchAllCustomerLeadsQuery,
  useManagerGetAllSalesQuery,
  useManagerCreateSaleMutation,
  useManagerGetSaleByIdQuery,
  useManagerUpdateSaleMutation,
  useManagerDeleteSaleMutation,
  useSearchAllLeadsOrActivitiesByTextQuery,
  useGetAllLeadsForUserQuery,
  useCreateLeadMutation,
  useManagerGetLeadByIdQuery,
  useManagerUpdateLeadMutation,
  useManagerDeleteLeadMutation,
  useGetAgentDailyStatsByDateQuery,
  useUpdateUserStatsByDateMutation,
  useGetAgentMonthlyReportQuery,
  useUpdateAgentGoalsMutation,
  useGetAllUserStatsByDayQuery,
  useGetAllUsersWeekDayStatsByWeekQuery,
  useGetAllUsersWeeklyStatsByWeekQuery,
  useGetAllUsersMonthlyStatsByMonthQuery,
  useSearchAgentsLeadsQuery,
  useMoveLeadsBetweenUsersMutation,
  useUpdateLeadStatusForUserMutation,
  useUpdateLeadStatusForMultipleLeadsAndUserMutation,
  useCreateManagerActivityMutation,
  useGetAllManagerActivitiesQuery,
  useGetManagerActivityByIdQuery,
  useUpdateManagerActivityMutation,
  useDeleteManagerActivityMutation,
  useResetAgentPasswordMutation,
  useUploadCSVMutation,
  useUploadActivitiesCSVMutation,
  useGetAllLeadsFromPoolQuery,
  useMoveLeadsToPoolMutation,
  useMoveLeadsFromPoolToUserMutation,
  useUpdatePoolLeadMutation,
  useDeletePoolLeadMutation,
  useGetAllCategoriesQuery,
  useGetCategoriesByIdsQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useAddLeadToCategoryMutation,
  useRemoveCategoryFromLeadsMutation,
  useUpdateLeadsCategoryMutation,
  useGetLeadsByStatusAndCategoryQuery,
  useDeleteAllLeadsForUserMutation,
  useGetSignaturesForUserQuery,
  useUploadSignatureForUserMutation,
  useDeleteSignatureFromUserMutation,
  useUploadLegalDisclosureMutation,
  useUploadPacketUploadMutation,
  useUpdateLeadStatusesMutation,
  useUpdateLeadCustomersMutation,
  // useGetErrorLogsQuery,
  // useUpdateErrorLogMutation,
  // useDeleteErrorLogsMutation,
} = managementApi;
