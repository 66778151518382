import { /* , useTheme */ } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import BugReportForm from "components/forms/BugReportForm";
import { useState } from "react";

function Footer(
  {
    /* user */
  }
) {
  // const theme = useTheme();
  const [bugFormOpen, setBugFormOpen] = useState(false);

  const handleOpenBugForm = () => {
    setBugFormOpen(true);
  };

  const handleCloseBugForm = () => {
    setBugFormOpen(false);
  };

  return (
    <AppBar sx={{ position: "static", background: "none", height: "70px" }}>
      <Toolbar sx={{ justifyContent: "flex-end" }}>
        <Typography className="bug-report-link" onClick={handleOpenBugForm}>
          Report a bug
        </Typography>
      </Toolbar>
      {bugFormOpen && (
        <BugReportForm open={bugFormOpen} handleClose={handleCloseBugForm} />
      )}
      <style>
        {`
                    .bug-report-link:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                `}
      </style>
    </AppBar>
  );
}

export default Footer;
