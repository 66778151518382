import { useState } from "react";
import { useUpdateManagerActivityMutation } from "state/managementApi";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Close from "@mui/icons-material/Close";
import { hoverStyle } from "components/helpers/MediaQueries";
import handleUpdateDifferences from "components/helpers/UpdateFormDifferences";
import { useSnackbar } from "notistack";

const UpdateManagerActivityForm = ({ activityData, onClose }) => {
    const initialValues = {
        title: activityData.title,
        description: activityData.description,
        type: activityData.type,
        status: activityData.status,
    }

    const [formErrors, setFormErrors] = useState([]);
    const [updateActivity, { isLoading: updating }] = useUpdateManagerActivityMutation();

    const { enqueueSnackbar } = useSnackbar();

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({
            title: Yup.string().required('Title is required'),
            description: Yup.string().required('Description is required'),
            type: Yup.string().required('Type is required'),
            status: Yup.string().required('Status is required'),
        }),
        onSubmit: async (values) => {
            try {
                const updatedValues = { ...values };
                const differences = await handleUpdateDifferences(activityData, updatedValues);

                await updateActivity({ activityId: activityData._id, updates: differences })
                    .then(() => {
                        enqueueSnackbar('Activity Updated');
                        onClose();
                    });
            } catch (error) {
                console.error("Error updating activity:", error);
            }
        }
    });


    return (
        <Dialog open onClose={onClose} fullWidth maxWidth='sm'>
            <DialogTitle>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h6">Update Activity</Typography>
                    <Close sx={hoverStyle} onClick={onClose} />
                </div>
                <Divider sx={{ mb: '15px', }} />
            </DialogTitle>
            <DialogContent /* sx={{ minWidth: '400px', width: '400px', }} */>
                <Box>
                    <FormControl fullWidth margin="normal" required>
                        <InputLabel>Type</InputLabel>
                        <Select
                            name="type"
                            value={formik.values.type}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        >
                            <MenuItem value="call">Call</MenuItem>
                            <MenuItem value="email">Email</MenuItem>
                            <MenuItem value="listEmail">List Email</MenuItem>
                            <MenuItem value="inPerson">In Person</MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                        </Select>
                        {formik.touched.type && formik.errors.type && (
                            <Alert severity="error">{formik.errors.type}</Alert>
                        )}
                    </FormControl>
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Title"
                        name="title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.title && formik.errors.title && (
                        <Alert severity="error">{formik.errors.title}</Alert>
                    )}
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        multiline
                        rows={4}
                        label="Description"
                        name="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.description && formik.errors.description && (
                        <Alert severity="error">{formik.errors.description}</Alert>
                    )}
                    <FormControl fullWidth margin="normal" required>
                        <InputLabel>Status</InputLabel>
                        <Select
                            name="status"
                            value={formik.values.status}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        >
                            <MenuItem value="upcoming">Upcoming</MenuItem>
                            <MenuItem value="inProgress">In Progress</MenuItem>
                            <MenuItem value="complete">Complete</MenuItem>
                        </Select>
                        {formik.touched.status && formik.errors.status && (
                            <Alert severity="error">{formik.errors.status}</Alert>
                        )}
                    </FormControl>
                    {formErrors.length > 0 && (
                        <Alert severity="error">Error Updating Activity</Alert>
                    )}
                </Box>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={updating || formik.values === initialValues}
                    onClick={formik.handleSubmit}
                >
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UpdateManagerActivityForm;