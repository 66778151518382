import Add from "@mui/icons-material/Add";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import Attachment from "@mui/icons-material/Attachment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { UpdateSettings } from "components/helpers/UpdateSettings";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { useGetSignaturesQuery, useSendEmailMutation } from "state/api";
import { useCreateManagerActivityMutation } from "state/managementApi";
import { useCreateActivityForLeadsMutation } from "state/salesApi";
import * as Yup from "yup";
import ReactQuill from "react-quill-new";
import "react-quill-new/dist/quill.snow.css";

function ComposeEmail({
  handleCloseEmailForm,
  arrayOfEmails,
  arrayOfLeadIds,
  isManager = false,
  DNAIds,
}) {
  const user = useSelector((state) => state.user);
  const [userSettings, setUserSettings] = useState(
    JSON.parse(localStorage.getItem("settings")) || null
  );
  // TODO: DOUBLE CHECK THIS
  const [senderAddress, setSenderAddress] = useState(
    user.alternateEmails.length && (userSettings && userSettings.preferredEmail)
      ? userSettings?.preferredEmail
      : 1
  );
  const [attachments, setAttachments] = useState([]);
  const {
    data: signatures,
  } = useGetSignaturesQuery(user._id);
  const [sendEmail] = useSendEmailMutation();
  const [emailResponseMessage, setEmailResponseMessage] = useState([]);
  const [formErrors, setFormErrors] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  // Set 'Add Recipient' dialog open if composing new email and not emailing a lead
  const [openAddEmailDialog, setOpenAddEmailDialog] = useState(
    arrayOfEmails.length === 0
  );
  const [newEmailAddress, setNewEmailAddress] = useState("");
  const [sendOptionsOpen, setSendOptionsOpen] = useState(false);
  const [addEmailErrors, setAddEmailErrors] = useState([]);

  const [leadIds, setLeadIds] = useState(arrayOfLeadIds);
  const [addEmailActivityMutation] = useCreateActivityForLeadsMutation();
  const [addManagerActivity] = useCreateManagerActivityMutation();
  const quillRef = useRef(null);

  // Validation schema for manually adding emails
  const addEmailSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
        "Invalid email"
      )
      .required("Email is required"),
  });

  const handleAddEmailSubmit = async () => {
    try {
      await addEmailSchema.validate({
        email: newEmailAddress,
      });

      const formattedNewEmail =
        formik.values.to.trim().length > 0
          ? `, ${newEmailAddress}`
          : newEmailAddress;

      formik.setFieldValue("to", `${formik.values.to} ${formattedNewEmail}`);
      formik.setFieldValue("numLeads", formik.values.numLeads++);

      setNewEmailAddress("");
      setAddEmailErrors([]);
      setOpenAddEmailDialog(false);
    } catch (err) {
      setAddEmailErrors(err.errors);
    }
  };

  const formik = useFormik({
    initialValues: {
      userId: user._id,
      to: arrayOfEmails.join(", "),
      emailSubject: "",
      emailText: "",
      deliveryTime: null,
      signatureName: "",
      packetIncluded: false,
      numLeads: arrayOfEmails.length,
      senderEmail: user.email,
      inlineImages: [],
    },
    validationSchema: Yup.object({
      to: Yup.string().required("Recipient email is required"),
      emailSubject: Yup.string().required("Email subject is required"),
      emailText: Yup.string().required("Email message is required"),
      deliveryTime: Yup.date()
        .nullable()
        .test(
          "is-future-time",
          "Delivery time must be at least 15 minutes in the future",
          function (value) {
            if (value) {
              const currentTime = new Date();
              return (
                value > currentTime &&
                value > currentTime.getTime() + 15 * 60 * 1000
              );
            }
            return true;
          }
        ),
      packetIncluded: Yup.boolean(),
      numLeads: Yup.number(),
      senderEmail: Yup.string(),
      inlineImages: Yup.array(),
    }),

    onSubmit: async (values, helpers) => {
      let errors = [];
      const inlineImageErrors = validateInlineImages();
      if (inlineImageErrors.length > 0) {
        setFormErrors(inlineImageErrors);
        return;
      }
      const formData = new FormData();

      let activityData = {
        isListEmail: false,
        type: "",
      };

      if (DNAIds && DNAIds.length) {
        const filteredLeadIds = leadIds.filter((id) => !DNAIds.includes(id));
        activityData.leadIds = filteredLeadIds;
      } else {
        activityData.leadIds = leadIds;
      }

      formData.append("userId", values.userId);
      formData.append("to", values.to);
      formData.append("subject", values.emailSubject);
      formData.append("text", values.emailText);

      // Handle inlineImages
      if (values.inlineImages.length > 0) {
        formData.append("inlineImages", JSON.stringify(values.inlineImages));
      }


      if (values.deliveryTime) {
        const deliveryDateTime = new Date(values.deliveryTime);

        if (!isNaN(deliveryDateTime)) {
          formData.append("deliveryTime", deliveryDateTime.toISOString());
        }
      }

      formData.append("signatureName", values.signatureName);

      for (const attachment of attachments) {
        formData.append("attachments", attachment);
      }

      formData.append("packetIncluded", values.packetIncluded);
      formData.append("numLeads", values.numLeads);

      // Logic for handling sender address
      if (senderAddress === 1) {
        formData.append("senderEmail", values.senderEmail);
      } else {
        formData.append(
          "senderEmail",
          user.alternateEmails[userSettings.preferredEmail - 2]
        );
      }

      try {
        await sendEmail(formData).unwrap();
        setEmailResponseMessage([
          { type: "success", message: "Email sent successfully!" },
        ]);
        if (!isManager) {
          activityData.text = values.emailText;

          if (leadIds.length > 1) {
            activityData.title = "List Email Sent";
            activityData.type = "listEmail";

            await addEmailActivityMutation(activityData);
          } else if (leadIds.length === 1) {
            activityData.title = "Email Sent";
            activityData.type = "email";

            await addEmailActivityMutation(activityData);
          }
        } else {
          activityData.description = values.emailText;

          if (leadIds.length > 1) {
            activityData.title = "List Email Sent";
            activityData.type = "listEmail";
            activityData.date = new Date();

            await addManagerActivity(activityData);
          } else if (leadIds.length === 1) {
            activityData.title = "Email Sent";
            activityData.type = "email";
            activityData.date = new Date();

            await addManagerActivity(activityData);
          }
        }
        formik.resetForm();
        setFormErrors([]);
        setAttachments([]);
        handleCloseEmailForm();
        enqueueSnackbar("Email sent");
      } catch (error) {
        console.error("Email send error:", error);
        errors.push(error);
        setFormErrors(errors);
        setEmailResponseMessage([
          {
            type: "error",
            message: error.message || "An Email error has occurred.",
          },
        ]);
      }
    },
  });

  const handleQuillChange = (content, delta, source, editor) => {
    formik.setFieldValue("emailText", content);
  };

  const handlePasteEvent = useCallback(
    (e) => {
      e.preventDefault();
      const clipboardItems = Array.from(e.clipboardData.items);
      const newInlineImages = [...formik.values.inlineImages];
  
      clipboardItems.forEach((item) => {
        if (item.type.startsWith("image/")) {
          const file = item.getAsFile();
          const reader = new FileReader();
  
          reader.onload = (event) => {
            const base64Image = event.target.result; // Full base64 string
            const mimeType = item.type;
            const extension = mimeType.split("/")[1];
            const cid = `inline-image-${crypto.randomUUID()}`; // Unique cid
  
            // Insert Base64 URL into Quill for display
            const quill = quillRef.current.getEditor();
            const range = quill.getSelection();
            quill.clipboard.dangerouslyPasteHTML(
              range.index,
              `<img src="${base64Image}" alt="Pasted Image" style="max-width: 100%; height: auto;" />`
            );
  
            // Store the `cid` and Base64 data for email sending
            newInlineImages.push({
              cid,
              filename: `pasted-image-${newInlineImages.length + 1}.${extension}`,
              mimeType,
              data: base64Image.split(",")[1], // Only the Base64 part
            });
  
            formik.setFieldValue("inlineImages", newInlineImages);
          };
  
          reader.readAsDataURL(file);
        }
      });
    },
    [formik, quillRef]
  );

  const validateInlineImages = () => {
    const maxImageSize = 50 * 1024 * 1024; // 50 MB
    const maxImageCount = 10;
    const errors = [];

    if (formik.values.inlineImages.length > maxImageCount) {
      errors.push("Too many inline images. Please limit to 10.");
    }

    formik.values.inlineImages.forEach((image) => {
      const imageSize = Buffer.from(image.data, "base64").length;
      if (imageSize > maxImageSize) {
        errors.push(`${image.filename} exceeds the maximum size of 5 MB.`);
      }
    });

    return errors;
  };


  const handleEmailSendDateClick = () => {
  };

  const handleAttachmentChange = (e) => {
    const files = e.target.files;
    setAttachments([...attachments, ...files]);
  };

  const handleAddEmailClick = () => {
    setOpenAddEmailDialog(true);
  };

  const handleAddEmailDialogClose = () => {
    setOpenAddEmailDialog(false);
  };

  const handlePreferredEmailSelect = () => {
    const updatedSettings = UpdateSettings("preferredEmail", senderAddress);
    setUserSettings(updatedSettings);
    setSenderAddress(updatedSettings["preferredEmail"]);
  };

  const handleSendOptionsDisplay = () => {
    setSendOptionsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!user.alternateEmails.length) {
      handlePreferredEmailSelect();
    }
  }, []);

  useEffect(() => {
    const editor = quillRef.current?.getEditor?.();
    const editorElement = editor?.container;

    if (editorElement) {
      editorElement.addEventListener("paste", handlePasteEvent);
    }

    return () => {
      if (editorElement) {
        editorElement.removeEventListener("paste", handlePasteEvent);
      }
    };
  }, [handlePasteEvent, quillRef]);

  if (!signatures) return;

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleCloseEmailForm}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">New Message</Typography>
            <IconButton onClick={handleCloseEmailForm}>
              <CloseIcon />
            </IconButton>
          </div>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <div>
            <Typography sx={{ fontSize: "12px" }}>
              Sending from:{" "}
              {!userSettings ||
                !userSettings.preferredEmail ||
                userSettings.preferredEmail === 1 ||
                !user.alternateEmails.length
                ? `${user.email}`
                : `${user.alternateEmails[userSettings.preferredEmail - 2]}`}
            </Typography>
            <TextField
              label="To"
              variant="standard"
              fullWidth
              name="to"
              value={formik.values.to}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              margin="normal"
              required
              inputProps={{
                readOnly: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleAddEmailClick}>
                      <Add />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {formik.touched.to && formik.errors.to && (
              <Alert severity="error">{formik.errors.to}</Alert>
            )}
            <TextField
              label="Subject"
              variant="standard"
              fullWidth
              name="emailSubject"
              value={formik.values.emailSubject}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            {formik.touched.emailSubject && formik.errors.emailSubject && (
              <Alert severity="error">{formik.errors.emailSubject}</Alert>
            )}
            <ReactQuill
              ref={quillRef}
              id="emailEditor"
              value={formik.values.emailText}
              onChange={handleQuillChange}
              modules={{
                toolbar: [
                  ["bold", "italic", "underline", "strike"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link", "image"],
                ],
              }}
              formats={[
                "bold",
                "italic",
                "underline",
                "strike",
                "list",
                "link",
                "image",
              ]}
              style={{
                minHeight: "200px",
              }}
            />
            <input
              type="hidden"
              id="inlineImagesInput"
              name="inlineImages"
              value={formik.values.inlineImages}
              onChange={formik.handleChange}
            />
            {formik.touched.emailText && formik.errors.emailText && (
              <Alert severity="error">{formik.errors.emailText}</Alert>
            )}
            <TextField
              label="Delivery Time"
              variant="outlined"
              fullWidth
              name="deliveryTime"
              value={formik.values.deliveryTime || ""}
              type="datetime-local"
              onBlur={formik.handleBlur}
              margin="normal"
              onClick={handleEmailSendDateClick}
              onChange={(e) =>
                formik.setFieldValue("deliveryTime", e.target.value)
              }
              InputProps={{
                min: new Date().toISOString().split("T")[0], // Restrict to future dates
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {formik.touched.deliveryTime && formik.errors.deliveryTime && (
              <Alert severity="error">{formik.errors.deliveryTime}</Alert>
            )}
            <InputLabel id="signature">Signature</InputLabel>
            <Box>
              <Select
                labelId="signature"
                label="Signature"
                name="signatureName"
                value={formik.values.signatureName || ""}
                style={{ width: "100%", height: "100%" }}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                {signatures.map((signature, index) => (
                  <MenuItem key={index} value={signature.name}>
                    <img src={signature.data} alt={signature.name} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <input
              type="file"
              id="attachmentInput"
              multiple
              style={{ display: "none" }}
              onChange={(e) => {
                handleAttachmentChange(e);
                e.target.value = null; // Reset the input value
              }}
            />
            {attachments.length > 0 && (
              <List>
                {attachments.map((attachment, index) => (
                  <ListItem key={`attachment-${index}`}>
                    <DeleteIcon
                      variant="contained"
                      sx={{ cursor: 'pointer' }}
                      onClick={() =>
                        setAttachments(
                          attachments.filter((a) => a !== attachment)
                        )
                      }
                    />
                    <ListItemIcon>
                      <AttachFileIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={attachment.name}
                      secondary={`${attachment.size} bytes`}
                    />
                  </ListItem>
                ))}
              </List>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <form onSubmit={formik.handleSubmit}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Tooltip title="Send" placement="top">
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                          borderRadius: "15px",
                          borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px",
                        }}
                      >
                        Send
                      </Button>
                    </Tooltip>
                    <Tooltip title="Send options" placement="top">
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          borderRadius: "15px",
                          borderTopLeftRadius: "0px",
                          borderBottomLeftRadius: "0px",
                          padding: "5px",
                          minWidth: "0",
                        }}
                        onClick={handleSendOptionsDisplay}
                      >
                        {!sendOptionsOpen ? <ArrowDropDown /> : <ArrowDropUp />}
                      </Button>
                    </Tooltip>
                  </Box>
                </form>
                <Box sx={{ display: "flex", marginLeft: "15px" }}>
                  <Tooltip title="Attach Files" placement="top">
                    <IconButton
                      sx={{
                        rotate: "-90deg",
                        borderRadius: "5px",
                        padding: "2px",
                      }}
                      onClick={() => {
                        document.getElementById("attachmentInput").click();
                      }}
                    >
                      <Attachment />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name="packetIncluded"
                    value={formik.values.packetIncluded}
                    onChange={formik.handleChange}
                  />
                }
                label="Include packet"
                style={{
                  marginLeft: "10px",
                }}
              />
            </Box>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={openAddEmailDialog}>
        <DialogContent style={{ position: "relative" }}>
          <Box p={2}>
            <CloseIcon
              style={{
                position: "absolute",
                right: "5px",
                top: "5px",
                cursor: "pointer",
              }}
              onClick={handleAddEmailDialogClose}
            />
            <Typography>Send to:</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                value={newEmailAddress}
                onChange={(e) => setNewEmailAddress(e.target.value)}
                style={{ marginTop: "8px", marginRight: "8px" }}
              />
            </Box>
            {(!userSettings ||
              !userSettings.preferredEmail) /* || userSettings.preferredEmail !== 1 */ &&
              user.alternateEmails.length > 0 && (
                <Box
                  mt="35px"
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography>Send from:</Typography>
                  <FormControl>
                    <Select
                      value={senderAddress}
                      onChange={(e) => {
                        setSenderAddress(e.target.value);
                      }}
                    >
                      <MenuItem value={1}>{user.email}</MenuItem>
                      {user.alternateEmails.map((alt, index) => (
                        <MenuItem key={index} value={index + 2}>
                          {alt}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      *Selecting an option will save it as your preferred email
                      in settings
                    </FormHelperText>
                  </FormControl>
                </Box>
              )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleAddEmailSubmit();
              handlePreferredEmailSelect();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ position: "relative" }}>
        {emailResponseMessage.length > 0 &&
          emailResponseMessage.map((message, index) =>
            message.type === "error" ? (
              <Alert key={index} severity={message.type}>
                {message.message}
              </Alert>
            ) : null
          )}
      </div>
    </div>
  );
}
export default ComposeEmail;
