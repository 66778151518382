import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import useTheme from "@mui/material/styles/useTheme";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import DialogActions from "@mui/material/DialogActions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Header from "components/layout/Header";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import ArchiveIcon from "@mui/icons-material/Archive";
import mongoose from "mongoose";
import { useArchiveDispatchCardMutation } from "../../state/api";
import { setupWebSocket } from "../../components/helpers/WebSocketSetup";
import { useSnackbar } from "notistack";

const Dispatch = () => {
  const theme = useTheme();
  const isConnected = useSelector((state) => state.webSocket.isConnected);
  const [socket, setSocket] = useState(null);
  const [columns, setColumns] = useState({});
  const [isEditingColor, setIsEditingColor] = useState(false);
  const [editCardId, setEditCardId] = useState(null);
  const [editedTitle, setEditedTitle] = useState("");
  const [editedSubtitle, setEditedSubtitle] = useState("");
  const [editedNotes, setEditedNotes] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  const [editedColor, setEditedColor] = useState("");
  const [oldColor, setOldColor] = useState("");
  const [archiveDispatchCardMutation] = useArchiveDispatchCardMutation();
  const [archiveMessage, setArchiveMessage] = useState("");
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
  const [expandedCards, setExpandedCards] = useState({});
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const defaultCardColor = theme.palette.primary[1000];

  const PREDEFINED_COLORS = [
    { name: "Default", color: defaultCardColor },
    { name: "Sergio Rosa", color: "#d961a2" },
    { name: "Tori Siegal", color: "#8e7cc3" },
    { name: "Morgan Hodges", color: "#0e9602" },
    { name: "Dispatch 1", color: "#6d9eeb" },
    { name: "Dispatch 2", color: "#76a5af" },
    { name: "Other", color: "#a0522d" }, // Orange/Brown
    { name: "Trouble", color: "#ff0000" }, // Red
    { name: "Assign Load", color: "#bbb85b" }, // Faded Yellow
  ];

  useEffect(() => {
    const componentName = "Dispatch";
    const newSocket = setupWebSocket(
      process.env.REACT_APP_WEBSOCKET_URL,
      componentName
    );

    newSocket.on("update", (updatedColumns) => {
      setColumns(updatedColumns);
    });
    setSocket(newSocket);
    return () => {
      newSocket.disconnect();
    };
  }, []);

  const handleAddCard = () => {
    const id = new mongoose.Types.ObjectId().toString();
    const title = "";
    const subtitle = "";
    const notes = "";
    const description = "";
    const color = theme.palette.primary[1000];
    const columnId = "column-1";
    const column = columns[columnId];
    const newItems = [
      ...column.items,
      { id, title, subtitle, notes, description, color },
    ];
    const newColumn = { ...column, items: newItems };
    const updatedColumns = { ...columns, [columnId]: newColumn };
    setColumns(updatedColumns);
    socket.emit("update", updatedColumns);
  };

  const handleEditCard = (itemId) => {
    const item = getItemById(itemId);
    setEditCardId(itemId);
    setOldColor(item.color);
    setEditedColor(item.color);
    setEditedTitle(item.title);
    setEditedSubtitle(item.subtitle);
    setEditedNotes(item.notes);
    setEditedDescription(item.description);
    setIsEditingColor(true);
  };

  const handleCardColorChange = (cardId, color) => {
    if (isEditingColor) {
      const item = getItemById(cardId);
      item.color = color.hex;
      setEditedColor(color.hex);
    }
  };

  const handleSaveCard = (itemId) => {
    const updatedColumns = { ...columns };
    const item = getItemById(itemId);
    item.title = editedTitle;
    item.subtitle = editedSubtitle;
    item.notes = editedNotes;
    item.description = editedDescription;
    item.color = editedColor;
    setColumns(updatedColumns);
    setEditCardId(null);
    setIsEditingColor(false);
    socket.emit("update", updatedColumns); // Change to http request
  };

  const handleCancelEditCard = (itemId) => {
    const item = getItemById(itemId);
    setEditCardId(null);
    setEditedTitle("");
    setEditedSubtitle("");
    setEditedNotes("");
    setEditedDescription("");
    //handles bug if no color selected then cancelled.
    if (oldColor) {
      item.color = oldColor;
    }
  };

  const handleEditCardChange = (e, field) => {
    switch (field) {
      case "title":
        setEditedTitle(e.target.value);
        break;
      case "subtitle":
        setEditedSubtitle(e.target.value);
        break;
      case "notes":
        setEditedNotes(e.target.value);
        break;
      case "description":
        const newValue = e.target.value;
        if (newValue !== undefined) {
          setEditedDescription(newValue);
        }
        break;
      default:
        break;
    }
  };

  const handleDeleteCard = (itemId) => {
    const confirmed = window.confirm("Confirm: delete this card?");
    if (!confirmed) {
      return;
    }
    const updatedColumns = { ...columns };
    for (let column of Object.values(updatedColumns)) {
      const itemIndex = column.items.findIndex((item) => item.id === itemId);
      if (itemIndex > -1) {
        column.items.splice(itemIndex, 1);
        break;
      }
    }
    setColumns(updatedColumns);
    socket.emit("update", updatedColumns);
  };

  const handleNewArchiveCard = async (itemId) => {
    const item = getItemById(itemId);
    if (!item.title || !item.subtitle || !item.description || !item.notes) {
      const confirmed = window.confirm(
        "Some required fields are empty. Are you sure you want to proceed?"
      );
      if (!confirmed) {
        return;
      }
    }
    try {
      await archiveDispatchCardMutation({
        cardId: item.id,
        title: item.title,
        subtitle: item.subtitle,
        notes: item.notes,
        description: item.description,
        color: item.color,
      }).then(() => {
        enqueueSnackbar("Dispatch card archived");
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getItemById = (itemId) => {
    return Object.values(columns)
      .flatMap((column) => column.items)
      .find((item) => item.id === itemId);
  };

  const handleToggleCardExpansion = (cardId) => {
    setExpandedCards((prevExpandedCards) => ({
      ...prevExpandedCards,
      [cardId]: !prevExpandedCards[cardId],
    }));
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const item = sourceColumn.items.splice(source.index, 1)[0];
    destColumn.items.splice(destination.index, 0, item);
    setColumns({
      ...columns,
      [source.droppableId]: sourceColumn,
      [destination.droppableId]: destColumn,
    });
    // Send websocket update
    const updatedColumns = { ...columns };
    updatedColumns[source.droppableId] = sourceColumn;
    updatedColumns[destination.droppableId] = destColumn;
    socket.emit("update", updatedColumns);
  };

  const handleOpenFilterDialog = () => setFilterDialogOpen(true);
  const handleCloseFilterDialog = () => setFilterDialogOpen(false);

  const handleFilterChange = (color) => {
    if (activeFilters.includes(color)) {
      setActiveFilters((prev) => prev.filter((c) => c !== color));
    } else {
      setActiveFilters((prev) => [...prev, color]);
    }
  };

  const handleClearFilters = () => {
    setActiveFilters([]);
  };

  return (
    <Box p="1.5rem 2.5rem" sx={{ width: "100%" }}>
      <Header
        title="DISPATCH BOARD"
        subtitle="Manage shipment cards and status"
      />
      <Typography
        variant="h6"
        component="h2"
        gutterBottom
        sx={{ paddingBottom: 1 }}
      >
        WebSocket Status:{" "}
        {isConnected ? (
          <Typography sx={{ color: "#00deeb" }}>Connected</Typography>
        ) : (
          <Typography sx={{ color: "#FF0000" }}>Disconnected</Typography>
        )}
      </Typography>
      <Grid
        container
        spacing={1}
        sx={{ backgroundColor: theme.palette.background.alt, padding: 2 }}
      >
        <Grid item xs={12} md={2}>
          <Grid item container spacing={8}>
            <Grid item xs={12} sm={4} md={4}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleAddCard}
              >
                Add Card
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleOpenFilterDialog}
              >
                Filter Colors
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleClearFilters}
              >
                Clear Filters
              </Button>
            </Grid>
          </Grid>
          {filterDialogOpen && (
            <Dialog open={filterDialogOpen} onClose={handleCloseFilterDialog}>
              <DialogTitle>Filter by Color</DialogTitle>
              <DialogContent>
                {PREDEFINED_COLORS.map((color) => (
                  <FormControlLabel
                    key={color.name}
                    control={
                      <Checkbox
                        checked={activeFilters.includes(color.color)}
                        onChange={() => handleFilterChange(color.color)}
                        name={color.name}
                        color="primary"
                      />
                    }
                    label={color.name}
                  />
                ))}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseFilterDialog}
                  variant="contained"
                  color="error"
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Grid container spacing={1}>
              {Object.entries(columns).map(([columnId, column]) => (
                <Grid item xs={12} sm={6} md={6} lg={4} key={columnId}>
                  <Paper elevation={3}>
                    <Typography
                      variant="h6"
                      component="h2"
                      align="center"
                      gutterBottom
                      sx={{ backgroundColor: theme.palette.primary[900] }}
                    >
                      {column.name}
                    </Typography>
                    <Droppable droppableId={columnId}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {column.items
                            .filter(
                              (item) =>
                                item &&
                                (activeFilters.length === 0 ||
                                  activeFilters.includes(item.color))
                            )
                            .map((item, index) => {
                              const isEditing = editCardId === item.id;
                              const isExpanded = expandedCards[item.id];

                              return (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Paper
                                        elevation={isEditing ? 10 : 3}
                                        style={{ backgroundColor: item.color }}
                                      >
                                        <div
                                          style={{
                                            padding: "8px",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          {isEditing ? (
                                            <div>
                                              <TextField
                                                fullWidth
                                                autoFocus
                                                label="Title"
                                                value={editedTitle}
                                                onChange={(e) =>
                                                  handleEditCardChange(
                                                    e,
                                                    "title"
                                                  )
                                                }
                                                variant="standard"
                                                multiline
                                              />
                                              <TextField
                                                fullWidth
                                                label="Subtitle"
                                                value={editedSubtitle}
                                                onChange={(e) =>
                                                  handleEditCardChange(
                                                    e,
                                                    "subtitle"
                                                  )
                                                }
                                                variant="standard"
                                              />
                                              <TextField
                                                fullWidth
                                                label="Notes"
                                                value={editedNotes}
                                                onChange={(e) =>
                                                  handleEditCardChange(
                                                    e,
                                                    "notes"
                                                  )
                                                }
                                                variant="standard"
                                                multiline
                                              />
                                              <TextField
                                                fullWidth
                                                label="Description"
                                                variant="outlined"
                                                value={editedDescription.replace(
                                                  /,\s+/g,
                                                  ", "
                                                )}
                                                multiline
                                                onChange={(e) => {
                                                  const newValue =
                                                    e.target.value;
                                                  setEditedDescription(
                                                    newValue
                                                  );
                                                }}
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                InputProps={{
                                                  style: {
                                                    whiteSpace: "pre-wrap",
                                                    overflowWrap: "break-word",
                                                    wordBreak: "break-all",
                                                  },
                                                }}
                                              />

                                              <div>
                                                {PREDEFINED_COLORS.map(
                                                  (predefinedColor) => {
                                                    if (
                                                      predefinedColor.name.toLowerCase() !==
                                                      "default"
                                                    ) {
                                                      return (
                                                        <Tooltip
                                                          title={
                                                            predefinedColor.name
                                                          }
                                                          key={
                                                            predefinedColor.name
                                                          }
                                                        >
                                                          <Button
                                                            style={{
                                                              backgroundColor:
                                                                predefinedColor.color,
                                                              margin: "5px",
                                                              color: "white",
                                                            }}
                                                            onClick={() =>
                                                              handleCardColorChange(
                                                                item.id,
                                                                {
                                                                  hex: predefinedColor.color,
                                                                }
                                                              )
                                                            }
                                                          >
                                                            {
                                                              predefinedColor.name
                                                            }
                                                          </Button>
                                                        </Tooltip>
                                                      );
                                                    }
                                                    return null;
                                                  }
                                                )}
                                              </div>
                                              {/* <SketchPicker
                                              color={editedColor}
                                              onChange={(color) => handleCardColorChange(item.id, color)}
                                            /> */}
                                              <Tooltip
                                                title="Save"
                                                placement="top"
                                              >
                                                <IconButton
                                                  onClick={() =>
                                                    handleSaveCard(item.id)
                                                  }
                                                  size="small"
                                                >
                                                  <SaveIcon />
                                                </IconButton>
                                              </Tooltip>
                                              <Tooltip
                                                title="Cancel"
                                                placement="top"
                                              >
                                                <IconButton
                                                  onClick={() =>
                                                    handleCancelEditCard(
                                                      item.id
                                                    )
                                                  }
                                                  size="small"
                                                >
                                                  <CancelIcon />
                                                </IconButton>
                                              </Tooltip>
                                            </div>
                                          ) : (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <Box>
                                                <Typography
                                                  variant="h6"
                                                  component="h3"
                                                  gutterBottom
                                                >
                                                  {item.title}
                                                </Typography>
                                                <Typography
                                                  variant="subtitle1"
                                                  gutterBottom
                                                >
                                                  {item.subtitle}
                                                </Typography>
                                              </Box>
                                              <input
                                                type="checkbox"
                                                checked={
                                                  expandedCards[item.id] ||
                                                  false
                                                }
                                                onChange={() =>
                                                  handleToggleCardExpansion(
                                                    item.id
                                                  )
                                                }
                                              />
                                            </div>
                                          )}
                                          {isExpanded && !isEditing && (
                                            <div>
                                              {/* Expanded content */}
                                              <Typography
                                                variant="subtitle1"
                                                gutterBottom
                                              >
                                                {item.notes}
                                              </Typography>
                                              <Typography
                                                variant="body1"
                                                gutterBottom
                                              >
                                                {item.description
                                                  .split(",")
                                                  .map((pair, index) => {
                                                    const [key, ...valueParts] =
                                                      pair.split(":");
                                                    const value = valueParts
                                                      .join(":")
                                                      .trim();
                                                    return (
                                                      <span key={index}>
                                                        {item.description.includes(
                                                          key
                                                        ) ? (
                                                          <span
                                                            style={{
                                                              fontWeight:
                                                                "bold",
                                                            }}
                                                          >
                                                            {key}
                                                          </span>
                                                        ) : (
                                                          <span>{key}</span>
                                                        )}
                                                        {value && `: ${value}`}
                                                        {index <
                                                          item.description.split(
                                                            ","
                                                          ).length -
                                                          1
                                                          ? ", "
                                                          : ""}
                                                      </span>
                                                    );
                                                  })}
                                              </Typography>
                                              <Tooltip
                                                title="Edit"
                                                placement="top"
                                              >
                                                <IconButton
                                                  onClick={() =>
                                                    handleEditCard(item.id)
                                                  }
                                                  size="small"
                                                >
                                                  <EditIcon />
                                                </IconButton>
                                              </Tooltip>
                                              <Tooltip
                                                title="Delete"
                                                placement="top"
                                              >
                                                <IconButton
                                                  onClick={() =>
                                                    handleDeleteCard(item.id)
                                                  }
                                                  size="small"
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              </Tooltip>
                                              <Tooltip
                                                title="Archive"
                                                placement="top"
                                              >
                                                <IconButton
                                                  onClick={() =>
                                                    handleNewArchiveCard(
                                                      item.id
                                                    )
                                                  }
                                                  size="small"
                                                >
                                                  <ArchiveIcon />
                                                </IconButton>
                                              </Tooltip>
                                              {archiveMessage && (
                                                <Dialog
                                                  open={isArchiveDialogOpen}
                                                  onClose={() =>
                                                    setIsArchiveDialogOpen(
                                                      false
                                                    )
                                                  }
                                                >
                                                  <DialogTitle>
                                                    Archive Message
                                                  </DialogTitle>
                                                  <DialogContent>
                                                    <Typography
                                                      variant="body2"
                                                      gutterBottom
                                                    >
                                                      {archiveMessage}
                                                    </Typography>
                                                  </DialogContent>
                                                </Dialog>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      </Paper>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </DragDropContext>
        </Grid>
      </Grid>
      {/* {addCardOpen && (
        <CarrierSubmissionForm open={addCardOpen} closeForm={handleAddCardClose} />
      )} */}
    </Box>
  );
};

export default Dispatch;
