import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCreateActivityMutation } from '../../state/salesApi';
import { useSnackbar } from 'notistack';
import { Close } from '@mui/icons-material';
import { hoverStyle } from 'components/helpers/MediaQueries';

const AddActivityForm = ({ leadId, onClose, refetch }) => {
  const [formErrors, setFormErrors] = useState([]);
  const [addActivityMutation, { isLoading: adding }] = useCreateActivityMutation();
  const [successMessage, setSuccessMessage] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = `${now.getMonth() + 1}`.padStart(2, '0');
    const day = `${now.getDate()}`.padStart(2, '0');
    const hours = `${now.getHours()}`.padStart(2, '0');
    const minutes = `${now.getMinutes()}`.padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      date: getCurrentDateTime(),
      type: 'call',
      status: 'complete',
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Title is required'),
      description: Yup.string().required('Description is required'),
      date: Yup.date().required('Date is required'),
      type: Yup.string().required('Type is required'),
      status: Yup.string().required('Status is required'),
    }),
    onSubmit: async (values, helpers) => {
      let errors = [];
      const activityData = { 
        leadId: [leadId], 
        title: values.title,
        description: values.description,
        date: new Date(),
        type: values.type,
        status: values.status,
      };

      try {
        addActivityMutation(activityData)
          .unwrap()
          .then(() => {
            formik.resetForm();
            setSuccessMessage('Successfully added activity!');
            setFormErrors([]);
            enqueueSnackbar("Activity added");
            refetch();
            handleClose();
          })
          .catch((error) => {
            errors.push(error);
            setFormErrors(errors);
            setSuccessMessage('');
          });
      } catch (error) {
        errors.push(error);
        setFormErrors(errors);
        setSuccessMessage('');
      }
    },
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const handleAlertClose = () => {
    setSuccessMessage('');
  };

  return (
    <Dialog open={true} onClose={(event, reason) => onClose(event, reason)} >
      <DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant='h6'>Add Activity</Typography>
          <Close sx={hoverStyle} onClick={handleClose}/>
        </div>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={formik.handleSubmit} noValidate>
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Type</InputLabel>
            <Select
              name="type"
              value={formik.values.type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <MenuItem value="call">Call</MenuItem> 
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="inPerson">In Person</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
            {formik.touched.type && formik.errors.type && (
              <Alert severity="error">{formik.errors.type}</Alert>
            )}
          </FormControl>
          {formik.values.type === "call" ? (
            <FormControl fullWidth margin='normal' required>
              <InputLabel>Title</InputLabel>
              <Select
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <MenuItem value="First Contact">First Contact</MenuItem>
                <MenuItem value="Follow Up">Follow Up</MenuItem>
                <MenuItem value="Voicemail">Voicemail</MenuItem>
              </Select>
            </FormControl>
          ):(
            <TextField
              margin="normal"
              fullWidth
              label="Title"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          )}
          {formik.touched.title && formik.errors.title && (
            <Alert severity="error">{formik.errors.title}</Alert>
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            multiline
            rows={4}
            label="Description"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.description && formik.errors.description && (
            <Alert severity="error">{formik.errors.description}</Alert>
          )}
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={formik.values.status}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <MenuItem value="upcoming">Upcoming</MenuItem>
              <MenuItem value="inProgress">In Progress</MenuItem>
              <MenuItem value="complete">Complete</MenuItem>
            </Select>
            {formik.touched.status && formik.errors.status && (
              <Alert severity="error">{formik.errors.status}</Alert>
            )}
          </FormControl>
          {formErrors.length ? (
            <Alert severity="error">Error adding activity.</Alert>
          ) : null}
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Cancel
            </Button>
            <Button type="submit" color="primary" variant="contained" disabled={adding}>
              Add
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
      {successMessage && (
        <Box sx={{ mt: 2 }}>
          <Alert severity="success" onClose={handleAlertClose}>
            {successMessage}
          </Alert>
        </Box>
      )}
    </Dialog>
  );
};

export default AddActivityForm;
