import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    credentials: "include",
  }),
  reducerPath: "adminApi",
  tagTypes: [
    "User",
    "Agent",
    "Products",
    "Customers",
    "Operations",
    "Transactions",
    "Geography",
    "Sales",
    "Admins",
    "Performance",
    "Dashboard",
    "Email",
    "Pool",
    "Activity",
    "ManageLeads",
    "ManagerActivity",
    "OperationsTeam",
    "OperationsCard",
    "Quotes",
    "Superadmin",
    "Stats",
    "Client",
    "ClientQuotes",
  ],
  //set route endpoints
  endpoints: (build) => ({
    //Get user by ID.
    getUser: build.query({
      query: (id) => `general/user/${id}`,
      providesTags: ["User"],
    }),
    getUserByJWT: build.query({
      query: () => `/general/user/`,
      providesTags: ["User"],
    }),
    //get email info and send email
    sendEmail: build.mutation({
      query: (data) => ({
        url: "/general/send-email",
        method: "POST",
        body: data,
        providesTags: ["Email"],
      }),
    }),
    //email dispatch
    sendEmailDispatch: build.mutation({
      query: (data) => ({
        url: "/general/send-email-dispatch",
        method: "POST",
        providesTags: ["Email"],
      }),
    }),
    updateDispatch: build.mutation({
      query: (data) => ({
        url: `/general/update-dispatch`,
        method: "POST",
        body: data,
      }),
    }),
    getAllDispatchers: build.query({
      query: () => `/dispatchcard/dispatchers`,
    }),
    getArchivedDispatch: build.query({
      query: ({
        page,
        limit,
        sortBy,
        order,
        startDate,
        endDate,
        archivedBy,
      }) => ({
        url: `/dispatchcard/cards?page=${page}&limit=${limit}&sortBy=${sortBy}&order=${order}${startDate ? `&startDate=${startDate}` : ""
          }${endDate ? `&endDate=${endDate}` : ""}${archivedBy ? `&archivedBy=${archivedBy}` : ""
          }`,
        headers: {
          'Accept': 'application/json',
          "Content-Type": "application/json",
        },
      }),
    }),
    updateArchivedDispatch: build.mutation({
      query: ({ id, data }) => ({
        url: `/dispatchcard/cards/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteArchivedDispatch: build.mutation({
      query: (id) => ({
        url: `/dispatchcard/cards/${id}`,
        method: "DELETE",
      }),
    }),
    // TODO: Client routes have changed for Customer Portal. Update client routes below
    //get all products
    getProducts: build.query({
      query: () => "client/products",
      providesTags: ["Products"],
    }),
    //get all customers
    getCustomers: build.query({
      query: () => "client/customers",
      providesTags: ["Customers"],
    }),
    //How to do params. get transactions by params.
    getTransactions: build.query({
      query: ({ page, pageSize, sort, search }) => ({
        url: "client/transactions",
        method: "GET",
        params: { page, pageSize, sort, search },
      }),
      providesTags: ["Transactions"],
    }),
    //get Geography for users
    getGeography: build.query({
      query: () => "client/geography",
      providesTags: ["Geography"],
    }),
    //get sales
    getSales: build.query({
      query: () => "sales/sales",
      providesTags: ["Sales"],
    }),
    //get user signatures
    getSignatures: build.query({
      query: (id) => `/general/signatures/${id}`,
    }),
    //POST a user signature
    addSignature: build.mutation({
      query: ({ userId, formData }) => ({
        url: `/general/upload-signature/${userId}`,
        method: "POST",
        body: formData,
      }),
    }),
    //DELETE a user signature
    deleteSignature: build.mutation({
      query: ({ userId, signatureName }) => ({
        url: `/general/signature/${userId}/${signatureName}`,
        method: "DELETE",
      }),
    }),
    getUserPerformance: build.query({
      query: (id) => `management/performance/${id}`,
      providesTags: ["Performance"],
    }),
    // getDashboard: build.query({
    //   query: () => "general/dashboard",
    //   providesTags: ["Dashboard"],
    // }),
    //POST routes for auth
    login: build.mutation({
      query: (data) => ({
        url: "auth/login",
        method: "POST",
        body: {
          email: data.email,
          password: data.password,
        },
      }),
    }),
    getGoogleAuthUrl: build.query({
      query: () => "auth/google",
    }),
    googleCallback: build.mutation({
      query: (data) => ({
        url: "auth/google/callback",
        method: "POST",
        body: {
          code: data,
        },
      }),
    }),
    getGoogleCalendarEvents: build.query({
      query: ({ startDate, endDate }) =>
        `general/calendar/events?startDate=${startDate}&endDate=${endDate}`,
    }),
    addGoogleCalendarEvent: build.mutation({
      query: (data) => ({
        url: "/general/calendar/event",
        method: "POST",
        body: data,
      }),
    }),
    getSearchLeads: build.query({
      query: (searchQuery) => `/general/search-leads/${searchQuery}`,
    }),
    getSearchLeadsByField: build.query({
      query: ({ field, searchQuery }) =>
        `/general/search-leads/${field}/${searchQuery}`,
    }),
    register: build.mutation({
      query: (data) => ({
        url: "auth/register",
        method: "POST",
        body: {
          email: data.email,
          password: data.password,
          name: data.name || "",
          address: data.address || "",
          city: data.city || "",
          state: data.state || "",
          occupation: data.occupation || "",
          country: data.country || "",
          phoneNumber: data.phoneNumber || "",
          cellNumber: data.cellNumber || "",
        },
      }),
    }),
    //csv POST for comparison.
    processCSV: build.mutation({
      query: (formData) => ({
        url: "csv/process",
        method: "POST",
        body: formData,
      }),
    }),
    // Get all agents
    //send DispatchCard to Archive
    archiveDispatchCard: build.mutation({
      query: (formData) => ({
        url: "dispatchCard/archive",
        method: "POST",
        body: formData,
      }),
    }),
    resetUserPassword: build.mutation({
      query: (data) => ({
        url: "auth/reset-password",
        method: "PATCH",
        body: data,
      }),
    }),
    getDailyStatData: build.query({
      query: () => `/general/dailyStats`,
    }),
    getLeaderboardData: build.query({
      query: ({ priorityStat, limit }) => `/general/leaderboard?priorityStat=${priorityStat}&limit=${limit}`,
    }),
    textLocateAuthenticate: build.mutation({
      query: () => ({
        url: `/general/textlocate/authenticate`,
        method: "POST"
      }),
    }),
    pingDriver: build.mutation({
      query: (data) => ({
        url: "/general/textlocate/ping-driver",
        method: "POST",
        body: data
      })
    }),
    handleTextLocateWebhook: build.mutation({
      query: () => ({
        url: `/general/textlocate/webhook`,
        method: "POST",
      })
    }),
    // FMCSA routes
    getCarrierInfo: build.query({
      query: (mcNumber) => `/general/fmcsa/carrier/${mcNumber}`
    }),
    // Notification Routes
    createNotificationForUser: build.mutation({
      query: (data) => ({
        url: `/general/notifications`,
        method: "POST",
        body: data
      })
    }),
    deleteNotificationForUser: build.mutation({
      query: ({ id, userId }) => ({
        url: `/general/notifications/${id}`,
        method: "DELETE",
        body: {
          userId
        }
      })
    }),
    deleteMultipleNotificationsForUser: build.mutation({
      query: ({ userId, notificationIds }) => ({
        url: `/general/notifications/multiple`,
        method: "DELETE",
        body: {
          userId,
          notificationIds
        }
      })
    }),
    updateNotificationForUser: build.mutation({
      query: ({ id, data }) => ({
        url: `/general/notifications/${id}`,
        method: "PATCH",
        body: data
      })
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetUserByJWTQuery,
  useGetProductsQuery,
  useGetCustomersQuery,
  useGetTransactionsQuery,
  useGetGeographyQuery,
  useGetSalesQuery,
  useGetSignaturesQuery,
  useAddSignatureMutation,
  useDeleteSignatureMutation,
  useGetUserPerformanceQuery,
  // useGetDashboardQuery,
  useGetGoogleAuthUrlQuery,
  useGoogleCallbackMutation,
  useGetGoogleCalendarEventsQuery,
  useAddGoogleCalendarEventMutation,
  useGetSearchLeadsQuery,
  useGetSearchLeadsByFieldQuery,
  useLoginMutation,
  useRegisterMutation,
  useProcessCSVMutation,
  useArchiveDispatchCardMutation,
  useResetUserPasswordMutation,
  useGetDailyStatDataQuery,
  useGetLeaderboardDataQuery,
  useSendEmailMutation,
  useSendEmailDispatchMutation,
  useGetAllDispatchersQuery,
  useGetArchivedDispatchQuery,
  useUpdateArchivedDispatchMutation,
  useDeleteArchivedDispatchMutation,
  useUpdateDispatchMutation,
  useTextLocateAuthenticateMutation,
  usePingDriverMutation,
  useHandleTextLocateWebhookMutation,
  useGetCarrierInfoQuery,
  useCreateNotificationForUserMutation,
  useDeleteNotificationForUserMutation,
  useDeleteMultipleNotificationsForUserMutation,
  useUpdateNotificationForUserMutation,
} = api;
