import { Archive, Cancel, Delete, Edit, Map, Save, TravelExplore } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import DispatchPingLocation from "components/dispatch/board/DispatchPingLocation";
import TextLocateForm from "components/dispatch/board/TextLocateForm";
import { setupWebSocket } from "components/helpers/WebSocketSetup";
import Header from "components/layout/Header";
import { useSnackbar } from "notistack";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import {
  useAddCardToBookedColumnMutation,
  useArchiveCardMutation,
  useDeleteCardMutation,
  useRemoveCardFromColumnMutation,
  useUpdateCardMutation,
  useUpdateColumnMutation,
} from "state/dispatchApi";

const DispatchBoard = () => {
  const theme = useTheme();
  const isConnected = useSelector((state) => state.webSocket.isConnected);

  const mapsKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  
  // Column States
  const [columns, setColumns] = useState([]); // All columns
  const [bookingColumn, setBookingColumn] = useState({});
  const [atPickupColumn, setAtPickupColumn] = useState({});
  const [inTransitColumn, setInTransitColumn] = useState({});
  const [atDeliveryColumn, setAtDeliveryColumn] = useState({});
  const [completeColumn, setCompleteColumn] = useState({});
  const [problemColumn, setProblemColumn] = useState({});
  const [projectColumn, setProjectColumn] = useState({});

  // Editing States
  const [isEditingColor, setIsEditingColor] = useState(false);
  const [editCardId, setEditCardId] = useState(null);
  const [editedTitle, setEditedTitle] = useState("");
  const [editedSubtitle, setEditedSubtitle] = useState("");
  const [editedNotes, setEditedNotes] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  const [editedColor, setEditedColor] = useState("");
  const [oldColor, setOldColor] = useState("");

  const [expandedCards, setExpandedCards] = useState({});
  const [activeFilters, setActiveFilters] = useState([]);

  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [textLocateOpen, setTextLocateOpen] = useState(false);
  const [cardToText, setCardToText] = useState(null);
  const [locationOpen, setLocationOpen] = useState(false)
  const [cardToLocate, setCardToLocate] = useState(null)

  const columnsRef = useRef(columns);

  // Mutations
  const [addCard] = useAddCardToBookedColumnMutation();

  const [updateCard] = useUpdateCardMutation();

  const [archiveCard] = useArchiveCardMutation();

  const [deleteCard] = useDeleteCardMutation();

  const [removeCardFromColumn] =
    useRemoveCardFromColumnMutation();

  const [updateColumn] =
    useUpdateColumnMutation();

  const { enqueueSnackbar } = useSnackbar();

  const defaultCardColor = theme.palette.primary[1000];

  const PREDEFINED_COLORS = [
    { name: "Default", color: defaultCardColor },
    { name: "Sergio Rosa", color: "#d961a2" },
    { name: "Tori Siegal", color: "#8e7cc3" },
    { name: "Morgan Hodges", color: "#0e9602" },
    { name: "Dispatch 1", color: "#6d9eeb" },
    { name: "Dispatch 2", color: "#76a5af" },
    { name: "Other", color: "#a0522d" }, // Orange/Brown
    { name: "Trouble", color: "#ff0000" }, // Red
    { name: "Assign Load", color: "#bbb85b"}, // Faded Yellow
  ];

  // Websocket handling
  useEffect(() => {
    const socket = setupWebSocket(
      process.env.REACT_APP_WEBSOCKET_URL,
      "Dispatch2"
    );

    // On page load/websocket connect
    socket.on("initialState", (columns) => {
      setColumns(columns); // Set parent column states
      handleColumnSetters(columns); // Set individual column states
      columnsRef.current = columns;
    });

    // On column update/websocket emit
    socket.on("columnUpdated", (data) => { // Data returned as { columnId: parentColumn._id, column: parentColumn }
      if (data.updatedColumns) { // If data is returned
        for (const column of data.updatedColumns) { // For every updated column returned
          handleColumnUpdating(column.name, column);
        }
      } else {
        handleColumnUpdating(data.column.name, data.column);
      }
    });

    // On textLocate webhook response
    socket.on("webhookResponse", (card) => {
      handleUpdateCardById(card, columnsRef.current);
    });
    
    return () => socket.disconnect(); // Cleanup on unmount
  }, []);

  useEffect(() => {
    columnsRef.current = columns;
  }, [columns]);

  // Togglers
  const toggleFilterDialog = () => setFilterDialogOpen((prev) => !prev);
  const toggleTextLocate = (card) => {
    textLocateOpen ? setCardToText(null) : setCardToText(card);
    setTextLocateOpen(prev => !prev);
  };
  const toggleLocationOpen = (card) => {
    cardToLocate ? setCardToLocate(null) : setCardToLocate(card);
    setLocationOpen((prev) => !prev);
  }

  // Handlers
  const handleColumnSetters = (columns) => {
    setBookingColumn(columns.find((column) => column.name === "Booked"));
    setAtPickupColumn(columns.find((column) => column.name === "At Pickup"));
    setInTransitColumn(columns.find((column) => column.name === "In Transit"));
    setAtDeliveryColumn(columns.find((column) => column.name === "At Delivery"));
    setCompleteColumn(columns.find((column) => column.name === "Complete"));
    setProblemColumn(columns.find((column) => column.name === "Problem"));
    setProjectColumn(columns.find((column) => column.name === "Project"));
  };

  const handleColumnUpdating = (columnName, column) => {
    switch (columnName) {
      case "Booked":
        setBookingColumn(column);
        break;
      case "At Pickup":
        setAtPickupColumn(column);
        break;
      case "In Transit":
        setInTransitColumn(column);
        break;
      case "At Delivery":
        setAtDeliveryColumn(column);
        break;
      case "Complete":
        setCompleteColumn(column);
        break;
      case "Problem":
        setProblemColumn(column);
        break;
      case "Project":
        setProjectColumn(column);
        break;
      default:
        break;
    };

    setColumns((prev) => {
      return prev.map((col) => 
        col.name === columnName ? column : col
      );
    });
  };

  const handleUpdateCardById = (card, currentColumns) => {
    const oldColumns = [...currentColumns];
    let columnName = '';
    for (let column of oldColumns) {
      const cardMatch = column.items.find((item) => item._id === card._id);
      if (!cardMatch) {
        break;
      } else {
        columnName = column.name;
      }
    };

    const oldColumn = oldColumns.find((column) => column.name === columnName);
    const newItems = oldColumn.items.map(item => item._id === card._id ? card : item);

    handleColumnUpdating(columnName, {...oldColumn, items: newItems});
  };

  const handleToggleCardExpansion = (cardId) => {
    setExpandedCards((prev) => ({
      ...prev,
      [cardId]: !prev[cardId],
    }));
  };

  const handleAddCard = async () => {
    const newCard = {
      title: "",
      subtitle: "",
      notes: "",
      description: "",
      color: theme.palette.primary[1000],
    };

    try {
      await addCard(newCard).then(() => {
        enqueueSnackbar("Dispatch Card Added");
      });
    } catch (error) {
      console.error("Error adding card:", error);
    }
  };

  const handleSaveCard = async (itemId) => {
    const updatedCard = {
      title: editedTitle,
      subtitle: editedSubtitle,
      notes: editedNotes,
      description: editedDescription,
      color: editedColor,
    };

    try {
      await updateCard({ cardId: itemId, cardUpdates: updatedCard }).then(
        () => {
          setEditCardId(null);
          setIsEditingColor(false);
          enqueueSnackbar(`Card Updated`);
        }
      );
    } catch (error) {
      console.error("Error Saving Card:", error);
    }
  };

  const handleEditCard = (item) => {
    setEditCardId(item._id);
    setOldColor(item.color);
    setEditedColor(item.color);
    setEditedTitle(item.title);
    setEditedSubtitle(item.subtitle);
    setEditedNotes(item.notes);
    setEditedDescription(item.description);
    setIsEditingColor(true);
  };

  const handleCardColorChange = (item, color) => {
    if (isEditingColor) {
      item.color = color.hex;
      setEditedColor(color.hex);
    }
  };

  const handleCancelEditCard = (item) => {
    setEditCardId(null);
    setEditedTitle("");
    setEditedSubtitle("");
    setEditedNotes("");
    setEditedDescription("");
    setIsEditingColor(false);
    // Handle bug if no color is selected then cancelled
    if (oldColor) {
      item.color = oldColor;
    }
  };

  const handleRemoveCard = async (columnId, cardId) => {
    const confirmed = window.confirm(
      "Confirm: Remove this card? This will delete the card from the board, but save it in the database."
    );

    if (!confirmed) return;

    try {
      await archiveCard(cardId);

      await removeCardFromColumn({ columnId, cardId }).then(() => {
        enqueueSnackbar(`Card Removed`);
      });
    } catch (error) {
      console.error("Error Removing Card:", error);
    }
  };

  const handleDeleteCard = async (cardId) => {
    const confirmed = window.confirm(
      "Confirm: Delete card? This will will remove the card from the database completely."
    );

    if (!confirmed) return;

    try {
      await deleteCard(cardId).then(() => {
        enqueueSnackbar(`Card Deleted`);
      });
    } catch (error) {
      console.error("Error Deleting Card:", error);
    }
  };

  const handleNewArchiveCard = async (item) => {
    if (!item.title || !item.subtitle || !item.description || !item.notes) {
      const confirmed = window.confirm(
        "Some required fields are empty. Are you sure you want to proceed?"
      );

      if (!confirmed) {
        return;
      }
    }

    try {
      await archiveCard(item._id).then(() => {
        enqueueSnackbar(`${item.title} Archived`);
      });
    } catch (error) {
      console.error("Error Archiving Card:", error);
    }
  };

  const handleGetColumnFromDroppableId = (droppableId) => {
    switch (droppableId) {
      case "column-1":
        return columns.find((column) => column.name === "Booked");
      case "column-2":
        return columns.find((column) => column.name === "At Pickup");
      case "column-3":
        return columns.find((column) => column.name === "In Transit");
      case "column-4":
        return columns.find((column) => column.name === "At Delivery");
      case "column-5":
        return columns.find((column) => column.name === "Complete");
      case "column-6":
        return columns.find((column) => column.name === "Problem");
      case "column-7":
        return columns.find((column) => column.name === "Project");
      default:
        break;
    }
  };

  const handleColumnChangingStutter = async (column) => {
    switch (column.name) {
      case "Booked":
        setBookingColumn(column);
        break;
      case "At Pickup":
        setAtPickupColumn(column);
        break;
      case "In Transit":
        setInTransitColumn(column);
        break;
      case "At Delivery":
        setAtDeliveryColumn(column);
        break;
      case "Complete":
        setCompleteColumn(column);
        break;
      case "Problem":
        setProblemColumn(column);
        break;
      case "Project":
        setProjectColumn(column);
        break;
      default:
        break;
    }
  };

  const handleDragEnd = async (result) => {

    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const sourceColumn = handleGetColumnFromDroppableId(source.droppableId);
    const destColumn = handleGetColumnFromDroppableId(destination.droppableId);

    const movedItem = sourceColumn.items.splice(source.index, 1)[0];
    destColumn.items.splice(destination.index, 0, movedItem);

    const updatedColumns = [...columns]; //what
    setColumns(updatedColumns);

    try {
      await handleColumnChangingStutter(sourceColumn);
      await handleColumnChangingStutter(destColumn);
      await updateColumn({
        updatedColumn: {
          sourceColumnId: sourceColumn._id,
          destinationColumnId: destColumn._id,
          cardId: result.draggableId,
          newPosition: result.destination.index,
        },
      }).then(() => { });
    } catch (error) {
      console.error("Error moving card:", error);
    }
  };

  const handleFilterChange = (color) => {
    if (activeFilters.includes(color)) {
      setActiveFilters((prev) => prev.filter((c) => c !== color));
    } else {
      setActiveFilters((prev) => [...prev, color]);
    }
  };

  const handleClearFilters = () => {
    setActiveFilters([]);
  };

  // HTML for columns
  const column = (column, columnId) => {
    return (
      <Grid item xs={12} sm={6} md={6} lg={4}>
        <Paper
          elevation={3}
          sx={{
            mb: "15px",
            pb: "5px",
            backgroundColor: theme.palette.background.secondary,
          }}
        >
          <Typography
            variant="h6"
            component="h2"
            align="center"
            gutterBottom
            sx={{ backgroundColor: theme.palette.primary[900] }}
          >
            {column.name}
          </Typography>
          {/* FIXES ERROR BUT CAUSES NEW ONE */}
          <Droppable droppableId={columnId} /* droppableId={column._id} */>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {column.items &&
                  column.items
                    .filter(
                      (item) =>
                        item &&
                        (activeFilters.length === 0 ||
                          activeFilters.includes(item.color))
                    )
                    .map((item, index) => {
                      const isEditing = editCardId === item._id;
                      const isExpanded = expandedCards[item._id];

                      return (
                        <Draggable
                          key={item._id}
                          draggableId={item._id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Paper
                                elevation={isEditing ? 10 : 3}
                                sx={{ backgroundColor: item.color }}
                              >
                                <div
                                  style={{
                                    padding: "8px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  {isEditing ? (
                                    <div>
                                      <TextField
                                        fullWidth
                                        autoFocus
                                        label="Title"
                                        value={editedTitle}
                                        onChange={(e) =>
                                          setEditedTitle(e.target.value)
                                        }
                                        variant="standard"
                                        multiline
                                      />
                                      <TextField
                                        fullWidth
                                        label="Subtitle"
                                        value={editedSubtitle}
                                        onChange={(e) =>
                                          setEditedSubtitle(e.target.value)
                                        }
                                        variant="standard"
                                      />
                                      <TextField
                                        fullWidth
                                        label="Notes"
                                        value={editedNotes}
                                        onChange={(e) =>
                                          setEditedNotes(e.target.value)
                                        }
                                        variant="standard"
                                        multiline
                                      />
                                      <TextField
                                        fullWidth
                                        label="Description"
                                        variant="outlined"
                                        multiline
                                        value={editedDescription.replace(
                                          /,\s+/g,
                                          ", "
                                        )}
                                        onChange={(e) => {
                                          setEditedDescription(e.target.value);
                                        }}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        InputProps={{
                                          style: {
                                            whiteSpace: "pre-wrap",
                                            overflowWrap: "break-word",
                                            wordBreak: "break-all",
                                          },
                                        }}
                                      />
                                      <div>
                                        {PREDEFINED_COLORS.map(
                                          (predefinedColor) => {
                                            if (
                                              predefinedColor.name.toLowerCase() !==
                                              "default"
                                            ) {
                                              return (
                                                <Tooltip
                                                  title={predefinedColor.name}
                                                  key={predefinedColor.name}
                                                  placement="top"
                                                >
                                                  <Button
                                                    style={{
                                                      backgroundColor:
                                                        predefinedColor.color,
                                                      margin: "5px",
                                                      color: "white",
                                                    }}
                                                    onClick={() =>
                                                      handleCardColorChange(
                                                        item,
                                                        {
                                                          hex: predefinedColor.color,
                                                        }
                                                      )
                                                    }
                                                  >
                                                    {predefinedColor.name}
                                                  </Button>
                                                </Tooltip>
                                              );
                                            }
                                            return null;
                                          }
                                        )}
                                      </div>
                                      <Tooltip title="Save" placement="top">
                                        <IconButton
                                          onClick={() =>
                                            handleSaveCard(item._id)
                                          }
                                          size="small"
                                        >
                                          <Save />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Cancel" placement="top">
                                        <IconButton
                                          onClick={() =>
                                            handleCancelEditCard(item)
                                          }
                                          size="small"
                                        >
                                          <Cancel />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Box>
                                        <Typography
                                          variant="h6"
                                          component="h3"
                                          gutterBottom
                                        >
                                          {item.title}
                                        </Typography>
                                        <Typography
                                          variant="subtitle1"
                                          gutterBottom
                                        >
                                          {item.subtitle}
                                        </Typography>
                                        {item.archivedBy && (
                                          <>
                                            <Divider
                                              sx={{
                                                color: "black",
                                                m: "10px 0px",
                                              }}
                                            />
                                            <Typography
                                              variant="subtitle2"
                                              gutterBottom
                                              fontWeight="bold"
                                            >
                                              ARCHIVED
                                            </Typography>
                                          </>
                                        )}
                                      </Box>
                                      <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                                        <input
                                          type="checkbox"
                                          checked={
                                            expandedCards[item._id] || false
                                          }
                                          onChange={() =>
                                            handleToggleCardExpansion(item._id)
                                          }
                                        />
                                          <Tooltip title="Ping Driver" placement="bottom">
                                            <IconButton
                                              onClick={() => toggleTextLocate(item)}
                                            >
                                              <TravelExplore />
                                            </IconButton>
                                          </Tooltip>
                                      </Box>
                                    </div>
                                  )}
                                  {isExpanded && !isEditing && (
                                    <div>
                                      {/* Expanded content */}
                                      <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                      >
                                        {item.notes}
                                      </Typography>
                                      <Grid container spacing={3}>
                                        {item.description
                                          .split(",")
                                          .map((pair, index) => {
                                            const [key, ...valueParts] =
                                              pair.split(":");
                                            
                                              const trimmedKey = key.trim();

                                            const value = valueParts
                                              .join(":")
                                              .trim();
                                              
                                            return (
                                              <Grid item xs={(trimmedKey === "Reference Number" || trimmedKey === "Instructions for Dispatch") ? 12 : 6} key={index}>
                                                <span>
                                                  {item.description.includes(
                                                    key
                                                  ) ? (
                                                    <span
                                                      style={{
                                                        fontWeight:
                                                          "bold",
                                                      }}
                                                    >
                                                      {key}
                                                    </span>
                                                  ) : (
                                                    <span>{key}</span>
                                                  )}
                                                  {value && `: ${value}`}
                                                </span>
                                              </Grid>
                                            )
                                          })
                                        }
                                      </Grid>
                                      {item?.textLocate?.updateStatus && (
                                        <Tooltip title="Pinged Location" placement="top">
                                          <IconButton
                                            onClick={() => 
                                              toggleLocationOpen(item)
                                            }
                                          >
                                            <Map />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                      <Tooltip title="Edit" placement="top">
                                        <IconButton
                                          onClick={() => handleEditCard(item)}
                                          size="small"
                                        >
                                          <Edit />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Archive" placement="top">
                                        <IconButton
                                          onClick={() =>
                                            handleNewArchiveCard(item)
                                          }
                                          size="small"
                                        >
                                          <Archive />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Remove" placement="top">
                                        <IconButton
                                          onClick={() =>
                                            handleRemoveCard(
                                              column._id,
                                              item._id
                                            )
                                          }
                                        >
                                          <Cancel />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Delete" placement="top">
                                        <IconButton
                                          onClick={() =>
                                            handleDeleteCard(item._id)
                                          }
                                          size="small"
                                        >
                                          <Delete />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  )}
                                </div>
                              </Paper>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Paper>
      </Grid>
    );
  };

  if (
    !bookingColumn ||
    !atPickupColumn ||
    !inTransitColumn ||
    !atDeliveryColumn ||
    !completeColumn ||
    !problemColumn ||
    !projectColumn
  )
    return null;

  return (
    <Box p="1.5rem 2.5rem" sx={{ width: "100%" }}>
      <Header
        title="DISPATCH BOARD V.2"
        subtitle="Manage shipment cards and status"
      />
      <Typography
        variant="h6"
        component="h2"
        gutterBottom
        sx={{ paddingBottom: 1 }}
      >
        WebSocket Status:{" "}
        {isConnected ? (
          <Typography sx={{ color: "#00deeb" }}>Connected</Typography>
        ) : (
          <Typography sx={{ color: "#FF0000" }}>Disconnected</Typography>
        )}
      </Typography>
      <Grid
        container
        spacing={1}
        sx={{ backgroundColor: theme.palette.background.alt, padding: 2 }}
      >
        <Grid item xs={12} md={2}>
          <Grid item container spacing={8}>
            <Grid item xs={12} sm={4} md={4}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleAddCard}
              >
                Add Card
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Button
                variant="contained"
                fullWidth
                onClick={toggleFilterDialog}
              >
                Filter Colors
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleClearFilters}
              >
                Clear Filters
              </Button>
            </Grid>
          </Grid>
          {filterDialogOpen && (
            <Dialog open={filterDialogOpen} onClose={toggleFilterDialog}>
              <DialogTitle>Filter by Color</DialogTitle>
              <DialogContent>
                {PREDEFINED_COLORS.map((color) => (
                  <FormControlLabel
                    key={color.name}
                    control={
                      <Checkbox
                        checked={activeFilters.includes(color.color)}
                        onChange={() => handleFilterChange(color.color)}
                        name={color.name}
                        color="primary"
                      />
                    }
                    label={color.name}
                  />
                ))}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={toggleFilterDialog}
                  variant="contained"
                  color="error"
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Grid container spacing={3}>
              {column(bookingColumn, "column-1")}
              {column(atPickupColumn, "column-2")}
              {column(inTransitColumn, "column-3")}
              {column(atDeliveryColumn, "column-4")}
              {column(completeColumn, "column-5")}
              {column(projectColumn, "column-7")}
              {column(problemColumn, "column-6")}
            </Grid>
          </DragDropContext>
        </Grid>
      </Grid>
      {textLocateOpen && (
        <TextLocateForm
          card={cardToText}
          onClose={toggleTextLocate}
        />
      )}
      {locationOpen && (
        <DispatchPingLocation 
          card={cardToLocate}
          onClose={toggleLocationOpen}
          mapsKey={mapsKey}
        />
      )}
    </Box>
  );
};

export default DispatchBoard;