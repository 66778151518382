import Close from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

const MessageInbox = ({ toggleMessages }) => {


    return (
        <Dialog
        open
        onClose={toggleMessages}
        fullWidth
        maxWidth="md"
        >
            <DialogTitle>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h6">Messages</Typography>
                    <IconButton onClick={toggleMessages}>
                        <Close />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <Typography variant="h3">To be implemented</Typography>
            </DialogContent>
        </Dialog>
    )
}

export default MessageInbox;