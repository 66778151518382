import { useEffect, useState } from "react";

const useDebounce = (value, delay, callback) => {
    const [state, setState] = useState();

    useEffect(() => {
     const timer = setTimeout(() => setState(value), delay);

     // Clear timeout should the value change while already debouncing
     return () => {
        clearTimeout(timer);
     };
    }, [value, delay]);

    return state;
}

export default useDebounce;