import React from 'react';
import { useGetAgentMonthlyReportQuery } from '../../state/managementApi';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const AgentMonthlyStats = ({ agentId, monthYear, onClose }) => {
  const { data: monthlyStats, isLoading: monthlyStatsLoading, isError: monthlyStatsError } = useGetAgentMonthlyReportQuery({ agentId, monthYear });

  const handleClose = () => {
    onClose();
  };

  if (monthlyStatsLoading) {
    return <CircularProgress />;
  }

  if (monthlyStatsError) {
    return <Box>Error: {monthlyStatsError.message}</Box>;
  }

  return (
    <Box>
      <Typography variant="h4" component="h1" sx={{ paddingTop: 1 }}>
        Monthly Stats for {monthYear}
      </Typography>
      {monthlyStats && monthlyStats.length > 0 ? (
        <Box>
          <Typography>Updated: {monthlyStats[0].updatedAt}</Typography>
          <Typography>Calls: {monthlyStats[0].monthlyCalls}</Typography>
          <Typography>Emails: {monthlyStats[0].monthlyEmails}</Typography>
          <Typography>Setups: {monthlyStats[0].setups}</Typography>
        </Box>
      ) : (
        <Box sx={{ paddingTop: 1 }}>{monthYear} - No monthly stats available.</Box>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 'auto' }}>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Close
        </Button>
      </Box>
    </Box>
  );
};

export default AgentMonthlyStats;
