import Close from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { hoverStyle } from "components/helpers/MediaQueries";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { usePingDriverMutation } from "state/api";

const TextLocateForm = ({ card, onClose }) => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    // const [action, setAction] = useState("SendPing"); //SendPing = 0, SchedulePing = 1, SendText = 2, BundleSchedule = 3, ScheduleText = 4, Upload = 5, CreatePod = 6, PreBook = 7
    const [messageService, setMessageService] = useState("Standard"); // Standard = 0, WhatsApp = 1

    const [pingDriver] = usePingDriverMutation();


    const handleSend = async () => {
        const textData = {
            phoneNumber,
            firstName,
            lastName,
            cardId: card._id,
            action: "SendPing",
            messageService
        };

        try {
            await pingDriver(textData)
                .then(() => {
                    enqueueSnackbar(`Text Sent to ${card.title}`);
                    onClose();
                });
        } catch (error) {
            console.error('Error sending text:', error);
        }
    };

    if (!card) return;

    return (
        <Dialog open onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                    <Typography variant="h6">Ping {card.title}</Typography>
                    <IconButton onClick={onClose}>
                        <Close sx={hoverStyle} />
                    </IconButton>
                </div>
                <Divider />
            </DialogTitle>
            <DialogContent>
                <Typography sx={{ padding: '5px', }}><b>Number</b></Typography>
                <TextField
                    fullWidth
                    name="number"
                    type="tel"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    sx={{ mb: '25px', }}
                />
                <Typography sx={{ padding: '5px', }}><b>First Name:</b></Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    name="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                <Typography sx={{ padding: '5px', }}><b>Last Name:</b></Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    name="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
                <Typography sx={{ padding: '5px', }}>Message Service</Typography>
                <Select
                    value={messageService}
                    onChange={(e) => setMessageService(e.target.value)}
                >
                    <MenuItem value="Standard">Standard - USA</MenuItem>
                    <MenuItem value="WhatsApp">WhatsApp - Global</MenuItem>
                </Select>
            </DialogContent>
            <DialogActions
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '10px'
                }}
            >
                <Button
                    variant="contained"
                    color="error"
                    onClick={onClose}
                    sx={{ ml: '15px', }}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSend}
                    sx={{ mr: '15px', }}
                >
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default TextLocateForm;