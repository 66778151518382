import { Alert, Box, Button, Dialog, DialogTitle, MenuItem, Stack } from "@mui/material";
import SignatureForm from "components/forms/SignatureForm";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useDeleteSignatureMutation, useGetSignaturesQuery } from "state/api";

const SignatureMenu = ({ user, signatureSettingsOpen, handleCloseSignatureSettings }) => {
    const { data: signatures, error, isLoading, refetch } = useGetSignaturesQuery(user._id);
    const [newSignatureFormOpen, setNewSignatureFormOpen] = useState(false);
    const [deleteSignature] = useDeleteSignatureMutation();
    const [deleteError, setDeleteError] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const handleSignatureDelete = async (signatureName) => {
        await deleteSignature({ userId: user._id, signatureName: signatureName })
            .then(() => {
                enqueueSnackbar("Signature deleted");
                refetch();
            })
            .catch((error) => {
                console.error('Error:', error);
                setDeleteError(true);
            });
    }

    if (isLoading) return <div>Loading...</div>;
    if (error || !signatures) return <div>Error loading signatures.</div>;

    return (
        <>
            <Dialog open={signatureSettingsOpen} onClose={handleCloseSignatureSettings} fullWidth maxWidth="lg">
                <DialogTitle sx={{ textAlign: 'center' }}>Signature Settings</DialogTitle>
                <Stack spacing={2}>
                    {signatures.map((signature, index) => (
                        <MenuItem key={index} style={{ display: "flex", justifyContent: "space-around" }}>
                            <img src={signature.data} alt={signature.name} style={{ width: "40%", }} />
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => handleSignatureDelete(signature.name)}
                            >
                                Delete
                            </Button>
                        </MenuItem>
                    ))}
                </Stack>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setNewSignatureFormOpen(!newSignatureFormOpen)}
                    sx={{ marginTop: "20px" }}
                >
                    Add Signature
                </Button>
                {newSignatureFormOpen && (
                    <SignatureForm user={user} onClose={() => setNewSignatureFormOpen(false)} refetch={refetch}/>
                )}
                <Box
                    sx={{
                        marginTop: '5px',
                        width: "100%",
                        maxHeight: 'calc(100vh - 100px',
                        overflow: 'auto',
                        marginBottom: '5px'
                    }}
                >
                    {deleteError && (
                        <Alert severity="error">
                            Error deleting signature.
                        </Alert>
                    )}
                </Box>
            </Dialog>
        </>
    )
}

export default SignatureMenu;