import Close from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { hoverStyle } from "components/helpers/MediaQueries";
import { useState } from "react";

const AssignQuoteMenu = ({ quote, opsMembers, onClose, onSubmit }) => {
    const [selectedAgentName, setSelectedAgentName] = useState("default");
    const [selectedAgent, setSelectedAgent] = useState(null);

    const sortedAgents = [...opsMembers].sort((a, b) => {
        const valueA = a.name || "";
        const valueB = b.name || "";
        return valueA.localeCompare(valueB);
    });

    return (
        <Dialog open onClose={onClose} fullWidth maxWidth="xs">
            <DialogTitle>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography>Assign Quote</Typography>
                    <Close sx={hoverStyle} onClick={onClose}/>
                </div>
                <Divider />
            </DialogTitle>
            <DialogContent sx={{ pb: '0px', }}>
                <Select
                    labelId="agent-id"
                    id="agent"
                    name="agent"
                    value={selectedAgentName}
                    onChange={(e) => {
                        setSelectedAgentName(e.target.value);
                        setSelectedAgent(opsMembers.find((agent) => agent.name === e.target.value));
                    }}
                >
                    <MenuItem value="default" sx={{ display: 'none', }}>Select an Operations Member</MenuItem>
                    {sortedAgents.map((agent, index) => (
                        <MenuItem key={index} value={agent.name}>{agent.name}</MenuItem>
                    ))}
                </Select>
                <Divider sx={{ mt: '15px', }}/>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', }}>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={selectedAgentName === "default"}
                        onClick={() => onSubmit(selectedAgent)}
                    >
                        Confirm
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default AssignQuoteMenu;