import Close from "@mui/icons-material/Close";
import Search from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { hoverStyle } from "components/helpers/MediaQueries";
import { useEffect, useState } from "react";
import { useGetCarrierInfoQuery } from "state/api";

const GetCarrierInfoForm = ({ onClose }) => {
    const [carrier, setCarrier] = useState(null);
    const [links, setLinks] = useState(null);
    
    // Search States
    const [searchInput, setSearchInput] = useState("");
    const [searched, setSearched] = useState(false);
    const [searchSkip, setSearchSkip] = useState(true);

    const { 
        data: carrierInfo, 
        isLoading, 
        status, 
        isSuccess 
    } = useGetCarrierInfoQuery(searchInput, { skip: searchSkip });

    useEffect(() => {
     if (isSuccess && status === "fulfilled" && !isLoading) {
        setSearchSkip(true);
        setCarrier(carrierInfo.content[0].carrier);
        setLinks(carrierInfo.content[0]._links);
     }
    }, [carrierInfo, isSuccess, status, isLoading]);

    // Handlers
    const handleSearch = () => {
        setSearched(true);
        setSearchSkip(false);
    };

    return (
        <Dialog open onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                    <Typography variant="h6">Carrier Search</Typography>
                    <IconButton onClick={onClose}>
                        <Close sx={hoverStyle}/>
                    </IconButton>
                </div>
                <Divider />
            </DialogTitle>
            <DialogContent>
                <Typography>MC Number:</Typography>
                <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 400, border: '1px solid grey', }}
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    <InputBase 
                        sx={{ ml: 1, flex: 1, }}
                        id="searchbar"
                        placeholder="MC Number"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleSearch(e);
                            }
                        }}
                    />
                    {!searched ? (
                        <IconButton
                            sx={{ p: '10px', }}
                            onClick={handleSearch}
                        >
                            <Search />
                        </IconButton>
                    ) : (
                        <IconButton
                            sx={{ p: '10px', }}
                            onClick={() => {
                                setSearched(false);
                                setSearchInput("");
                                setCarrier(null);
                            }}
                        >
                            <Close />
                        </IconButton>
                    )}
                </Paper>
                {carrier && (
                    carrierInfo.content && carrierInfo.content.length ? (
                        <Grid container direction="row" justifyContent="space-around" sx={{ mt: '35px', }}>
                            <Grid item xs={12}>
                                <Box>
                                    <Typography variant="h6" fontWeight="bold" mb="5px">
                                        {carrier.legalName}
                                    </Typography>
                                    <TableContainer component={Paper}>
                                        <Table size="small">
                                            <TableBody>
                                                <TableRow sx={{ border: '1px solid grey', }}>
                                                    <TableCell>
                                                        <Typography fontWeight="bold">Address</Typography>
                                                    </TableCell>
                                                    <TableCell colSpan={3} size="medium" sx={{ border: '1px solid grey', }}>
                                                        <Typography>
                                                            {carrier.phyStreet || ''} {carrier.phyCity || ''} {carrier.phyState || ''} {carrier.phyZipcode || ''} {carrier.phyCountry || ''}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow sx={{ border: '1px solid grey', }}>
                                                    <TableCell>
                                                        <Typography fontWeight="bold">Out-of-Service Date</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ border: '1px solid grey', }}>
                                                        <Typography>
                                                            {carrier.oosDate || 'None'}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography fontWeight="bold">Out-of-Service Rate {'(National Average Year)'}</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ border: '1px solid grey', }}>
                                                        <Typography>
                                                            {carrier.oosRateNationalAverageYear || ''}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow sx={{ border: '1px solid grey', }}>
                                                    <TableCell>
                                                        <Typography fontWeight="bold">Safety Rating</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ border: '1px solid grey', }}>
                                                        <Typography>
                                                            {carrier.safetyRating || ''}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography fontWeight="bold">Contract Authority Status</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ border: '1px solid grey', }}>
                                                        <Typography>
                                                            {carrier.contractAuthorityStatus || ''}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow sx={{ border: '1px solid grey', }}>
                                                    <TableCell>
                                                        <Typography fontWeight="bold">Total Drivers</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ border: '1px solid grey', }}>
                                                        <Typography>
                                                            {carrier.totalDrivers|| '0'}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography fontWeight="bold">Broker Authority Status</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ border: '1px solid grey', }}>
                                                        <Typography>
                                                            {carrier.brokerAuthorityStatus || ''}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow sx={{ border: '1px solid grey', }}>
                                                    <TableCell>
                                                        <Typography fontWeight="bold">Driver Inspections</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ border: '1px solid grey', }}>
                                                        <Typography>
                                                            {carrier.driverInsp|| '0'}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography fontWeight="bold">Common Authority Status</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ border: '1px solid grey', }}>
                                                        <Typography>
                                                            {carrier.commonAuthorityStatus || ''}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow sx={{ border: '1px solid grey', }}>
                                                    <TableCell>
                                                        <Typography fontWeight="bold">Total Power Units</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ border: '1px solid grey', }}>
                                                        <Typography>
                                                            {carrier.totalPowerUnits || '0'}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography fontWeight="bold">DBA Name</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ border: '1px solid grey', }}>
                                                        <Typography>
                                                            {carrier.dbaName || ''}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow sx={{ border: '1px solid grey', }}>
                                                    <TableCell>
                                                        <Typography fontWeight="bold">Vehicle Inspections</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ border: '1px solid grey', }}>
                                                        <Typography>
                                                            {carrier.vehicleInsp || '0'}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography fontWeight="bold">DOT Number</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ border: '1px solid grey', }}>
                                                        <Typography>
                                                            {carrier.dotNumber || ''}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Grid>
                        </Grid>
                    ) : (
                        <Typography>No carrier found</Typography>
                    )
                )}
                <Divider sx={{ mt: 5}}/>
            </DialogContent>
            <DialogActions
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '10px'
                }}
            >
                <Button
                    variant="contained"
                    color="error"
                    onClick={onClose}
                    sx={{ ml: '15px', }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default GetCarrierInfoForm;