import Close from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { hoverStyle } from "components/helpers/MediaQueries";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useGetAgentsQuery } from "state/managementApi";
import { useTransferLeadsMutation } from "state/salesApi";

const AssignLeadForm = ({ onClose, leadIds, leads, refetch }) => {
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [displayedAgentName, setDisplayedAgentName] = useState("default");
  //   const [assignOutcome, setAssignOutcome] = useState(null);
  //   const [assignedLeadCount, setAssignedLeadCount] = useState(0);

  const leadsAssigned = leads.find((lead) => lead.assignedUsers.length);

  const {
    data: agents = [],
    // error: agentsError,
    // isLoading: agentsLoading,
  } = useGetAgentsQuery(null, { staleTime: 0 });
  const [assignLead, /* { error: assignError, isLoading: assignLoading } */] =
    useTransferLeadsMutation();

  const { enqueueSnackbar } = useSnackbar();

  const sortedAgents = [...agents].sort((a, b) => {
    const valueA = a.name || "";
    const valueB = b.name || "";
    return valueA.localeCompare(valueB);
  });

  //   Handlers
  const handleSubmit = async () => {
    const dataObject = {
      targetEmployeeEmail: selectedAgent.email,
      leadIds,
    };

    try {
      await assignLead(dataObject).then(() => {
        enqueueSnackbar(
          `${leadIds.length > 1 ? `${leadIds.length} Leads` : `1 Lead`
          } assigned to ${displayedAgentName}`
        );
        refetch();
        onClose();
      });
    } catch (error) {
      console.error("Error assigning leads:", error);
    }
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>Assign Lead</Typography>
          <Close sx={hoverStyle} onClick={onClose} />
        </div>
        <Divider sx={{ mb: "15px" }} />
      </DialogTitle>
      <DialogContent sx={{ pb: "0px" }}>
        <Select
          labelId="agent-id"
          id="agent"
          name="agent"
          value={displayedAgentName}
          onChange={(e) => {
            setDisplayedAgentName(e.target.value);
            setSelectedAgent(
              agents.find((agent) => agent.name === e.target.value)
            );
          }}
        >
          <MenuItem value="default" sx={{ display: "none" }}>
            Select an agent
          </MenuItem>
          {sortedAgents
            .map((agent, index) => (
              <MenuItem key={index} value={agent.name}>
                {agent.name}
              </MenuItem>
            ))
            .sort()}
        </Select>
        {leadsAssigned && (
          <Alert variant="outlined" severity="warning" sx={{ mt: "15px" }}>
            <AlertTitle>WARNING</AlertTitle>1 or more leads already assigned
          </Alert>
        )}
        <Divider sx={{ mt: "15px" }} />
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ ml: "15px" }}>
          {displayedAgentName !== "default" && (
            <Typography
              fontWeight="bold"
              sx={{ flexBasis: "25%", wordWrap: "break-word", width: "80%" }}
            >
              Send {leadIds.length > 1 ? `${leadIds.length} Leads` : `1 Lead`}{" "}
              to {displayedAgentName}?
            </Typography>
          )}
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            disabled={selectedAgent === null}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AssignLeadForm;
