import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import { hoverStyle } from "components/helpers/MediaQueries";
import Divider from "@mui/material/Divider";
import {APIProvider, Map, Marker} from '@vis.gl/react-google-maps'; // Figure out how to hide the key in the network call
import { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

// CHECK GOOGLE MAPS ON DEPLOYED
// "Google Maps JavaScript API error: ApiProjectMapError"
// https://developers.google.com/maps/documentation/javascript/error-messages#api-project-map-error

// Google Maps JavaScript API error: ApiProjectMapError
// https://developers.google.com/maps/documentation/javascript/error-messages#api-project-map-error
// Google Maps JavaScript API warning: NoApiKeys https://developers.google.com/maps/documentation/javascript/error-messages#no-api-keys
// Google Maps JavaScript API warning: InvalidKey https://developers.google.com/maps/documentation/javascript/error-messages#invalid-key

const DispatchPingLocation = ({ card, onClose, mapsKey }) => {
    const [selectedPing, setSelectedPing] = useState(card.textLocate.updateStatus);
    const [defaultLatitude, setDefaultLatitude] = useState(card.textLocate.latitude);
    const [defaultLongitude, setdefaultLongitude] = useState(card.textLocate.longitude);

    const handleTabChange = (e, newValue) => {
        e.preventDefault();
        setSelectedPing(newValue);
        switch (newValue) {
            case 'PickUp':
                setDefaultLatitude(card.atPickup.latitude);
                setdefaultLongitude(card.atPickup.longitude);
                break;
            case 'InTransit':
                setDefaultLatitude(card.inTransit.latitude);
                setdefaultLongitude(card.inTransit.longitude);
                break;
            case 'Delivered':
                setDefaultLatitude(card.delivered.latitude);
                setdefaultLongitude(card.delivered.longitude);
                break;
            default:
                break;
        }
    };
    
    return (
        <Dialog open onClose={onClose} fullWidth maxWidth="xl">
            <DialogTitle>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                    <Typography variant="h6">{card.title} Location</Typography>
                    <IconButton onClick={onClose}>
                        <Close sx={hoverStyle}/>
                    </IconButton>
                </div>
                <Divider />
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
                {card?.textLocate ? (
                    <>
                        <Box sx={{ width: '100%', bgcolor: 'background.paper'}}>
                            <Tabs value={selectedPing} onChange={handleTabChange} centered>
                                {card.atPickup && <Tab value={'PickUp'} label="At Pickup"/>}
                                {card.inTransit && <Tab value={'InTransit'} label="In Transit"/>}
                                {card.delivered && <Tab value={'Delivered'} label="Delivered"/>} {/* DOUBLE CHECK IF PROPERTY IS 'delivered' */}
                            </Tabs>
                        </Box>
                        <APIProvider apiKey={mapsKey}> {/* Provider for google maps and react-google-maps package */}
                            <Map 
                                id={`ping-${card._id}`}
                                mapId={`${card.title}-map-status-${selectedPing}`} /* ID to reuse map if reloaded and avoid multiple network calls */
                                defaultCenter={{ lat: defaultLatitude, lng: defaultLongitude }} /* Default map location */
                                defaultZoom={7} /* Default map scale */
                                clickableIcons={false}
                                gestureHandling={'cooperative'} /* Disables automatic scale/center changes on interaction to avoid more network calls */
                                streetViewControl={false} /* Get rid of the tiny annoying street view man */
                                reuseMaps /* Caches map for potential remount */
                                style={{width: '50vw', height: '70vh'}}
                            >
                                <Marker position={{ lat: card.textLocate.latitude, lng: card.textLocate.longitude }}/>
                            </Map>
                        </APIProvider>
                    </>
                ) : (
                    <Typography>No ping to show</Typography>
                )}
            </DialogContent>
        </Dialog>
    )
};

export default DispatchPingLocation;